import { i18n } from 'legacy-i18n';
import { DjpI18nProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export type LegacyI18nProviderProps = {
  children: ReactNode;
};

export const LegacyI18nProvider = ({ children }: LegacyI18nProviderProps) => {
  return <DjpI18nProvider i18n={i18n}>{children}</DjpI18nProvider>;
};
