import { DjpApiConfigProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';
import { config } from '../config';

const API_CONFIG = {
  userIdentityGatewayBasePath: config.userIdentityGatewayPath,
};

export type LegacyApiConfigProviderProps = {
  children: ReactNode;
};

export const LegacyApiConfigProvider = ({ children }: LegacyApiConfigProviderProps) => {
  return <DjpApiConfigProvider apiConfig={API_CONFIG}>{children}</DjpApiConfigProvider>;
};
