import { useIonToast } from '@ionic/react';
import { i18n } from 'legacy-i18n';
import { DjpGraphqlErrorHandler } from 'legacy-stencil-react';
import { useRouter } from 'next/navigation';
import { ReactNode, useCallback } from 'react';

export type LegacyGraphqlErrorHandlerProviderProps = {
  children: ReactNode;
};

export const LegacyGraphqlErrorHandlerProvider = ({ children }: LegacyGraphqlErrorHandlerProviderProps) => {
  const [present] = useIonToast();
  const router = useRouter();

  interface HandleUnauthorizedError {
    (message?: string): Promise<void>;
  }

  const handleUnauthorizedError: HandleUnauthorizedError = useCallback(
    async (message?: string) => {
      await present({
        buttons: [{ text: 'OK', role: 'cancel' }],
        message: message ?? i18n?.t('global:global.error.unauthorized') ?? `You tried to view a page you don't have permission to access.`,
        position: 'bottom',
      });

      // Refresh the page after 1s
      setTimeout(() => router.refresh(), 1000);
    },
    [present, router]
  );

  interface NotifyUserAndLog {
    (err: unknown, message?: string): Promise<{ err: unknown; message?: string }>;
  }

  const notifyUserAndLog: NotifyUserAndLog = useCallback(async (err, message?: string) => {
    // Placeholder method brought from angular, at the time not actually used in legacy-stencil
    // logger.exception(err);
    // await present({
    //   buttons: [{ text: 'OK', role: 'cancel' }],
    //   message,
    //   position: 'bottom',
    // });
    // return Promise.resolve({ loading: false, data: null, networkStatus: NetworkStatus.error } as ApolloQueryResult<T>);
    return Promise.resolve({ err, message });
  }, []);

  return <DjpGraphqlErrorHandler graphqlErrorHandler={{ handleUnauthorizedError, notifyUserAndLog } as any}>{children}</DjpGraphqlErrorHandler>;
};
