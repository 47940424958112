import { Invite, useAcceptedInvitesQuery, usePendingInvitesQuery } from '@/graphql/generated';
import { addBasePath, Routes } from '@/utils/routes';
import Router from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';

interface InviteContextType {
  invites: string[];
  acceptedInvites: Array<Invite>;
  errorPendingInvites?: Error | null;
  errorAcceptedInvites?: Error | null;
  refreshInvites: () => Promise<void>;
}

const InviteContext = createContext<InviteContextType | undefined>(undefined);

export interface InviteProviderProps {
  children: ReactNode;
}

export const InviteProvider = ({ children }: InviteProviderProps) => {
  const {
    data: dataPendingInvites,
    error: errorPendingInvites,
    refetch,
  } = usePendingInvitesQuery({
    fetchPolicy: 'network-only',
  });

  const { data: dataAcceptedInvites, error: errorAcceptedInvites } = useAcceptedInvitesQuery({
    fetchPolicy: 'network-only',
  });
  const { searchInvites } = dataPendingInvites ?? {};

  useEffect(() => {
    if (searchInvites?.length > 0) {
      const invitePathname = `${Routes.invite}/${searchInvites[0]?.inviteId ?? 'no-id'}`;
      Router.push(invitePathname, addBasePath(invitePathname));
    }
  }, [searchInvites]);

  const refreshInvites = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const value = useMemo(() => {
    const invites = dataPendingInvites?.searchInvites?.map((invite) => invite.inviteId) || null;
    const acceptedInvites = dataAcceptedInvites?.searchInvites as Array<Invite>;
    return { invites, acceptedInvites, errorAcceptedInvites, errorPendingInvites, refreshInvites };
  }, [dataAcceptedInvites, dataPendingInvites, errorAcceptedInvites, errorPendingInvites, refreshInvites]);

  return <InviteContext.Provider value={value}>{children}</InviteContext.Provider>;
};

export const useInvites = () => {
  const context = useContext(InviteContext);
  if (!context) {
    throw new Error('useInvites must be used within an InviteProvider');
  }
  return context;
};
