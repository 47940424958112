import { config } from '@/config';
import useShowIntegrations from '@/hooks/useShowIntegrations';
import { useInternalUsers } from '@/providers/InternalUserProvider';
import { Permissions } from '@/utils/permissions';
import { Routes } from '@/utils/routes';
import { DropdownMenu } from '@dojo-engineering/radix-ui';
import { analyticsMap, useAnalytics } from 'framework';
import { i18n } from 'legacy-i18n';
import { useCallback, useState } from 'react';
import Authorization from '../../navigation/authorization/authorization';
import * as styles from './accountMenu.css';
import { AccountMenuHeader } from './header/accountMenuHeader';
import AccountMenuItem from './item/accountMenuItem';

export const AccountMenu = () => {
  const [open, setOpen] = useState(false);

  const { isInternalUser } = useInternalUsers();
  const { analytics } = useAnalytics();

  const shouldShowReferAFriend = config.featureFlags['APEX-499-refer-a-friend'];
  const showAccountingDropdownFlag = config.featureFlags['COS-199-show-accounting-integrations-dropdown'];
  const shouldShowIntegrations = useShowIntegrations(showAccountingDropdownFlag);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      let analyticKey = analyticsMap.Account;
      const extendedAnalyticKey = !open ? { ...analyticKey, action: analyticKey.action.replace('opened', 'closed') } : analyticKey;
      analytics.clickedElementV2(extendedAnalyticKey?.category, extendedAnalyticKey?.action);

      setOpen(open);
    },
    [analytics]
  );

  return (
    <div className={styles.parentContainer} id="account-menu">
      <DropdownMenu.Root open={open} onOpenChange={handleOpenChange}>
        <AccountMenuHeader open={open} />

        <DropdownMenu.Portal>
          <DropdownMenu.Content className={styles.content} sideOffset={5} align={'end'} alignOffset={5} loop={true}>
            <AccountMenuItem route={Routes.accountDetails} analyticKey={analyticsMap['Account details']}>
              {i18n.t('global:global.navigation.account_details_menu_item')}
            </AccountMenuItem>

            <AccountMenuItem route={Routes.locations} analyticKey={analyticsMap['Location details']}>
              {i18n.t('global:global.navigation.location_details_menu_item')}
            </AccountMenuItem>

            <Authorization key="my-details" shouldShow={!isInternalUser}>
              <AccountMenuItem route={Routes.myDetails} analyticKey={analyticsMap['My details']}>
                {i18n.t('global:global.navigation.my_details_menu_item')}
              </AccountMenuItem>
            </Authorization>

            <Authorization key="integrations" shouldShow={shouldShowIntegrations}>
              <AccountMenuItem route={Routes.integrations} analyticKey={analyticsMap['Accounting integrations']}>
                {i18n.t('global:global.navigation.accounting_integrations_menu_item')}
              </AccountMenuItem>
            </Authorization>

            <Authorization key="team-members" permission={Permissions.teamMembersView}>
              <AccountMenuItem route={Routes.teams} analyticKey={analyticsMap['Team members']}>
                {i18n.t('global:global.navigation.team_members_menu_item')}
              </AccountMenuItem>
            </Authorization>

            <Authorization key="billing-menu" permission={Permissions.billingInvoiceView}>
              <AccountMenuItem route={Routes.billing} analyticKey={analyticsMap['Billing']}>
                {i18n.t('global:global.navigation.billing_menu_item')}
              </AccountMenuItem>
            </Authorization>

            <Authorization key="pci-compliance" permission={Permissions.pciComplianceView}>
              <AccountMenuItem route={Routes.pciCompliance} analyticKey={analyticsMap['PCI compliance']}>
                {i18n.t('global:global.navigation.pci_compliance_menu_item')}
              </AccountMenuItem>
            </Authorization>

            <Authorization key="refer-a-friend" permission={Permissions.referralAllView} shouldShow={shouldShowReferAFriend}>
              <AccountMenuItem route={Routes.referAFriend} analyticKey={analyticsMap['Refer a friend']}>
                {i18n.t('global:global.navigation.refer_a_friend_menu_item')}
              </AccountMenuItem>
            </Authorization>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};
