import { Role } from '@/graphql/generated';
import { usePermissions } from '@/providers/PermissionsProvider/PermissionsProvider';
import { FEATURE_DOJO_CARD_INTEREST } from '@/utils/launchDarklyFlags';
import useLaunchDarkly from './useLaunchDarkly';

const useShouldShowDojoCardInterest = () => {
  const { getFlagValue } = useLaunchDarkly();
  const { role, loading } = usePermissions();

  const shouldShowDojoCardInterest = getFlagValue(FEATURE_DOJO_CARD_INTEREST) === 'true' && (role === Role.Owner || role === Role.Admin);

  return { shouldShowDojoCardInterest, loading };
};

export default useShouldShowDojoCardInterest;
