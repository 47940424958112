import { useInternalUsers } from '@/providers/InternalUserProvider';
import { Routes, addBasePath } from '@/utils/routes';
import { DjDrawer } from '@paymentsense/dj-components-react';
import { analyticsMap, useAnalytics } from 'framework';
import Router from 'next/router';
import { memo } from 'react';
import CustomerNav from './customerNav/customerNav';
import InternalUserNav from './internalUserNav/internalUserNav';
import styles from './navigation.module.css';

const Navigation = () => {
  const { isInternalUser } = useInternalUsers();
  const { analytics } = useAnalytics();

  const onBannerClick = () => {
    Router.push(Routes.home, addBasePath(Routes.home));
    analytics.clickedElementV2(analyticsMap.Logo.category, analyticsMap.Logo.action);
  };

  return (
    <DjDrawer
      animatedCloseMenu={false}
      bannerIcon="dojo_horizontal"
      onBannerClick={onBannerClick}
      closeButtonVariant="secondary"
      mobileFullWidth
      className={styles.drawer}
    >
      {isInternalUser ? <InternalUserNav /> : <CustomerNav />}
    </DjDrawer>
  );
};

export default memo(Navigation);
