import { sprinkles } from '@dojo-engineering/styled-system';
import { DjDrawerButton } from '@paymentsense/dj-components-react';
import { usePageHeader } from 'framework';
import { DjpTitle } from 'legacy-stencil-react';
import { AccountMenu } from './accountMenu/accountMenu';
import * as styles from './header.css';
import { TransferReference } from './transferReference';

export const Header = () => {
  const { title, navigationComponent } = usePageHeader();

  const handleClick = () => {
    const drawer = document.querySelector('dj-drawer').menu as HTMLIonMenuElement;
    if (drawer) {
      drawer.open();
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.hamburger} data-testid="hamburger_menu_icon">
        <DjDrawerButton role="button" tabIndex={0} aria-label="Open navigation menu" onClick={handleClick} />
      </div>
      <div className={styles.pageTitle} data-testid={navigationComponent ? '' : 'page_title'}>
        {title ? (
          <div className={styles.wrapper}>
            <div className={styles.titleText} data-testid="page_title">
              {title}
            </div>
            <div className={sprinkles({ display: 'flex', alignItems: 'center' })} style={{ marginLeft: 'auto' }} data-testid="page-top-navigation">
              {navigationComponent && <div className={styles.navigationComponent}>{navigationComponent}</div>}
              <TransferReference />
              <AccountMenu />
            </div>
          </div>
        ) : (
          <>
            <DjpTitle className={styles.title} />
            <div className={sprinkles({ display: 'flex', alignItems: 'center' })} style={{ marginLeft: 'auto' }}>
              <TransferReference />
              <AccountMenu />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
