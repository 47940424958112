import { Routes, addBasePath } from '@/utils/routes';
import { useAnalytics } from 'framework';
import { i18n } from 'legacy-i18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useCallback, useMemo } from 'react';
import NavItem from '../navItem/navItem';

const NavLink = ({
  analyticKey,
  href,
  icon,
  sub,
  text,
  showNew,
}: {
  analyticKey: { category: string; action: string };
  href: string;
  icon?: JSX.Element;
  sub?: boolean;
  text: string;
  showNew?: boolean;
}) => {
  const { pathname } = useRouter();
  const active = Routes.home === href ? pathname === href : pathname.includes(href);
  const translatedText = useMemo(() => i18n.t(text), [text]);
  const testId = useMemo(() => `navLink_${translatedText?.replace(/\s/g, '_')}`, [translatedText]);
  const { analytics } = useAnalytics();

  const handleClick = useCallback(() => {
    analytics.clickedElementV2(analyticKey?.category, analyticKey?.action);
  }, [analytics, analyticKey]);

  // NOTE: prefetch={false} disables prefetching destination page data
  // At the time being we are not using SSR, enable it otherwise.
  // https://nextjs.org/docs/pages/api-reference/components/link#prefetch
  return (
    <Link href={href} as={addBasePath(href)} data-testid={testId} prefetch={false} style={{ all: 'unset' }} onClick={handleClick}>
      <NavItem active={active} sub={sub} icon={icon} showNew={showNew}>
        {translatedText}
      </NavItem>
    </Link>
  );
};

export default memo(NavLink);
