import { EligibleProductsContext } from '@/providers/EligibleProductsProvider/EligibleProductsProvider';
import { useContext } from 'react';

export default function useEligibleProducts() {
  const context = useContext(EligibleProductsContext);

  if (!context) {
    throw new Error('useEligibleProducts must be used within an EligibleProductsProvider');
  }

  return context;
}
