interface ProductIds {
  RMS: string;
  BusinessFunding: string;
  Deposits: string;
  OnlineCheckout: string;
}

interface ProductNames {
  [key: string]: string;
}

interface TrackingBodyText {
  OutForSignature: string[];
  UnderReview: string[];
  SettingUp: string[];
  Complete: string[];
}

interface TrackingRejectedText {
  [key: string]: string;
}

interface TrackingChipType {
  OutForSignature: string;
  UnderReview: string;
  SettingUp: string;
  Complete: string | null;
}

interface TrackingChipText {
  OutForSignature: string;
  UnderReview: string;
  SettingUp: string;
  Complete: string | null;
}

interface TrackingAction {
  action: boolean;
  actionType: string | null;
  actionText: string | null;
}

interface TrackingActions {
  OutForSignature: TrackingAction;
  UnderReview: TrackingAction;
  SettingUp: TrackingAction;
  Complete: TrackingAction;
}

interface DayLabels {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  any: string;
}

interface TimeLabels {
  nine: string;
  ten: string;
  eleven: string;
  twelve: string;
  one: string;
  two: string;
  three: string;
  four: string;
  five: string;
}

interface DayLabelsToEnum {
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  'Any day': string;
}

interface TimeLabelsToEnum {
  '9:00 AM': string;
  '10:00 AM': string;
  '11:00 AM': string;
  '12:00 PM': string;
  '1:00 PM': string;
  '2:00 PM': string;
  '3:00 PM': string;
  '4:00 PM': string;
  '5:00 PM': string;
  'Any time': string;
}

interface ActionHandlers {
  integration: (context: any, locationId: string) => void;
}

export const PRODUCT_IDS: ProductIds = {
  RMS: '313a7e29-95da-4838-a2c2-f4bdde8df922',
  BusinessFunding: '90894fb9-ba73-4aa4-af0f-a661a115785d',
  Deposits: '7cf6c19d-f8f6-4758-8871-a6fab9e20e25',
  OnlineCheckout: 'd6111bd6-2a77-4982-981b-102892c1765b',
};

export const PRODUCT_NAMES: ProductNames = {
  '313a7e29-95da-4838-a2c2-f4bdde8df922': 'Dojo bookings',
  '90894fb9-ba73-4aa4-af0f-a661a115785d': 'Business funding',
  '7cf6c19d-f8f6-4758-8871-a6fab9e20e25': 'Deposits',
};

export const TRACKING_BODY_TEXT: TrackingBodyText = {
  OutForSignature: [
    "Thank you for applying for Dojo bookings. You're almost there, we just need you to accept your agreement, which can be found in your email.",
    "Your direct debit mandate will be confirmed when you've signed the agreement and your application has been approved.",
  ],
  UnderReview: [
    'We are currently reviewing your application, we hope to make a decision in the next 1-2 working days.',
    'You will receive an email once a decision has been made.',
  ],
  SettingUp: [
    "Congratulations, you've got Dojo bookings.",
    "We're currently setting up your account and your direct debit mandate, please check back in a few minutes.",
  ],
  Complete: ['You should be expecting a call from our integrations team in the next 1-2 days to get you set up.'],
};

export const TRACKING_REJECTED_TEXT: TrackingRejectedText = {
  '7cf6c19d-f8f6-4758-8871-a6fab9e20e25':
    'We were unable to approve your business for deposits. If you would like to cancel your order with us, please chat to us on Dojo Bookings web chat.',
};

export const TRACKING_CHIP_TYPE: TrackingChipType = {
  OutForSignature: 'intermediate',
  UnderReview: 'neutral',
  SettingUp: 'neutral',
  Complete: null,
};

export const TRACKING_CHIP_TEXT: TrackingChipText = {
  OutForSignature: 'Action required',
  UnderReview: 'Reviewing application',
  SettingUp: 'Setting up your account',
  Complete: null,
};

export const TRACKING_ACTION: TrackingActions = {
  OutForSignature: {
    action: false,
    actionType: null,
    actionText: null,
  },
  UnderReview: {
    action: false,
    actionType: null,
    actionText: null,
  },
  SettingUp: {
    action: false,
    actionType: null,
    actionText: null,
  },
  Complete: {
    action: false,
    actionType: null,
    actionText: null,
  },
};

export const DAY_LABELS: DayLabels = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  any: 'Any day',
};

export const TIME_LABELS: TimeLabels = {
  nine: '9:00 AM',
  ten: '10:00 AM',
  eleven: '11:00 AM',
  twelve: '12:00 PM',
  one: '1:00 PM',
  two: '2:00 PM',
  three: '3:00 PM',
  four: '4:00 PM',
  five: '5:00 PM',
};

export const DAY_LABELS_TO_ENUM: DayLabelsToEnum = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  'Any day': 'Anyday',
};

export const TIME_LABELS_TO_ENUM: TimeLabelsToEnum = {
  '9:00 AM': '_9AM',
  '10:00 AM': '_10AM',
  '11:00 AM': '_11AM',
  '12:00 PM': '_12PM',
  '1:00 PM': '_1PM',
  '2:00 PM': '_2PM',
  '3:00 PM': '_3PM',
  '4:00 PM': '_4PM',
  '5:00 PM': '_5PM',
  'Any time': '_Anytime',
};

export const ACTION_HANDLERS: ActionHandlers = {
  integration: (context, locationId) => {
    context.integrationDialog(locationId);
  },
};
