import { config } from '@/config';
import { GB } from '@/constants/markets';
import { useCustomers } from '@/providers/CustomersProvider/CustomersProvider';
import accountingIntegrationsCustomersJson from '../config/accounting-integrations-customers.json';

const checkCustomerIdInCustomerList = (customerId: string): boolean => {
  return accountingIntegrationsCustomersJson.some((x) => x === customerId);
};

export default function useShowIntegrations(featureFlagValue: boolean) {
  const { customer } = useCustomers();

  if (!customer) return false;

  const { marketId } = customer;

  const isAccountingIntegrationsEnabled = config.featureFlags['CX-4444-accounting-integrations'] && marketId === GB;

  if (!featureFlagValue) {
    const customerInList = checkCustomerIdInCustomerList(customer.customerId);

    return isAccountingIntegrationsEnabled && customerInList;
  }

  return isAccountingIntegrationsEnabled;
}
