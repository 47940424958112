'use client';
import { i18n } from 'legacy-i18n';
import { useEffect, useState } from 'react';

export default function useI18nLoaded() {
  const [i18nLoaded, setI18nLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // In case the i18n is not loaded after 2 seconds, we set it to true anyway to avoid blocking the app
      setI18nLoaded(true);
    }, 2_000);

    interface Loaded {
      [key: string]: any;
    }

    const loadedCb = (loaded: Loaded) => {
      if (Object.keys(loaded).length > 0) {
        setI18nLoaded(true);
        clearTimeout(timeoutId);
      }
    };

    i18n.on('loaded', loadedCb);

    return () => {
      i18n.off('loaded', loadedCb);
    };
  }, []);

  return { i18nLoaded, error };
}
