import { BrazeContext } from '@/providers/BrazeProvider/BrazeProvider';
import { useContext } from 'react';

export default function useBraze() {
  const braze = useContext(BrazeContext);

  if (!braze) {
    throw new Error('useBraze must be used within a BrazeProvider');
  }

  return braze;
}
