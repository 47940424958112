import { DjpClientSizeProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export type LegacyClientSizeProviderProps = {
  children: ReactNode;
};

export const LegacyClientSizeProvider = ({ children }: LegacyClientSizeProviderProps) => {
  return <DjpClientSizeProvider>{children}</DjpClientSizeProvider>;
};
