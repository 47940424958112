import { WarningTriangle } from '@dojo-engineering/react-icons';
import { i18n } from 'legacy-i18n';
import * as styles from './demoAccountBanner.css';

export default function DemoAccountBanner() {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <WarningTriangle />
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.heading}>{i18n.t('global:global.demo_account.title')}</h3>
        <p className={styles.description}>{i18n.t('global:global.demo_account.subtitle')}</p>
      </div>
    </div>
  );
}
