import { useApolloClient } from '@apollo/client';
import { DjpApolloProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export interface LegacyApolloProviderProps {
  children: ReactNode;
}

export function LegacyApolloProvider({ children }: LegacyApolloProviderProps) {
  const client = useApolloClient();

  return (
    <>
      <DjpApolloProvider client={client as any} />
      {children}
    </>
  );
}
