import { useAnalytics } from 'framework';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { DjpExperimentFlagsProvider } from 'legacy-stencil-react';
import { useMemo } from 'react';

export const LegacyLaunchDarklyProvider = () => {
  const { analytics } = useAnalytics();
  const ldClient = useLDClient();
  const experimentFlags = useMemo(() => {
    const flags = ldClient?.allFlags() ?? {};
    const flagArray = [];

    if (flags) {
      for (const field of Object.keys(flags)) {
        flagArray.push({ name: field, value: flags[field] });
      }
    }
    analytics.featureFlag(flagArray);

    return flags;
  }, [ldClient, analytics]);

  return <DjpExperimentFlagsProvider experimentFlags={experimentFlags} />;
};
