import { FEATURE_NEW_ONLINE_CHECKOUT } from '@/utils/launchDarklyFlags';
// FIXME: relative URL - should be a package import
import { PRODUCT_IDS } from '../../../../packages/legacy-stencil/src/constants/productConstants';
import useEligibleProducts from './useEligibleProducts';
import useLaunchDarkly from './useLaunchDarkly';

export default function useShowNewOnlinePayments() {
  const { getFlagValue } = useLaunchDarkly();
  const { products } = useEligibleProducts();

  const featureFlagEnabled = getFlagValue(FEATURE_NEW_ONLINE_CHECKOUT) === true;

  return products?.includes(PRODUCT_IDS.OnlineCheckout) && featureFlagEnabled;
}
