import { useCustomers } from '@/providers/CustomersProvider/CustomersProvider';
import { DjpMarketProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export type LegacyMarketProviderProps = {
  children: ReactNode;
};

export const LegacyMarketProvider = ({ children }: LegacyMarketProviderProps) => {
  const { customer } = useCustomers();
  // fall back to GB if customer is not loaded yet
  const marketId = customer?.marketId ?? 'GB';

  return <DjpMarketProvider marketId={marketId}>{children}</DjpMarketProvider>;
};
