import { ReactNode } from 'react';
import { LegacyAnalyticsProvider } from './LegacyAnalyticsProvider';
import { LegacyApiConfigProvider } from './LegacyApiConfigProvider';
import { LegacyApolloProvider } from './LegacyApolloProvider';
import { LegacyBrazeProvider } from './LegacyBrazeProvider';
import { LegacyClientSizeProvider } from './LegacyClientSizeProvider';
import { LegacyFeatureFlagsProvider } from './LegacyFeatureFlagsProvider';
import { LegacyGraphqlErrorHandlerProvider } from './LegacyGraphqlErrorHandlerProvider';
import { LegacyI18nProvider } from './LegacyI18nProvider';
import { LegacyLaunchDarklyProvider } from './LegacyLaunchDarklyProvider';
import { LegacyLinksProvider } from './LegacyLinksProvider';
import { LegacyLoggerProvider } from './LegacyLoggerProvider';
import { LegacyMarketProvider } from './LegacyMarketProvider';
import { LegacyRouterProvider } from './LegacyRouterProvider';
import { LegacyUserProvider } from './LegacyUserProvider';

export type LegacyProvidersProps = {
  children: ReactNode;
};

export function LegacyProvidersNoAuth({ children }: LegacyProvidersProps) {
  return (
    <LegacyLoggerProvider>
      <LegacyAnalyticsProvider>
        <LegacyUserProvider>
          <LegacyApolloProvider>
            <LegacyI18nProvider>
              <LegacyRouterProvider>
                <LegacyFeatureFlagsProvider>
                  <LegacyApiConfigProvider>
                    <LegacyClientSizeProvider>{children}</LegacyClientSizeProvider>
                  </LegacyApiConfigProvider>
                </LegacyFeatureFlagsProvider>
              </LegacyRouterProvider>
            </LegacyI18nProvider>
          </LegacyApolloProvider>
        </LegacyUserProvider>
      </LegacyAnalyticsProvider>
    </LegacyLoggerProvider>
  );
}

export function LegacyProviders({ children }: LegacyProvidersProps) {
  return (
    <LegacyProvidersNoAuth>
      <LegacyGraphqlErrorHandlerProvider>
        <LegacyMarketProvider>
          <LegacyLaunchDarklyProvider />
          <LegacyLinksProvider>
            <LegacyBrazeProvider>{children}</LegacyBrazeProvider>
          </LegacyLinksProvider>
        </LegacyMarketProvider>
      </LegacyGraphqlErrorHandlerProvider>
    </LegacyProvidersNoAuth>
  );
}
