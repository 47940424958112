import { AnalyticsAction, AnalyticsCategory } from '@/constants/analytics';
import { OnboardingStep } from '@/graphql/generated';
import { useAnalytics } from 'framework';
import { i18n } from 'legacy-i18n';
import { useEffect, useState } from 'react';
import { tourStepsData } from '../onboardingModal';
import StepIndicators from './stepIndicators/stepIndicators';
import { button, centeringContainer, centeringContainerColumn, description, imageContainer, innerContent, spacingContainer, title } from './tourSteps.css';

type TourStepsProps = {
  onTourComplete: () => void;
  steps: OnboardingStep[];
};

const TourSteps = ({ onTourComplete, steps }: TourStepsProps) => {
  const { analytics } = useAnalytics();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    analytics.page(AnalyticsCategory.onboardingProductTour, { name: tourStepsData[currentStep].analyticsName }, i18n.t(steps[currentStep].title));
  }, [analytics, currentStep, steps]);

  const onNext = () => {
    if (currentStep >= steps.length - 1) {
      onTourComplete();
    }
    setCurrentStep((value) => value + 1);
    analytics.clickedElementV2(AnalyticsCategory.onboardingProductTour, AnalyticsAction.clickedNext, tourStepsData[currentStep].analyticsLabel);
  };

  return (
    <div className={centeringContainerColumn}>
      <div className={centeringContainer}>
        <div className={innerContent}>
          <div className={spacingContainer}>
            <div>
              <img className={imageContainer} src={steps[currentStep].image} alt="assets/images/getStarted.png"></img>
              <h3 className={title}>{steps[currentStep].title}</h3>
              <p className={description}>{steps[currentStep].description}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button onClick={onNext} className={button} data-testid={`step-indicator-${currentStep}`}>
          {steps[currentStep].buttonTitle}
        </button>
        <StepIndicators stepCount={steps.length} currentStep={currentStep} onIndicatorClick={setCurrentStep} />
      </div>
    </div>
  );
};

export default TourSteps;
