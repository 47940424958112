const CI_ENVIRONMENT = process.env.BRANCH_NAME?.match(/[a-z]{2,5}-\d{1,5}/gi)?.[0];

export const config = {
  // apiUrl: process.env.API_URL,
  accountingRedirectUrl: process.env.NEXT_PUBLIC_ACCOUNTING_REDIRECT_URL,
  appVersion: process.env.NEXT_PUBLIC_APP_VERSION,
  brazeEnabled: process.env.NEXT_PUBLIC_BRAZE_ENABLED === 'true',
  businessGroup: process.env.NEXT_PUBLIC_BUSINESS_GROUP,
  commitHash: process.env.NEXT_PUBLIC_COMMIT_HASH,
  devPortalUrl: process.env.NEXT_PUBLIC_DEVELOPER_PORTAL_URL,
  featureFlags: JSON.parse(process.env.NEXT_PUBLIC_FEATURE_FLAGS),
  graphqlUrl: process.env.GRAPHQL_URL,
  host: process.env.NEXT_PUBLIC_AUTH0_BASE_URL,
  launchDarklyClientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  launchDarklySDKKey: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_SDK_KEY,
  logRocketAppId: process.env.NEXT_PUBLIC_LOGROCKET_APP_ID,
  passwordPath: process.env.NEXT_PUBLIC_PASSWORD_PATH,
  rudderstackKey: process.env.NEXT_PUBLIC_RUDDERSTACK_KEY,
  rudderstackUrl: process.env.NEXT_PUBLIC_RUDDERSTACK_URL,
  serviceEnvironment: CI_ENVIRONMENT ?? process.env.ENVIRONMENT ?? 'localhost',
  superpayAppsDocumentPath: process.env.SUPERPAY_APPS_DOCUMENTS_PATH,
  supportedLanguages: JSON.parse(process.env.NEXT_PUBLIC_SUPPORTED_LANGUAGES),
  osanoScriptUrl: process.env.OSANO_SCRIPT_URL,
  userIdentityGatewayPath: process.env.NEXT_PUBLIC_USER_IDENTITY_GATEWAY_PATH,
};
