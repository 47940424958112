import { useAuth } from 'framework';
import { DjpUserProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export type LegacyUserProviderProps = {
  children: ReactNode;
};

export const LegacyUserProvider = ({ children }: LegacyUserProviderProps) => {
  const { user } = useAuth();

  const userProvider = {
    email: user?.email,
    uid: user?.dojoId as string,
  };
  return <DjpUserProvider user={userProvider}>{children}</DjpUserProvider>;
};
