import { DjpRouterProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export type LegacyRouterProviderProps = {
  children: ReactNode;
};

export const LegacyRouterProvider = ({ children }: LegacyRouterProviderProps) => {
  return <DjpRouterProvider>{children}</DjpRouterProvider>;
};
