import { usePhoneNumbersQuery } from '@/graphql/generated';
import { createContext, ReactNode } from 'react';
import { useCustomers } from '../CustomersProvider/CustomersProvider';

export interface PhoneNumbers {
  CustomerService: string;
  YoulendPhoneNumber: string;
}

export interface PhoneNumbersContextType {
  loading: boolean;
  phoneNumbers: PhoneNumbers;
}

export const PhoneNumbersContext = createContext<PhoneNumbersContextType>({
  loading: false,
  phoneNumbers: {} as PhoneNumbers,
});

export interface PhoneNumbersProviderProps {
  children: ReactNode;
}

export const PhoneNumbersProvider = ({ children }: PhoneNumbersProviderProps) => {
  const { loading: loadingCustomer, customer } = useCustomers();

  const { loading, data, error } = usePhoneNumbersQuery({ skip: loadingCustomer, variables: { marketId: customer?.marketId } });

  const phoneNumbers = data?.phoneNumbers?.reduce((accum, phoneNumber) => {
    accum[phoneNumber.id as keyof typeof accum] = phoneNumber.value;
    return accum;
  }, {} as PhoneNumbers);

  return <PhoneNumbersContext.Provider value={{ loading, phoneNumbers }}>{children}</PhoneNumbersContext.Provider>;
};
