import { AnalyticsAction, AnalyticsCategory } from '@/constants/analytics';
import { Role, useContactInfoQuery, useOnboardingQuery, useUpdateViewedStateMutation, ViewedState } from '@/graphql/generated';
import useLaunchDarkly from '@/hooks/useLaunchDarkly';
import { useCustomers } from '@/providers/CustomersProvider/CustomersProvider';
import { useInternalUsers } from '@/providers/InternalUserProvider';
import { usePermissions } from '@/providers/PermissionsProvider/PermissionsProvider';
import { FEATURE_ONBOARDING_FLOW } from '@/utils/launchDarklyFlags';
import { Close } from '@dojo-engineering/react-icons';
import * as Dialog from '@radix-ui/react-dialog';
import { useAnalytics } from 'framework';
import { useEffect, useRef, useState } from 'react';
import { closeButton, closeIcon, content, overlay, topSection } from './onboardingModal.css';
import TourSteps from './tourSteps/tourSteps';

const numbers = ['StepOne', 'StepTwo', 'StepThree', 'StepFour', 'StepFive', 'StepSix', 'StepSeven', 'StepEight', 'StepNine', 'StepTen'];

export const tourStepsData = numbers.map((item) => {
  const container = { analyticsName: 'onboarding' + item, analyticsLabel: item };
  return container;
});

const OnboardingModal = () => {
  const { isInternalUser } = useInternalUsers();
  const overlayRef = useRef<HTMLDivElement>(null);
  const { analytics } = useAnalytics();
  const { getFlagValue } = useLaunchDarkly();

  const featureOnboardingFlowFlag = getFlagValue(FEATURE_ONBOARDING_FLOW) === 'true';

  const { data: contact, loading: loadingContact } = useContactInfoQuery({ skip: !featureOnboardingFlowFlag });
  const { role, loading: loadingRole } = usePermissions();

  const [open, setOpen] = useState(true);

  const { customer } = useCustomers();

  const { data: onboarding } = useOnboardingQuery({
    skip: isInternalUser || !contact?.contact?.firstName,
    variables: {
      customerId: customer?.customerId,
      firstName: contact?.contact?.firstName,
      mcc: customer?.locations?.[0]?.industry?.mcc,
    },
  });

  const [updateViewedState] = useUpdateViewedStateMutation();

  const doNotShow =
    !onboarding?.onboardingInfo?.show || !featureOnboardingFlowFlag || !open || loadingContact || loadingRole || !(role === Role.Owner || role === Role.Admin);

  useEffect(() => {
    if (!doNotShow) {
      analytics.page(AnalyticsCategory.onboardingProductTour, { name: 'onboardingStepOne' });
    }
  }, [doNotShow, analytics]);

  if (doNotShow) {
    return null;
  }

  const stepContent = onboarding?.onboardingInfo?.content;

  const onTourComplete = () => {
    updateViewedState({
      variables: {
        viewedstate: ViewedState.Completed,
      },
    });
    setOpen(false);
    let max = stepContent.length - 1;
    analytics.clickedElementV2(AnalyticsCategory.onboardingProductTour, AnalyticsAction.clickedDone, tourStepsData[max].analyticsLabel);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      updateViewedState({
        variables: {
          viewedstate: ViewedState.Completed,
        },
      });
      analytics.clickedElementV2(AnalyticsCategory.onboardingProductTour, AnalyticsAction.clickedClose);
    }
    setOpen(open);
  };

  const onInteractOutside = (e: Event) => {
    if (e.target !== overlayRef.current) {
      e.preventDefault();
    }
  };

  return (
    <Dialog.Root open={true} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={overlay} ref={overlayRef}>
          <Dialog.Content onInteractOutside={onInteractOutside} className={content}>
            <div className={topSection}>
              <Dialog.Close asChild>
                <button className={closeButton}>
                  <Close className={closeIcon} />
                </button>
              </Dialog.Close>
            </div>
            <TourSteps onTourComplete={onTourComplete} steps={stepContent} />
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default OnboardingModal;
