import { useLinksQuery } from '@/graphql/generated';
import { createContext, ReactNode } from 'react';
import { useCustomers } from '../CustomersProvider/CustomersProvider';

export interface Links {
  PrivacyPolicy: string;
  YapilyLegal: string;
  DojoSupportEmail: string;
  YoulendPrivacyPolicy: string;
  YoulendSupportEmail: string;
  CIFASFairProcessingNotice: string;
  YoulendTermsAndConditions: string;
  YoulendPaymentAccountTerms: string;
  [key: string]: string;
}

export interface LinksContextType {
  loading: boolean;
  links?: Links;
}

export const LinksContext = createContext<LinksContextType>({
  loading: false,
  links: {} as Links,
});

export const LinksProvider = ({ children }: { children: ReactNode }) => {
  const { loading: loadingCustomer, customer } = useCustomers();

  // fall back to GB if customer is not loaded yet
  const marketId = customer?.marketId ?? 'GB';

  const { loading, data, error } = useLinksQuery({ skip: loadingCustomer, variables: { marketId: marketId } });

  const links = data?.links?.reduce((accum, link) => {
    accum[link.id] = link.value;
    return accum;
  }, {} as Links);

  return <LinksContext.Provider value={{ loading, links }}>{children}</LinksContext.Provider>;
};
