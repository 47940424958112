import { useAnalytics } from 'framework';
import { DjpAnalyticsProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export interface LegacyAnalyticsProviderProps {
  children: ReactNode;
}

export const LegacyAnalyticsProvider = ({ children }: LegacyAnalyticsProviderProps) => {
  const { analytics } = useAnalytics();

  return <DjpAnalyticsProvider analytics={analytics}>{children}</DjpAnalyticsProvider>;
};
