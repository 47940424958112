import SectionAuthorization from '@/components/home/sections/sectionAuthorization';
import { usePermissions } from '@/providers/PermissionsProvider/PermissionsProvider';
import { headingRecipe, sprinkles } from '@dojo-engineering/styled-system';
import { i18n } from 'legacy-i18n';
import React from 'react';
import HomeTile from '../homeTile/homeTile';

type Tile = {
  permissions?: string[];
  shouldShow?: boolean;
  heading?: string;
  body?: string;
  Icon?: React.ReactNode;
  linkPath?:
    | string
    | {
        url: string;
        external?: boolean;
        target?: HTMLAnchorElement['target'];
      };
  query?: { [key: string]: string };
  analyticsPageSection?: string;
  analyticsActionSuffix?: string;
};

type TiledSectionProps = {
  heading: string;
  requiredPermissions?: string[];
  tiles: Tile[];
};

export default function TiledSection({ heading, requiredPermissions, tiles }: TiledSectionProps) {
  const { permissions } = usePermissions();

  const permissionShouldShows = tiles.flatMap(
    (tile) =>
      tile.permissions?.map((tp) => ({ permission: tp, shouldShow: tile.shouldShow ?? true })) ?? [{ permission: null, shouldShow: tile.shouldShow ?? true }]
  );

  const shouldShowTile = (tile: Tile): boolean => {
    if (tile.shouldShow === false) {
      return false;
    }

    if (tile.permissions && tile.permissions.length > 0 && !tile.permissions.some((tP) => permissions.some((p) => p.name === tP && p.enabled === true))) {
      return false;
    }

    return true;
  };

  return (
    <SectionAuthorization requiredPermissions={requiredPermissions} permissionShouldShows={permissionShouldShows}>
      <div>
        <div className={headingRecipe({ h: 5, variant: 'default' })} style={{ marginBottom: '16px' }}>
          {i18n.t(heading)}
        </div>
        <div className={sprinkles({ display: 'flex', flexDirection: 'column' })} style={{ rowGap: '12px' }}>
          {tiles.map((tile, index) => {
            if (!shouldShowTile(tile)) {
              return null;
            }

            return (
              <HomeTile
                key={index}
                Icon={tile.Icon}
                heading={tile.heading}
                body={tile.body}
                path={tile.linkPath}
                analyticsPageSection={tile.analyticsPageSection}
                analyticsActionSuffix={tile.analyticsActionSuffix}
                query={tile.query}
              />
            );
          })}
        </div>
      </div>
    </SectionAuthorization>
  );
}
