import { Customer, CustomerRolePermission, InternalUser, useCustomerQuery, useInternalUserInfoQuery } from '@/graphql/generated';
import { Routes, addBasePath } from '@/utils/routes';
import { useUser } from 'framework';
import Router, { useRouter } from 'next/router';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export interface InternalUserContextProps {
  internalUserInfo?: InternalUser;
  isInternalUser: boolean;
  internalUserPermissions: CustomerRolePermission[];
  customerMode: boolean;
  selectedCustomer: Customer;
  setSelectedCustomer: (customer: Customer) => void;
}

const InternalUserContext = createContext<InternalUserContextProps>({
  internalUserInfo: undefined,
  isInternalUser: undefined,
  internalUserPermissions: undefined,
  customerMode: undefined,
  selectedCustomer: undefined,
  setSelectedCustomer: (_customer: Customer) => {},
});

export interface InternalUserProviderProps {
  children: ReactNode;
}

export const InternalUserProvider = ({ children }: InternalUserProviderProps) => {
  const user = useUser();
  const router = useRouter();
  const { data: internalUserInfoData } = useInternalUserInfoQuery({ fetchPolicy: 'cache-first', skip: !user.user?.dojoId });
  const [internalUserInfo, setInternalUserInfo] = useState<InternalUser>(null);
  const [isInternalUser, setIsInternalUser] = useState(undefined);
  const [customerMode, setCustomerMode] = useState(undefined);
  const [internalUserPermissions, setInternalUserPermissions] = useState<CustomerRolePermission[]>(undefined);
  const [currentCustomerId, setCurrentCustomerId] = useState<string>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>(null);

  const { loading: loadingCustomer, data: customerData } = useCustomerQuery({
    skip: !user || !isInternalUser || !currentCustomerId,
    variables: { customerId: currentCustomerId },
  });

  const preparePermissions = (permissions: string[]) => {
    return permissions?.map((permission) => {
      return {
        category: 'internalUsers',
        enabled: true,
        name: permission,
        title: '',
      };
    });
  };

  useEffect(() => {
    if (isInternalUser) {
      if (router?.route === '/internal-user') {
        setCustomerMode(false);
      } else {
        setCustomerMode(true);
      }
    }
  }, [isInternalUser, router?.route]);

  useEffect(() => {
    setInternalUserInfo(internalUserInfoData?.internalUserInfo);
    setIsInternalUser(!!internalUserInfoData?.internalUserInfo);
    setInternalUserPermissions(preparePermissions(internalUserInfoData?.internalUserInfo?.permissions));
  }, [internalUserInfoData]);

  useEffect(() => {
    if (!loadingCustomer && customerData?.customer?.customerId) {
      setSelectedCustomer(customerData?.customer as Customer);
    }
  }, [customerData?.customer, loadingCustomer]);

  useEffect(() => {
    if (isInternalUser) {
      const currentCustomerId = localStorage.getItem('currentCustomerId');
      if (currentCustomerId) {
        setCurrentCustomerId(currentCustomerId);
        setCustomerMode(true);
      } else {
        Router.push(Routes.internalUserHome, addBasePath(Routes.internalUserHome));
      }
    }
  }, [isInternalUser, setCustomerMode]);

  const handleCustomerChange = useCallback((customer: Customer) => {
    setSelectedCustomer(customer);
    localStorage.setItem('currentCustomerId', customer?.customerId);
  }, []);

  const value: InternalUserContextProps = useMemo(
    () => ({
      internalUserInfo,
      isInternalUser,
      internalUserPermissions,
      customerMode,
      selectedCustomer,
      setSelectedCustomer: handleCustomerChange,
    }),
    [internalUserInfo, isInternalUser, internalUserPermissions, customerMode, selectedCustomer, handleCustomerChange]
  );

  return <InternalUserContext.Provider value={value}>{children}</InternalUserContext.Provider>;
};

export const useInternalUsers = () => {
  const context = useContext(InternalUserContext);

  if (!context) {
    throw new Error('useInternalUsers must be used within an InternalUserProvider');
  }

  return context;
};
