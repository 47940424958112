import IdleTimer from '@/components/idleTimer/idleTimer';
import ProtectedPages from '@/components/protectedPages/protectedPages';
import { config } from '@/config';
import { AnalyticsAction, AnalyticsCategory } from '@/constants/analytics';
import { LegacyProviders } from '@/providers-legacy/LegacyProviders';
import { ApiRoutes, Routes, RoutesNoAuth } from '@/utils/routes';
import { AnalyticsProvider, AuthProvider, LoggerProvider, PageHeaderProvider, UserProvider } from 'framework';
import { ApolloProvider } from './ApolloProvider';
import { BrazeProvider } from './BrazeProvider/BrazeProvider';
import { CustomersProvider } from './CustomersProvider/CustomersProvider';
import { EligibleProductsProvider } from './EligibleProductsProvider/EligibleProductsProvider';
import { InternalUserProvider } from './InternalUserProvider';
import { InviteProvider } from './InviteProvider/InviteProvider';
import { LaunchDarklyProvider } from './LaunchDarklyProvider';
import { LinksProvider } from './LinksProvider/LinksProvider';
import { PermissionsProvider } from './PermissionsProvider/PermissionsProvider';
import { PhoneNumbersProvider } from './PhoneNumbersProvider/PhoneNumbersProvider';

export interface ProvidersProps {
  nonce: string;
  children: JSX.Element;
}

export const Providers = ({ nonce, children }: ProvidersProps) => (
  <LoggerProvider
    logRocketAppId={config.logRocketAppId}
    appVersion={config.appVersion}
    commitHash={config.commitHash}
    serviceEnvironment={config.serviceEnvironment}
  >
    <AnalyticsProvider appVersion={config.appVersion} businessGroup={config.businessGroup}>
      <UserProvider profileUrl={ApiRoutes.profile} loginUrl={ApiRoutes.login}>
        <AuthProvider
          unauthedRoutes={RoutesNoAuth}
          routes={{ login: Routes.login, loginApi: ApiRoutes.login, logoutApi: ApiRoutes.logout }}
          onLogout={(analytics) => analytics.clickedElement(AnalyticsCategory.account, AnalyticsAction.loggedOut)}
        >
          <ApolloProvider>
            <ProtectedPages noAuthChildren={children}>
              <InternalUserProvider>
                <InviteProvider>
                  <CustomersProvider>
                    <LaunchDarklyProvider>
                      <LinksProvider>
                        <PhoneNumbersProvider>
                          <PermissionsProvider>
                            <BrazeProvider nonce={nonce}>
                              <EligibleProductsProvider>
                                <PageHeaderProvider>
                                  <IdleTimer />
                                  {/* TODO: only mount LegacyProviders for Stencil features */}
                                  <LegacyProviders>{children}</LegacyProviders>
                                </PageHeaderProvider>
                              </EligibleProductsProvider>
                            </BrazeProvider>
                          </PermissionsProvider>
                        </PhoneNumbersProvider>
                      </LinksProvider>
                    </LaunchDarklyProvider>
                  </CustomersProvider>
                </InviteProvider>
              </InternalUserProvider>
            </ProtectedPages>
          </ApolloProvider>
        </AuthProvider>
      </UserProvider>
    </AnalyticsProvider>
  </LoggerProvider>
);
