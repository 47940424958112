import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: any;
  JSON: any;
  Timestamp: any;
  URL: any;
  Upload: any;
  Void: any;
};

export type AcceptInviteError = {
  __typename?: 'AcceptInviteError';
  errorType: AcceptInviteErrorType;
};

export enum AcceptInviteErrorType {
  ProcessTimeout = 'ProcessTimeout',
}

export type AcceptInviteResponse = AcceptInviteError | Invite;

export type AcceptOfferRequest = {
  leadId: Scalars['String'];
  offerId: Scalars['String'];
};

export type Account = {
  __typename?: 'Account';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  accountNumber?: Maybe<Scalars['String']>;
  inEligibilityReason?: Maybe<InEligibilityReason>;
  maskedAccountNumber?: Maybe<Scalars['String']>;
};

export enum AccountType {
  Business = 'Business',
  Personal = 'Personal',
}

export type AccountVerification = {
  __typename?: 'AccountVerification';
  accountVerificationStatus: AccountVerificationStatus;
  accountVerificationType: AccountVerificationType;
  accounts?: Maybe<Array<Maybe<Account>>>;
  authorisationUrl?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<BusinessFundingDocument>>>;
  institutionId?: Maybe<Scalars['String']>;
  selectedAccountId?: Maybe<Scalars['String']>;
  statusChangedDate?: Maybe<Scalars['String']>;
};

export type AccountVerificationError = {
  __typename?: 'AccountVerificationError';
  errorType: AccountVerificationErrorKind;
};

export enum AccountVerificationErrorKind {
  Api = 'Api',
  NotFound = 'NotFound',
  Timeout = 'Timeout',
}

export type AccountVerificationResponse = AccountVerification | AccountVerificationError;

export enum AccountVerificationStatus {
  AccountsRetrieved = 'AccountsRetrieved',
  ConsentCancelled = 'ConsentCancelled',
  ConsentFailed = 'ConsentFailed',
  ConsentRequested = 'ConsentRequested',
  ConsentSucceeded = 'ConsentSucceeded',
  DocumentUploaded = 'DocumentUploaded',
  IntentCreated = 'IntentCreated',
  TransactionsRetrieved = 'TransactionsRetrieved',
}

export enum AccountVerificationType {
  DocumentBased = 'DocumentBased',
  OpenBanking = 'OpenBanking',
}

export type AccountingAccount = {
  __typename?: 'AccountingAccount';
  defaultRate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AccountingConfigOptions = {
  __typename?: 'AccountingConfigOptions';
  expenseAccounts?: Maybe<Array<AccountingAccount>>;
  expenseTaxRates?: Maybe<Array<AccountingTaxRate>>;
  isCashbackRequired?: Maybe<Scalars['Boolean']>;
  isGratuityRequired?: Maybe<Scalars['Boolean']>;
  liabilityAccounts?: Maybe<Array<AccountingAccount>>;
  revenueAccounts?: Maybe<Array<AccountingAccount>>;
  revenueTaxRates?: Maybe<Array<AccountingTaxRate>>;
};

export type AccountingConnection = {
  __typename?: 'AccountingConnection';
  billAccount?: Maybe<AccountingAccount>;
  billAccountExempt?: Maybe<AccountingAccount>;
  billAccountStandard?: Maybe<AccountingAccount>;
  billVatExempt?: Maybe<AccountingTaxRate>;
  billVatStandard?: Maybe<AccountingTaxRate>;
  configOptions?: Maybe<AccountingConfigOptions>;
  etag: Scalars['String'];
  invoiceSyncError?: Maybe<Scalars['String']>;
  lastImport?: Maybe<Scalars['String']>;
  nextImport?: Maybe<Scalars['String']>;
  revenueCashback?: Maybe<AccountingAccount>;
  revenueGraduity?: Maybe<AccountingAccount>;
  revenueGratuity?: Maybe<AccountingAccount>;
  revenueRefunds?: Maybe<AccountingAccount>;
  revenueSales?: Maybe<AccountingAccount>;
  revenueVat?: Maybe<AccountingTaxRate>;
  startDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  syncError?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  transferSyncError?: Maybe<Scalars['String']>;
  vatType?: Maybe<VatType>;
};

export type AccountingConnectionCallbackInput = {
  authCode: Scalars['String'];
  authState: Scalars['String'];
};

export type AccountingConnectionLink = {
  __typename?: 'AccountingConnectionLink';
  platform?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AccountingConnectionPatchInput = {
  billAccountExemptId?: InputMaybe<Scalars['ID']>;
  billAccountId?: InputMaybe<Scalars['ID']>;
  billAccountStandardId?: InputMaybe<Scalars['ID']>;
  billVatExemptId?: InputMaybe<Scalars['ID']>;
  billVatStandardId?: InputMaybe<Scalars['ID']>;
  etag: Scalars['String'];
  revenueCashbackId?: InputMaybe<Scalars['ID']>;
  revenueGraduityId?: InputMaybe<Scalars['ID']>;
  revenueGratuityId?: InputMaybe<Scalars['ID']>;
  revenueRefundsId?: InputMaybe<Scalars['ID']>;
  revenueSalesId?: InputMaybe<Scalars['ID']>;
  revenueVatId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['String']>;
  vatType?: InputMaybe<VatType>;
};

export type AccountingConnectionUpdateInput = {
  billAccountExemptId?: InputMaybe<Scalars['ID']>;
  billAccountId?: InputMaybe<Scalars['ID']>;
  billAccountStandardId?: InputMaybe<Scalars['ID']>;
  billVatExemptId?: InputMaybe<Scalars['ID']>;
  billVatStandardId?: InputMaybe<Scalars['ID']>;
  etag?: InputMaybe<Scalars['String']>;
  revenueCashbackId?: InputMaybe<Scalars['ID']>;
  revenueGraduityId?: InputMaybe<Scalars['ID']>;
  revenueGratuityId?: InputMaybe<Scalars['ID']>;
  revenueRefundsId?: InputMaybe<Scalars['ID']>;
  revenueSalesId?: InputMaybe<Scalars['ID']>;
  revenueVatId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['String'];
  vatType?: InputMaybe<VatType>;
};

export type AccountingTaxRate = {
  __typename?: 'AccountingTaxRate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export enum ActivationCodeState {
  NotAvailable = 'NotAvailable',
  NotDispatched = 'NotDispatched',
  Successful = 'Successful',
}

export type AddOnSummaryResponse = {
  __typename?: 'AddOnSummaryResponse';
  id: Scalars['ID'];
  pricingInfo?: Maybe<Array<Maybe<ProductQuestionConfigSummaryPricingInfoResponse>>>;
  productId: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  addressLine4?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  countryIsoAlpha2: Scalars['String'];
  countryIsoAlpha3?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  houseNameNumber?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressContact = {
  __typename?: 'AddressContact';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryIsoAlpha2?: Maybe<Scalars['String']>;
  countryIsoAlpha3?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  houseNameNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  countryIsoAlpha3: Scalars['String'];
  county: Scalars['String'];
  houseNameNumber: Scalars['String'];
  locality: Scalars['String'];
  postcode: Scalars['String'];
  street: Scalars['String'];
};

export type AdoptionTrackingStatusResponse = {
  __typename?: 'AdoptionTrackingStatusResponse';
  adoptionId: Scalars['ID'];
  locations: Array<TrackingStatusLocationResponse>;
  products: Array<TrackingStatusProductResponse>;
  status: Scalars['String'];
};

export type Amount = {
  __typename?: 'Amount';
  currencyCode: Scalars['String'];
  value: Scalars['Int'];
};

export type AmountInput = {
  currencyCode: Scalars['String'];
  value: Scalars['Int'];
};

export type AppAccess = {
  __typename?: 'AppAccess';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum ApplicationSubmissionType {
  DocumentBased = 'DocumentBased',
  NoBankStatement = 'NoBankStatement',
  OpenBanking = 'OpenBanking',
}

export type AuditTrailItem = {
  __typename?: 'AuditTrailItem';
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type BankAccountValidationInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  countryIsoAlpha2: Scalars['String'];
  sortCode: Scalars['String'];
};

export type BankAccountValidationResponse = {
  __typename?: 'BankAccountValidationResponse';
  accountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  countryIsoAlpha2?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ibanGenerated?: Maybe<Scalars['Boolean']>;
  isValid: Scalars['Boolean'];
  needsManualReview: Scalars['Boolean'];
  sepaInformation?: Maybe<SepaInformation>;
  sortCode?: Maybe<Scalars['String']>;
  validationMessage?: Maybe<Scalars['String']>;
  verificationResult?: Maybe<VerificationResult>;
};

export type BankAccountVerificationResponse = {
  __typename?: 'BankAccountVerificationResponse';
  accountHolderName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType: AccountType;
  branchCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  matched: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  reasonCode: ReasonCode;
  reasonDescription?: Maybe<Scalars['String']>;
};

export type BillingEntityInvoiceRequest = {
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  invoiceId: Scalars['String'];
};

export type BillingGetEntityInvoicesRequest = {
  entityId: Scalars['String'];
  entityType: Scalars['String'];
};

export type BillingInvoice = {
  __typename?: 'BillingInvoice';
  chargeDate?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  invoiceId: Scalars['String'];
  invoicePeriodEndDate?: Maybe<Scalars['String']>;
  invoicePeriodStartDate?: Maybe<Scalars['String']>;
  invoiceSource?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Float'];
};

export type BillingInvoicesResponse = {
  __typename?: 'BillingInvoicesResponse';
  invoices: Array<BillingInvoice>;
};

export type BillingLocationInvoice = {
  __typename?: 'BillingLocationInvoice';
  addressId?: Maybe<Scalars['String']>;
  chargeDate?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  due?: Maybe<Scalars['Float']>;
  fileName?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  invoicePeriodEndDate?: Maybe<Scalars['String']>;
  invoicePeriodStartDate?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  totalVat?: Maybe<Scalars['Float']>;
  tradingAddress?: Maybe<Address>;
};

export type BillingLocationInvoiceResponse = {
  __typename?: 'BillingLocationInvoiceResponse';
  billingLocationInvoices: Array<BillingLocationInvoice>;
};

export type BillingSignedZipUrlRequest = {
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  fromDate: Scalars['String'];
  identifier: Scalars['String'];
  toDate: Scalars['String'];
};

export type BrazeApiKey = {
  __typename?: 'BrazeApiKey';
  brazeApiKeyJWT: Scalars['String'];
  endpoint: Scalars['String'];
  key: Scalars['String'];
};

export enum BrazeClient {
  Android = 'Android',
  Web = 'Web',
  IOs = 'iOS',
}

export type BusinessFundingDocument = {
  __typename?: 'BusinessFundingDocument';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BusinessFundingDocumentDeletedResponse = {
  __typename?: 'BusinessFundingDocumentDeletedResponse';
  documentId: Scalars['String'];
};

export type BusinessFundingOffer = {
  __typename?: 'BusinessFundingOffer';
  currencyIsoCode?: Maybe<Scalars['String']>;
  estimatedRepaymentDate?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  offerId: Scalars['String'];
  order: Scalars['Float'];
  sweep: Scalars['Float'];
  youWillGet: Scalars['Float'];
  youWillRepay: Scalars['Float'];
};

export type BusinessFundingState = {
  __typename?: 'BusinessFundingState';
  amount?: Maybe<Scalars['Float']>;
  businessFundingUrl?: Maybe<Scalars['String']>;
  canSkipAccountVerification?: Maybe<Scalars['Boolean']>;
  currencyIsoCode?: Maybe<Scalars['String']>;
  customerId: Scalars['ID'];
  isEligibleForFlexOffers?: Maybe<Scalars['Boolean']>;
  isEligibleForInstantSigning?: Maybe<Scalars['Boolean']>;
  isPreApproved?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  maxFundingAmount?: Maybe<Scalars['Float']>;
  minFundingAmount?: Maybe<Scalars['Float']>;
  offers?: Maybe<Array<Maybe<BusinessFundingOffer>>>;
  offersExpiryDate?: Maybe<Scalars['String']>;
  selectedOfferId?: Maybe<Scalars['String']>;
  status: BusinessFundingStatus;
};

export type BusinessFundingStateChangedResponse = {
  __typename?: 'BusinessFundingStateChangedResponse';
  hasStateChanged: Scalars['Boolean'];
};

export enum BusinessFundingStatus {
  ApplicationSubmissionPending = 'ApplicationSubmissionPending',
  ApplicationSubmissionRequested = 'ApplicationSubmissionRequested',
  ContractSignatureRequested = 'ContractSignatureRequested',
  ContractSigned = 'ContractSigned',
  Eligible = 'Eligible',
  FlexOffersReceived = 'FlexOffersReceived',
  FlexOffersRequested = 'FlexOffersRequested',
  InEligible = 'InEligible',
  LeadSubmissionPending = 'LeadSubmissionPending',
  LeadSubmissionRequested = 'LeadSubmissionRequested',
  LeadSubmitted = 'LeadSubmitted',
  LoanFunded = 'LoanFunded',
  OfferAcceptanceRequested = 'OfferAcceptanceRequested',
  OfferAccepted = 'OfferAccepted',
  OffersProvided = 'OffersProvided',
  Onboarding = 'Onboarding',
  ReferredByAccountManager = 'ReferredByAccountManager',
  Rejected = 'Rejected',
}

export enum BusinessLegalType {
  LimitedCompany = 'LimitedCompany',
  Partnership = 'Partnership',
  SoleTrader = 'SoleTrader',
  Trust = 'Trust',
}

export type CalculateFlexOffersError = {
  __typename?: 'CalculateFlexOffersError';
  errorType: CalculateFlexOffersErrorKind;
};

export enum CalculateFlexOffersErrorKind {
  Api = 'Api',
  ProcessTimeout = 'ProcessTimeout',
}

export type CalculateFlexOffersRequest = {
  amount: Scalars['Float'];
  leadId: Scalars['String'];
};

export type CalculateFlexOffersResponse = BusinessFundingState | CalculateFlexOffersError;

export type CancelGroupTerminalReturnResponse = FailedCancelGroupTerminalReturn | GroupTerminalReturnCancelled;

export type CancelInviteError = {
  __typename?: 'CancelInviteError';
  errorType: CancelInviteErrorType;
};

export enum CancelInviteErrorType {
  Api = 'Api',
  InviteNotFound = 'InviteNotFound',
}

export type CancelInviteResponse = CancelInviteError | Invite;

export type CancelTerminalReturnResponse = FailedCancelTerminalReturn | TerminalReturnCancelled;

export enum CaptureMode {
  Auto = 'Auto',
  Manual = 'Manual',
}

export type CardHolder = {
  __typename?: 'CardHolder';
  billingAddress: Address;
  createdDate?: Maybe<Scalars['String']>;
  customerName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Address>;
  vatNumber?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type CardHolderPagedResult = {
  __typename?: 'CardHolderPagedResult';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<CardHolder>;
};

export type CardMachine = {
  __typename?: 'CardMachine';
  activationCode?: Maybe<Scalars['String']>;
  activationCodeState: ActivationCodeState;
  cardMachineOrderNumber: Scalars['Int'];
  collectionStatus?: Maybe<CardMachineCollectionStatus>;
  id: Scalars['String'];
  limits?: Maybe<CardMachineLimits>;
  location?: Maybe<Location>;
  locationId: Scalars['String'];
  manufacturerDescription: Scalars['String'];
  modelDescription: Scalars['String'];
  modelId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  schemes?: Maybe<Array<CardMachineScheme>>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<CardMachineStatus>;
  supervisorCode?: Maybe<Scalars['String']>;
  supervisorCodeAuditTrail?: Maybe<Array<Maybe<AuditTrailItem>>>;
  supervisorCodeIsDefault: Scalars['Boolean'];
  terminalId: Scalars['String'];
};

export type CardMachineActivationCode = {
  __typename?: 'CardMachineActivationCode';
  activationCode: Scalars['String'];
  activationCodeState: ActivationCodeState;
  id: Scalars['String'];
  terminalId: Scalars['String'];
};

export type CardMachineActivationResponse = {
  __typename?: 'CardMachineActivationResponse';
  status: CardMachineActivationState;
};

export enum CardMachineActivationState {
  Enabled = 'Enabled',
  NotEnabled = 'NotEnabled',
}

export type CardMachineAdminCodes = {
  __typename?: 'CardMachineAdminCodes';
  activationCode?: Maybe<Scalars['String']>;
  activationCodeState: ActivationCodeState;
  id: Scalars['String'];
  isDefaultSupervisorCode: Scalars['Boolean'];
  supervisorCode: Scalars['String'];
  terminalId: Scalars['String'];
};

export enum CardMachineCollectionStatus {
  Collected = 'Collected',
  CollectionRequested = 'CollectionRequested',
  CollectionStarted = 'CollectionStarted',
  None = 'None',
}

export type CardMachineEdge = {
  __typename?: 'CardMachineEdge';
  cursor: Scalars['Int'];
  node?: Maybe<CardMachine>;
};

export type CardMachineLimits = {
  __typename?: 'CardMachineLimits';
  maxCashbackAmount: Scalars['Int'];
  maxGratuityAmount: Scalars['Int'];
  maxTransactionAmount: Scalars['Int'];
};

export type CardMachineLocationSupervisorCodeUpdated = {
  __typename?: 'CardMachineLocationSupervisorCodeUpdated';
  locationId: Scalars['String'];
  statusId: Scalars['String'];
  supervisorCode: Scalars['String'];
};

export type CardMachineNameUpdated = {
  __typename?: 'CardMachineNameUpdated';
  cardMachine: CardMachine;
};

export type CardMachineProduct = {
  __typename?: 'CardMachineProduct';
  extras?: Maybe<Array<CardMachineProductFee>>;
  modelDescription: Scalars['String'];
  modelId: Scalars['String'];
  options?: Maybe<Array<CardMachineProductOption>>;
  paymentOptions?: Maybe<Array<CardMachineProductPaymentOptions>>;
  serviceFeeCurrencyCode: Scalars['String'];
  serviceFeePaymentFrequency: PaymentFrequency;
  serviceFeePrice: Scalars['String'];
  upfrontFeeCurrencyCode?: Maybe<Scalars['String']>;
  upfrontFeePrice?: Maybe<Scalars['String']>;
};

export type CardMachineProductFee = {
  __typename?: 'CardMachineProductFee';
  currencyCode: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  paymentFrequency: PaymentFrequency;
  price: Scalars['String'];
  title: Scalars['String'];
};

export type CardMachineProductOption = {
  __typename?: 'CardMachineProductOption';
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  title: Scalars['String'];
};

export enum CardMachineProductPaymentOptions {
  ServiceFee = 'ServiceFee',
  Upfront = 'Upfront',
}

export type CardMachineScheme = {
  __typename?: 'CardMachineScheme';
  allowed: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CardMachineSearchFilters = {
  __typename?: 'CardMachineSearchFilters';
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type CardMachineSearchInput = {
  collectionStatuses?: InputMaybe<Array<CardMachineCollectionStatus>>;
  locationIds: Array<Scalars['String']>;
  modelDescriptions?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<CardMachineStatus>>;
};

export type CardMachineSearchInputV2 = {
  collectionStatuses?: InputMaybe<Array<CardMachineCollectionStatus>>;
  cursor: Scalars['Int'];
  customerId: Scalars['String'];
  first: Scalars['Int'];
  locationIds: Array<Scalars['String']>;
  modelDescriptions?: InputMaybe<Array<Scalars['String']>>;
  nameOrSerialNumber?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<CardMachineStatus>>;
};

export type CardMachineSearchResult = {
  __typename?: 'CardMachineSearchResult';
  cardMachines: Array<Maybe<CardMachine>>;
};

export type CardMachineSearchResultV2 = {
  __typename?: 'CardMachineSearchResultV2';
  edges: Array<Maybe<CardMachineEdge>>;
  pageInfo: PageInfo;
};

export enum CardMachineStatus {
  Active = 'Active',
  BlockRequested = 'BlockRequested',
  Blocked = 'Blocked',
  Closed = 'Closed',
  PendingActivation = 'PendingActivation',
  UnblockRequested = 'UnblockRequested',
}

export type CardMachineSupervisorCode = {
  __typename?: 'CardMachineSupervisorCode';
  id: Scalars['String'];
  isDefaultSupervisorCode: Scalars['Boolean'];
  supervisorCode: Scalars['String'];
  terminalId: Scalars['String'];
};

export type CardMachineSupervisorCodeStatus = {
  __typename?: 'CardMachineSupervisorCodeStatus';
  status: SupervisorCodeStatus;
  statusId: Scalars['String'];
  terminal: TerminalSupervisorCodeUpdateStatus;
};

export type CardMachineSupervisorCodeStatusResponse = CardMachineSupervisorCodeStatus | RequestError;

export type CardMachineSupervisorCodeUpdated = {
  __typename?: 'CardMachineSupervisorCodeUpdated';
  /** @deprecated status is deprecated use the cardMachineSupervisorCodeUpdateStatus query */
  status: CardMachineSupervisorCodeUpdatedStatus;
  statusId: Scalars['String'];
  supervisorCode: Scalars['String'];
  terminalId: Scalars['String'];
};

export enum CardMachineSupervisorCodeUpdatedStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Unknown = 'UNKNOWN',
}

export type CardholderDetails = {
  __typename?: 'CardholderDetails';
  emailAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  carrierName: Scalars['String'];
  collectionBookingMaxDays: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  marketId: Scalars['String'];
  printerRequired: Scalars['Boolean'];
  returnCost: Scalars['Float'];
  supportedCollectionModes: Array<CollectionMode>;
};

export type ChargeableItem = {
  __typename?: 'ChargeableItem';
  description?: Maybe<Scalars['String']>;
  fixedValue?: Maybe<ChargeableItemValue>;
  id: Scalars['String'];
  percentageValue?: Maybe<ChargeableItemValue>;
};

export type ChargeableItemValue = {
  __typename?: 'ChargeableItemValue';
  defaultValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  options?: Maybe<Array<Maybe<Scalars['Float']>>>;
  override?: Maybe<Scalars['Boolean']>;
};

export type ClientRole = {
  __typename?: 'ClientRole';
  roleType: Role;
};

export type CloseTapDeviceResponse = FailedCloseTapDevice | TapDeviceClosed;

export type CollectPaymentLinkInput = {
  amount: Scalars['Int'];
  paymentIntentId: Scalars['ID'];
};

export type CollectPreAuthResponse = FailedTransactionOperation | PreAuthCollected;

export enum CollectStatus {
  Created = 'Created',
  Failed = 'Failed',
  Successful = 'Successful',
}

export enum CollectionMode {
  Collection = 'Collection',
  DropOff = 'DropOff',
}

export type ConfirmPciComplianceResponse = FailedPciUpdate | FailedUpdate | PciCompliance | TimeoutPciUpdate | TimeoutUpdate;

export type Contact = {
  __typename?: 'Contact';
  contactId: Scalars['ID'];
  countryOfResidenceIsoAlpha3?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Timestamp']>;
  dateOfBirth?: Maybe<Scalars['Timestamp']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nationalityIsoAlpha3?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
};

export type ContactLocationsQueryOptions = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type ContactPartial = {
  __typename?: 'ContactPartial';
  contactId: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
  roleType?: Maybe<Role>;
};

export type ContactPreference = {
  __typename?: 'ContactPreference';
  channel: ContactPreferenceChannel;
  enabledLocationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type: ContactPreferenceType;
  value: Scalars['Boolean'];
};

export enum ContactPreferenceChannel {
  PushNotification = 'PushNotification',
}

export type ContactPreferenceInput = {
  channel: ContactPreferenceChannel;
  enabledLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type: ContactPreferenceType;
  value: Scalars['Boolean'];
};

export enum ContactPreferenceType {
  PaymentLinks = 'PaymentLinks',
  Transfer = 'Transfer',
}

export type ContractDocumentResponse = {
  __typename?: 'ContractDocumentResponse';
  signedUrl: Scalars['String'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateAuthCodeResult = {
  __typename?: 'CreateAuthCodeResult';
  authCode: Scalars['String'];
  uuid: Scalars['ID'];
};

export type CreateCardHolder = {
  billingAddress: InputAddress;
  customerName: Scalars['String'];
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  shippingAddress: InputAddress;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type CreateInviteError = {
  __typename?: 'CreateInviteError';
  errorType: CreateInviteErrorType;
};

export enum CreateInviteErrorType {
  Api = 'Api',
  InvalidEmail = 'InvalidEmail',
  InviteExists = 'InviteExists',
  InviteeHasRole = 'InviteeHasRole',
  WrongRoleType = 'WrongRoleType',
}

export type CreateInviteInput = {
  email: Scalars['EmailAddress'];
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  role: Role;
};

export type CreateInviteResponse = CreateInviteError | Invite;

export type CreateInvoiceLineInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productId?: InputMaybe<Scalars['ID']>;
  taxRates?: InputMaybe<Array<InvoiceTaxRateInput>>;
  unitPrice: AmountInput;
  unitQuantity: Scalars['Int'];
};

export type CreateOrUpdateInvoiceInput = {
  customerId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  dueDays?: InputMaybe<Scalars['Int']>;
  terms?: InputMaybe<Scalars['String']>;
};

export type CreatePaymentConfigInput = {
  preAuth?: InputMaybe<Scalars['Boolean']>;
  showBillingAddress?: InputMaybe<Scalars['Boolean']>;
  showShippingAddress?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePaymentInput = {
  amount: Scalars['Int'];
  config?: InputMaybe<CreatePaymentConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
};

export type CreatePaymentLinkConfigInput = {
  payByBank?: InputMaybe<Scalars['Boolean']>;
  preAuth?: InputMaybe<Scalars['Boolean']>;
  showShippingAddress?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePaymentLinkInput = {
  amount: Scalars['Int'];
  config?: InputMaybe<CreatePaymentLinkConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
};

export type CreateProductInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productCategoryId?: InputMaybe<Scalars['ID']>;
  productImages?: InputMaybe<Array<InputMaybe<ProductImageInput>>>;
  unitOfMeasure?: InputMaybe<Scalars['String']>;
};

export type CreateTapDeviceOrderResponse = FailedCreateTapDeviceOrder | TapDeviceOrderCreated;

export type CreateTapDeviceSecretResponse = {
  __typename?: 'CreateTapDeviceSecretResponse';
  expirationDate: Scalars['String'];
  secret: Scalars['String'];
};

export type CreateTerminalOrderResponse = FailedCreateTerminalOrder | TerminalOrderCreated;

export type CreateTerminalReturnResponse = FailedCreateTerminalReturn | TerminalReturnCreated;

export type CreateWebhookSubscriptionInput = {
  description?: InputMaybe<Scalars['String']>;
  events: Array<Scalars['String']>;
  url: Scalars['URL'];
};

export type Cursor = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Customer = {
  __typename?: 'Customer';
  businessLegalType: Scalars['String'];
  customerId: Scalars['ID'];
  customerLevelBilling?: Maybe<Scalars['Boolean']>;
  customerReference: Scalars['String'];
  invites?: Maybe<Array<Invite>>;
  isSandbox: Scalars['Boolean'];
  locations?: Maybe<Array<Location>>;
  marketId: Scalars['String'];
  pciCompliance: PciCompliance;
  registeredInformation?: Maybe<RegisteredInformation>;
  startDate?: Maybe<Scalars['Timestamp']>;
  status: CustomerStatus;
  teamMembers?: Maybe<Array<TeamMember>>;
};

export type CustomerLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type CustomerDataInput = {
  customerId: Scalars['String'];
  productId?: InputMaybe<Scalars['ID']>;
};

export type CustomerLocationsQueryOptions = {
  limit?: InputMaybe<Scalars['Int']>;
  paymentLinksOnly?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerProduct = {
  __typename?: 'CustomerProduct';
  available: Scalars['Boolean'];
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  newBadgeEndDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type CustomerReferral = {
  __typename?: 'CustomerReferral';
  referralUrl: Scalars['String'];
  referrals: Array<Referral>;
};

export type CustomerRoleFilterInput = {
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomerRolePermission = {
  __typename?: 'CustomerRolePermission';
  category: Scalars['String'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  title: Scalars['String'];
};

export type CustomerRolePermissions = {
  __typename?: 'CustomerRolePermissions';
  permissions: Array<CustomerRolePermission>;
  role: Role;
};

export enum CustomerStatus {
  Active = 'Active',
  AwaitingClosure = 'AwaitingClosure',
  CancellationRequested = 'CancellationRequested',
  Closed = 'Closed',
}

export type DataPoint = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type DataReferenceValue = {
  __typename?: 'DataReferenceValue';
  description: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export enum DatePeriod {
  LastThreeMonths = 'LastThreeMonths',
  ThisMonth = 'ThisMonth',
  ThisWeek = 'ThisWeek',
  ThisYear = 'ThisYear',
  Today = 'Today',
}

export enum DateRange {
  Custom = 'Custom',
  Last3Month = 'Last3Month',
  LastMonth = 'LastMonth',
  LastWeek = 'LastWeek',
  LastYear = 'LastYear',
  ThisMonth = 'ThisMonth',
  ThisWeek = 'ThisWeek',
  ThisYear = 'ThisYear',
  Today = 'Today',
  Yesterday = 'Yesterday',
}

export type DropOffPoint = {
  __typename?: 'DropOffPoint';
  address: Scalars['String'];
  city: Scalars['String'];
  coordinates: Coordinates;
  name: Scalars['String'];
  postCode: Scalars['String'];
};

export type EditContactRoleLocationsInput = {
  customerId: Scalars['ID'];
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  roleType: Role;
};

export type EditCustomerPermissionsInput = {
  roles: Array<RolePermissions>;
};

export type EmailTapTransactionError = Error & {
  __typename?: 'EmailTapTransactionError';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type EmailTapTransactionResponse = EmailTapTransactionError | TapTransactionEmail;

export type EnableCardMachineActivationRequest = {
  customerId: Scalars['String'];
  locationId: Scalars['String'];
  terminalId: Scalars['String'];
};

export type EnableCardMachineActivationResponse = {
  __typename?: 'EnableCardMachineActivationResponse';
  customerId: Scalars['String'];
  locationId: Scalars['String'];
  terminalId: Scalars['String'];
};

export type Error = {
  message: Scalars['String'];
};

export type ExportCsvResponse = {
  __typename?: 'ExportCsvResponse';
  path: Scalars['String'];
};

export type ExportTransferCsvResponse = {
  __typename?: 'ExportTransferCsvResponse';
  path: Scalars['String'];
};

export type FailedCancelGroupTerminalReturn = Error & {
  __typename?: 'FailedCancelGroupTerminalReturn';
  errorType: FailedCancelGroupTerminalReturnType;
  message: Scalars['String'];
};

export enum FailedCancelGroupTerminalReturnType {
  SomethingWentWrong = 'SomethingWentWrong',
}

export type FailedCancelTerminalReturn = Error & {
  __typename?: 'FailedCancelTerminalReturn';
  errorType: FailedCancelTerminalReturnType;
  message: Scalars['String'];
};

export enum FailedCancelTerminalReturnType {
  SomethingWentWrong = 'SomethingWentWrong',
}

export type FailedCloseTapDevice = Error & {
  __typename?: 'FailedCloseTapDevice';
  message: Scalars['String'];
};

export type FailedCreateTapDeviceOrder = Error & {
  __typename?: 'FailedCreateTapDeviceOrder';
  message: Scalars['String'];
};

export type FailedCreateTerminalOrder = Error & {
  __typename?: 'FailedCreateTerminalOrder';
  errorType: FailedCreateTerminalOrderType;
  message: Scalars['String'];
};

export enum FailedCreateTerminalOrderType {
  SomethingWentWrong = 'SomethingWentWrong',
}

export type FailedCreateTerminalReturn = Error & {
  __typename?: 'FailedCreateTerminalReturn';
  errorType: FailedCreateTerminalReturnType;
  message: Scalars['String'];
};

export enum FailedCreateTerminalReturnType {
  SomethingWentWrong = 'SomethingWentWrong',
}

export type FailedGetPciSignedUrl = Error & {
  __typename?: 'FailedGetPciSignedUrl';
  errorType: FailedGetPciSignedUrlType;
  message: Scalars['String'];
};

export enum FailedGetPciSignedUrlType {
  InvalidFileLocation = 'InvalidFileLocation',
  SomethingWentWrong = 'SomethingWentWrong',
}

export type FailedLocationNicknameUpdate = Error & {
  __typename?: 'FailedLocationNicknameUpdate';
  message: Scalars['String'];
};

export type FailedLocationSearch = Error & {
  __typename?: 'FailedLocationSearch';
  message: Scalars['String'];
};

export type FailedPciUpdate = Error & {
  __typename?: 'FailedPCIUpdate';
  errorType: FailedPciUpdateType;
  message: Scalars['String'];
};

export enum FailedPciUpdateType {
  SomethingWentWrong = 'SomethingWentWrong',
  StatusAlreadyCompliant = 'StatusAlreadyCompliant',
  Timeout = 'Timeout',
}

export type FailedTransactionOperation = {
  __typename?: 'FailedTransactionOperation';
  failedOutcome: TransactionOperationOutcomeType;
};

export type FailedTransactionV2Operation = {
  __typename?: 'FailedTransactionV2Operation';
  failedOutcome: TransactionV2OperationOutcomeType;
};

export type FailedUpdate = Error & {
  __typename?: 'FailedUpdate';
  message: Scalars['String'];
};

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse';
  contentType?: Maybe<Scalars['String']>;
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  fullFileName?: Maybe<Scalars['String']>;
  publicUrl: Scalars['String'];
  storageId?: Maybe<Scalars['String']>;
};

export type GroupTerminalReturnCancelled = {
  __typename?: 'GroupTerminalReturnCancelled';
  response: Scalars['String'];
};

export type HardwareCare = {
  __typename?: 'HardwareCare';
  billingStartDate?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type HasBusinessFundingStateChangedRequest = {
  lastUpdated?: InputMaybe<Scalars['String']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  date: Scalars['String'];
  name: Scalars['String'];
};

export enum InEligibilityReason {
  InternationalAccount = 'InternationalAccount',
  UnsupportedAccount = 'UnsupportedAccount',
}

export type Industry = {
  __typename?: 'Industry';
  description?: Maybe<Scalars['String']>;
  mcc?: Maybe<Scalars['String']>;
};

export type InitiateAccountVerification = {
  __typename?: 'InitiateAccountVerification';
  authorisationUrl: Scalars['String'];
};

export type InputAddress = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  countryIsoAlpha2: Scalars['String'];
  county?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
};

export type Institution = {
  __typename?: 'Institution';
  fullName: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['String'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
};

export type InstitutionGroup = {
  __typename?: 'InstitutionGroup';
  iconUrl: Scalars['String'];
  id: Scalars['String'];
  institutions: Array<Maybe<Institution>>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
};

export type InternalUser = {
  __typename?: 'InternalUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  permissions?: Maybe<Array<Scalars['String']>>;
  role: InternalUserRole;
  userId: Scalars['ID'];
};

export enum InternalUserRole {
  BetaTestPaymentConsultant = 'BetaTestPaymentConsultant',
  CustomerServiceAdvisor = 'CustomerServiceAdvisor',
  NotSet = 'NotSet',
  PaymentConsultant = 'PaymentConsultant',
  SuperPaymentConsultant = 'SuperPaymentConsultant',
}

export type Invite = {
  __typename?: 'Invite';
  createdAt: Scalars['Timestamp'];
  createdBy?: Maybe<Contact>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String'];
  email: Scalars['String'];
  inviteId: Scalars['ID'];
  locationIds?: Maybe<Array<Scalars['String']>>;
  role: Role;
  status: InviteStatus;
};

export enum InviteStatus {
  Accepted = 'Accepted',
  Expired = 'Expired',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Revoked = 'Revoked',
}

export type Invoice = {
  __typename?: 'Invoice';
  accountId: Scalars['String'];
  cardHolder?: Maybe<CardHolder>;
  createdDate: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  dueDays?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['String']>;
  lines?: Maybe<Array<InvoiceLine>>;
  orderId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  terms?: Maybe<Scalars['String']>;
  totalDetails?: Maybe<InvoiceTotalDetails>;
};

export type InvoiceDeleted = {
  __typename?: 'InvoiceDeleted';
  invoiceId: Scalars['ID'];
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceId: Scalars['ID'];
  product?: Maybe<Product>;
  productId: Scalars['ID'];
  subtotalAmount: Amount;
  taxRates?: Maybe<Array<InvoiceTaxRate>>;
  unitPrice: Amount;
  unitQuantity: Scalars['Int'];
};

export type InvoiceLineDeleted = {
  __typename?: 'InvoiceLineDeleted';
  id: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

export type InvoicePagedResult = {
  __typename?: 'InvoicePagedResult';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Invoice>;
};

export type InvoiceSettings = {
  __typename?: 'InvoiceSettings';
  businessLegalType?: Maybe<BusinessLegalType>;
  canChangeInvoiceNumberPrefix?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['String']>;
  deliveryAddress: Address;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceNumberPrefix: Scalars['String'];
  isBillingInformationCompleted: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  registeredAddress?: Maybe<Address>;
  registeredName?: Maybe<Scalars['String']>;
  tradingAddress: Address;
  tradingName: Scalars['String'];
  vatNumber?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  Draft = 'Draft',
  Overdue = 'Overdue',
  Paid = 'Paid',
  Pending = 'Pending',
  SentAndUnpaid = 'SentAndUnpaid',
  Void = 'Void',
}

export type InvoiceTaxRate = {
  __typename?: 'InvoiceTaxRate';
  type: Scalars['String'];
  value: Scalars['Float'];
};

export type InvoiceTaxRateInput = {
  type: Scalars['String'];
  value: Scalars['Float'];
};

export type InvoiceTotalDetails = {
  __typename?: 'InvoiceTotalDetails';
  subtotalAmount: Amount;
  totalAmount: Amount;
  vatAmount: Amount;
};

export type Location = {
  __typename?: 'Location';
  agreementLength: Scalars['Int'];
  billingAddress?: Maybe<AddressContact>;
  cardMachineOrderingPermitted?: Maybe<Scalars['Boolean']>;
  cardMachines?: Maybe<Array<CardMachine>>;
  currencyCode?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['String'];
  deliveryAddress?: Maybe<AddressContact>;
  hardwareCare?: Maybe<HardwareCare>;
  industry?: Maybe<Industry>;
  invoiceSettings?: Maybe<InvoiceSettings>;
  locationId: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  numberOfCardMachines: Scalars['Int'];
  preauthAllowed?: Maybe<Scalars['Boolean']>;
  preauthEligible?: Maybe<Scalars['Boolean']>;
  remotePaymentAppConfig?: Maybe<RemotePaymentAppConfig>;
  remotePayments?: Maybe<Array<RemotePayment>>;
  reserveAmount: Scalars['String'];
  settlementProfileId?: Maybe<Scalars['String']>;
  status: LocationStatus;
  tradingAddress?: Maybe<AddressContact>;
  tradingName: Scalars['String'];
  transferReference?: Maybe<Scalars['String']>;
};

export type LocationCustomerArgs = {
  params?: InputMaybe<CustomerRoleFilterInput>;
};

export type LocationCardMachineProducts = {
  __typename?: 'LocationCardMachineProducts';
  allocatedFreeCardMachines?: Maybe<Scalars['String']>;
  cardMachineProducts: Array<CardMachineProduct>;
  customerId: Scalars['String'];
  freeCardMachinesAllocationLimit?: Maybe<Scalars['String']>;
  locationId: Scalars['String'];
};

export type LocationNicknameUpdated = {
  __typename?: 'LocationNicknameUpdated';
  location: Location;
};

export type LocationSearchRecord = {
  __typename?: 'LocationSearchRecord';
  customerId: Scalars['String'];
  locationId: Scalars['String'];
  marketId: Scalars['String'];
  status: Scalars['String'];
  tradingAddress: Address;
  tradingName: Scalars['String'];
};

export type LocationSearchResponse = FailedLocationSearch | LocationSearchResult;

export type LocationSearchResult = {
  __typename?: 'LocationSearchResult';
  locations: Array<Maybe<LocationSearchRecord>>;
  total: Scalars['Int'];
};

export enum LocationStatus {
  Active = 'Active',
  AwaitingClosure = 'AwaitingClosure',
  CancellationRequested = 'CancellationRequested',
  Closed = 'Closed',
}

export type LocationSupervisorCodeStatus = {
  __typename?: 'LocationSupervisorCodeStatus';
  status: SupervisorCodeStatus;
  statusId: Scalars['String'];
  terminals: Array<TerminalSupervisorCodeUpdateStatus>;
};

export type LocationSupervisorCodeStatusResponse = LocationSupervisorCodeStatus | RequestError;

export type MarketplaceProduct = {
  __typename?: 'MarketplaceProduct';
  addOns?: Maybe<Array<MarketplaceProduct>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  locations?: Maybe<Array<Scalars['String']>>;
  marketId: Scalars['String'];
  name: Scalars['String'];
  pricing?: Maybe<Pricing>;
};

export type MarketplaceProductV2 = {
  __typename?: 'MarketplaceProductV2';
  description: Scalars['String'];
  id: Scalars['ID'];
  locations?: Maybe<Array<Scalars['String']>>;
  marketId: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  pricing?: Maybe<Pricing>;
};

export enum ModuleType {
  Faqs = 'faqs',
  Pricing = 'pricing',
  Testimonials = 'testimonials',
  Usps = 'usps',
}

export type Money = {
  __typename?: 'Money';
  currencyCode: Scalars['String'];
  value: Scalars['Int'];
};

export type MonthlyAggregate = {
  __typename?: 'MonthlyAggregate';
  currencyIsoCode?: Maybe<Scalars['String']>;
  month: Scalars['Int'];
  totalAmount: Scalars['Float'];
  transfers: Array<Transfer>;
  year: Scalars['Int'];
};

export type MutateInitiateAccountVerification = {
  __typename?: 'MutateInitiateAccountVerification';
  callbackUrl: Scalars['String'];
  customerId: Scalars['ID'];
  institutionId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: AcceptInviteResponse;
  acceptOffer?: Maybe<Scalars['Void']>;
  authCallbackAccountingConnection?: Maybe<Scalars['Void']>;
  calculateFlexOffers?: Maybe<CalculateFlexOffersResponse>;
  cancelAccountVerification?: Maybe<Scalars['Void']>;
  cancelAccountVerificationAndLead?: Maybe<Scalars['Void']>;
  cancelBusinessFundingLead?: Maybe<Scalars['Void']>;
  cancelGroupTerminalReturn: CancelGroupTerminalReturnResponse;
  cancelInvite: CancelInviteResponse;
  cancelPaymentLink: PaymentLinkSummary;
  cancelTerminalReturn: CancelTerminalReturnResponse;
  cancelVirtualTerminalPayment: PaymentSummary;
  closeTapDevice: CloseTapDeviceResponse;
  collectPreAuth: CollectPreAuthResponse;
  collectPreAuthPaymentLink?: Maybe<PaymentLinksCollectResult>;
  confirmPciCompliance: ConfirmPciComplianceResponse;
  createAccountVerificationIntent: InitiateAccountVerification;
  createAccountingConnectionLink: AccountingConnectionLink;
  createAuthCode: CreateAuthCodeResult;
  createCardHolder: CardHolder;
  createInvite: CreateInviteResponse;
  createInvoice: Invoice;
  createInvoiceLine: InvoiceLine;
  createPaymentLink: PaymentLinkSummary;
  createProduct: Product;
  createReceiptToken: ReceiptTokenResponse;
  createTapDeviceOrder: CreateTapDeviceOrderResponse;
  createTapDeviceSecret: CreateTapDeviceSecretResponse;
  createTapplePayment: PaymentSummary;
  createTerminalOrder: CreateTerminalOrderResponse;
  createTerminalReturn: CreateTerminalReturnResponse;
  createVirtualTerminalPayment: PaymentSummary;
  deleteBusinessFundingDocument: BusinessFundingDocumentDeletedResponse;
  deleteInvoice: InvoiceDeleted;
  deleteInvoiceLine: InvoiceLineDeleted;
  deleteWebhookSubscription?: Maybe<Scalars['Void']>;
  editCustomerPermissions?: Maybe<Scalars['Void']>;
  emailTapTransaction: EmailTapTransactionResponse;
  enableCardMachineActivation: CardMachineActivationResponse;
  exportCsv: ExportCsvResponse;
  exportCsvSigned: ExportCsvResponse;
  exportTransferCsv: ExportTransferCsvResponse;
  exportTransferCsvSigned: ExportTransferCsvResponse;
  f2fRefundTransaction: TransactionF2fRefundedResponse;
  f2fRefundTransactionV2: TransactionV2F2fRefundedResponse;
  initiateAccountVerification: InitiateAccountVerification;
  onlineCheckoutActivation: OnlineCheckoutActivationStatusType;
  orderProduct: OrderStatus;
  patchAccountingConnection: AccountingConnection;
  proposeProduct: OrderStatus;
  refundTransaction: TransactionRefundedResponse;
  rejectInvite: Invite;
  releasePreAuth: ReleasePreAuthResponse;
  removeAccountingConnectionLink: AccountingConnectionLink;
  removeContact: RemoveContactResponse;
  renewInvite: Invite;
  resubmitOnlineCheckoutActivation: OnlineCheckoutActivationStatusType;
  resyncAccountingConnection: AccountingConnection;
  retrieveAccountTransactions: AccountVerification;
  reversalPaymentLink?: Maybe<PaymentLinksReversalResult>;
  reverseTransaction: TransactionReversedResponse;
  reviewOnlineCheckoutActivation: ReviewOnlineCheckoutActivationResult;
  rpDeleteApiKey?: Maybe<Scalars['Void']>;
  rpGenerateApiKey: RpApiKey;
  rpUpdateApiKey?: Maybe<Scalars['Void']>;
  sendReceipt?: Maybe<Scalars['Void']>;
  setContactPreferences: Array<ContactPreference>;
  setUserPushPreference?: Maybe<Scalars['Void']>;
  signContract?: Maybe<Scalars['Void']>;
  submitBusinessFundingApplication: SubmitBusinessFundingResponse;
  submitBusinessFundingApplicationV2: SubmitBusinessFundingResponse;
  submitBusinessFundingApplicationV3: SubmitBusinessFundingResponse;
  submitBusinessFundingLead: SubmitBusinessFundingResponse;
  submitBusinessFundingLeadV2: SubmitBusinessFundingResponse;
  submitBusinessFundingReferral: SubmitBusinessFundingReferralResponse;
  submitPayByQrIntakeForm: Scalars['String'];
  submitQueueBookingsForm?: Maybe<Scalars['Void']>;
  subscribeToWebhookEvents: WebhookSubscription;
  terminalCreateReceiptToken: ReceiptTokenResponse;
  terminalSendReceipt?: Maybe<Scalars['Void']>;
  transferReferenceUpdate?: Maybe<Location>;
  updateAccountingConnection: AccountingConnection;
  updateCardHolder: CardHolder;
  updateCardMachineLocationSupervisorCode: UpdateCardMachineLocationSupervisorCodeResponse;
  updateCardMachineName: UpdateCardMachineNameResponse;
  updateCardMachineSupervisorCode: UpdateCardMachineSupervisorCodeResponse;
  updateContactRoleLocations?: Maybe<Scalars['Void']>;
  updateInvoice: Invoice;
  updateInvoiceLine: InvoiceLine;
  updateInvoiceSettings: InvoiceSettings;
  updateLocationNickname: UpdateLocationNicknameResponse;
  updatePreauthAllowed?: Maybe<Scalars['String']>;
  updateProduct: Product;
  updateVatNumber: UpdateVatNumberResponse;
  updateViewedState?: Maybe<ViewedState>;
  updateWebhookSubscription: WebhookSubscription;
  uploadBusinessFundingDocument: AccountVerificationResponse;
  uploadFile?: Maybe<FileUploadResponse>;
  upsertReceiptConfiguration: ReceiptConfigurationResponse;
  upsertRemotePaymentAppConfigBranding?: Maybe<Scalars['Void']>;
};

export type MutationAcceptInviteArgs = {
  inviteId: Scalars['ID'];
};

export type MutationAcceptOfferArgs = {
  acceptOfferRequest: AcceptOfferRequest;
  customerId: Scalars['ID'];
};

export type MutationAuthCallbackAccountingConnectionArgs = {
  customerId: Scalars['ID'];
  params: AccountingConnectionCallbackInput;
  platform: Scalars['String'];
};

export type MutationCalculateFlexOffersArgs = {
  calculateFlexOffersRequest: CalculateFlexOffersRequest;
  customerId: Scalars['ID'];
};

export type MutationCancelAccountVerificationArgs = {
  customerId: Scalars['ID'];
};

export type MutationCancelAccountVerificationAndLeadArgs = {
  customerId: Scalars['ID'];
  leadId?: InputMaybe<Scalars['String']>;
};

export type MutationCancelBusinessFundingLeadArgs = {
  customerId: Scalars['ID'];
  leadId: Scalars['String'];
};

export type MutationCancelGroupTerminalReturnArgs = {
  groupCorrelationId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type MutationCancelInviteArgs = {
  inviteId: Scalars['ID'];
};

export type MutationCancelPaymentLinkArgs = {
  locationId: Scalars['ID'];
  paymentIntentId: Scalars['ID'];
};

export type MutationCancelTerminalReturnArgs = {
  correlationId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type MutationCancelVirtualTerminalPaymentArgs = {
  locationId: Scalars['ID'];
  paymentIntentId: Scalars['ID'];
};

export type MutationCloseTapDeviceArgs = {
  customerId: Scalars['ID'];
  locationId: Scalars['ID'];
  terminalId: Scalars['ID'];
};

export type MutationCollectPreAuthArgs = {
  amount: Scalars['Int'];
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationCollectPreAuthPaymentLinkArgs = {
  locationId: Scalars['ID'];
  params: CollectPaymentLinkInput;
};

export type MutationConfirmPciComplianceArgs = {
  customerId: Scalars['ID'];
};

export type MutationCreateAccountVerificationIntentArgs = {
  customerId: Scalars['ID'];
  institutionId: Scalars['String'];
};

export type MutationCreateAccountingConnectionLinkArgs = {
  customerId: Scalars['ID'];
  platform: Scalars['String'];
};

export type MutationCreateAuthCodeArgs = {
  contactId: Scalars['String'];
  customerId: Scalars['String'];
  locationId: Scalars['String'];
};

export type MutationCreateCardHolderArgs = {
  payload: CreateCardHolder;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationCreateInviteArgs = {
  customerId: Scalars['ID'];
  payload: CreateInviteInput;
};

export type MutationCreateInvoiceArgs = {
  payload: CreateOrUpdateInvoiceInput;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationCreateInvoiceLineArgs = {
  invoiceId: Scalars['ID'];
  payload: CreateInvoiceLineInput;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationCreatePaymentLinkArgs = {
  locationId: Scalars['ID'];
  params: CreatePaymentLinkInput;
};

export type MutationCreateProductArgs = {
  payload: CreateProductInput;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationCreateReceiptTokenArgs = {
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationCreateTapDeviceOrderArgs = {
  deviceModel: Scalars['String'];
  fingerprint: Scalars['String'];
  locationId: Scalars['ID'];
  modelType: Scalars['String'];
  nickname: Scalars['String'];
  orderId: Scalars['ID'];
};

export type MutationCreateTapDeviceSecretArgs = {
  locationId: Scalars['ID'];
};

export type MutationCreateTapplePaymentArgs = {
  locationId: Scalars['ID'];
  params: CreatePaymentInput;
};

export type MutationCreateTerminalOrderArgs = {
  cardMachineProducts: Array<OrderedCardMachine>;
  deliveryAddressId: Scalars['ID'];
  deliveryContactId: Scalars['ID'];
  locationId: Scalars['ID'];
  orderReason?: InputMaybe<OrderReason>;
};

export type MutationCreateTerminalReturnArgs = {
  carrier: Scalars['ID'];
  collectionAddressId: Scalars['ID'];
  collectionContactId: Scalars['ID'];
  collectionDate: Scalars['String'];
  collectionMode?: InputMaybe<CollectionMode>;
  locationId: Scalars['ID'];
  reason: ReturnReason;
  recordAcceptance: Scalars['Boolean'];
  terminalIds: Array<Scalars['String']>;
};

export type MutationCreateVirtualTerminalPaymentArgs = {
  locationId: Scalars['ID'];
  params: CreatePaymentInput;
};

export type MutationDeleteBusinessFundingDocumentArgs = {
  customerId: Scalars['ID'];
  documentId: Scalars['String'];
};

export type MutationDeleteInvoiceArgs = {
  invoiceId: Scalars['ID'];
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationDeleteInvoiceLineArgs = {
  invoiceId: Scalars['ID'];
  invoiceLineId: Scalars['ID'];
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationDeleteWebhookSubscriptionArgs = {
  accountId: Scalars['ID'];
  sandbox?: Scalars['Boolean'];
  subscriptionId: Scalars['ID'];
};

export type MutationEditCustomerPermissionsArgs = {
  customerId: Scalars['ID'];
  payload: EditCustomerPermissionsInput;
};

export type MutationEmailTapTransactionArgs = {
  email: Scalars['String'];
  fingerprint: Scalars['String'];
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationEnableCardMachineActivationArgs = {
  customerId: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationExportCsvArgs = {
  search: TransactionSearchInput;
};

export type MutationExportCsvSignedArgs = {
  search: TransactionSearchInput;
};

export type MutationExportTransferCsvArgs = {
  search: TransferSearchInput;
};

export type MutationExportTransferCsvSignedArgs = {
  search: TransferSearchInput;
};

export type MutationF2fRefundTransactionArgs = {
  amount: Scalars['Int'];
  locationId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  refundReason: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type MutationF2fRefundTransactionV2Args = {
  amount: Scalars['Int'];
  locationId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  refundReason: Scalars['String'];
  timeZone: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type MutationInitiateAccountVerificationArgs = {
  callbackUrl: Scalars['String'];
  customerId: Scalars['ID'];
  institutionId: Scalars['String'];
};

export type MutationOnlineCheckoutActivationArgs = {
  accountId: Scalars['ID'];
  args: OnlineCheckoutActivationInput;
};

export type MutationOrderProductArgs = {
  productOrderInput: ProductOrderInput;
};

export type MutationPatchAccountingConnectionArgs = {
  customerId: Scalars['ID'];
  params: AccountingConnectionPatchInput;
  platform: Scalars['String'];
};

export type MutationProposeProductArgs = {
  productOrderInput: ProductOrderInput;
};

export type MutationRefundTransactionArgs = {
  amount: Scalars['Int'];
  locationId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  refundReason: Scalars['String'];
  transactionId: Scalars['ID'];
};

export type MutationRejectInviteArgs = {
  inviteId: Scalars['ID'];
};

export type MutationReleasePreAuthArgs = {
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationRemoveAccountingConnectionLinkArgs = {
  customerId: Scalars['ID'];
  platform: Scalars['String'];
};

export type MutationRemoveContactArgs = {
  contactId: Scalars['ID'];
  customerId: Scalars['ID'];
};

export type MutationRenewInviteArgs = {
  inviteId: Scalars['ID'];
};

export type MutationResubmitOnlineCheckoutActivationArgs = {
  accountId: Scalars['ID'];
  args: ResubmitOnlineCheckoutActivationInput;
};

export type MutationResyncAccountingConnectionArgs = {
  customerId: Scalars['ID'];
  platform: Scalars['String'];
};

export type MutationRetrieveAccountTransactionsArgs = {
  accountId: Scalars['String'];
  customerId: Scalars['ID'];
};

export type MutationReversalPaymentLinkArgs = {
  locationId: Scalars['ID'];
  params: ReversalPaymentLinkInput;
};

export type MutationReverseTransactionArgs = {
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationReviewOnlineCheckoutActivationArgs = {
  accountId: Scalars['ID'];
  args: ReviewOnlineCheckoutActivationInput;
};

export type MutationRpDeleteApiKeyArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationRpGenerateApiKeyArgs = {
  accountId: Scalars['ID'];
  params: RpApiKeyAddInput;
};

export type MutationRpUpdateApiKeyArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
  params: RpApiKeyUpdateInput;
};

export type MutationSendReceiptArgs = {
  emailAddress: Scalars['String'];
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationSetContactPreferencesArgs = {
  customerId: Scalars['ID'];
  preferences?: InputMaybe<Array<InputMaybe<ContactPreferenceInput>>>;
  transferPreference: Scalars['Boolean'];
};

export type MutationSetUserPushPreferenceArgs = {
  marketId: Scalars['String'];
  preference: Scalars['String'];
  value: Scalars['Boolean'];
};

export type MutationSignContractArgs = {
  customerId: Scalars['ID'];
  leadId: Scalars['String'];
};

export type MutationSubmitBusinessFundingApplicationArgs = {
  customerId: Scalars['ID'];
  submitApplicationRequest: SubmitApplicationRequest;
};

export type MutationSubmitBusinessFundingApplicationV2Args = {
  customerId: Scalars['ID'];
  submitApplicationRequest: SubmitApplicationV2Request;
};

export type MutationSubmitBusinessFundingApplicationV3Args = {
  customerId: Scalars['ID'];
  submitApplicationRequest: SubmitApplicationV2Request;
};

export type MutationSubmitBusinessFundingLeadArgs = {
  customerId: Scalars['ID'];
  submitLeadRequest?: InputMaybe<SubmitLeadRequest>;
};

export type MutationSubmitBusinessFundingLeadV2Args = {
  customerId: Scalars['ID'];
  submitLeadRequest?: InputMaybe<SubmitLeadRequest>;
};

export type MutationSubmitBusinessFundingReferralArgs = {
  customerId: Scalars['ID'];
};

export type MutationSubmitPayByQrIntakeFormArgs = {
  payByQrIntakeForm: PayByQrIntakeForm;
};

export type MutationSubmitQueueBookingsFormArgs = {
  marketId: Scalars['ID'];
  queueBookingForm: QueueBookingForm;
};

export type MutationSubscribeToWebhookEventsArgs = {
  accountId: Scalars['ID'];
  params: CreateWebhookSubscriptionInput;
  sandbox?: Scalars['Boolean'];
};

export type MutationTerminalCreateReceiptTokenArgs = {
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationTerminalSendReceiptArgs = {
  emailAddress: Scalars['String'];
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type MutationTransferReferenceUpdateArgs = {
  customerId: Scalars['ID'];
  locationId: Scalars['ID'];
  reference: Scalars['String'];
};

export type MutationUpdateAccountingConnectionArgs = {
  customerId: Scalars['ID'];
  params: AccountingConnectionUpdateInput;
  platform: Scalars['String'];
};

export type MutationUpdateCardHolderArgs = {
  payload: UpdateCardHolder;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationUpdateCardMachineLocationSupervisorCodeArgs = {
  locationId: Scalars['ID'];
  supervisorCode: Scalars['String'];
};

export type MutationUpdateCardMachineNameArgs = {
  locationId: Scalars['ID'];
  name: Scalars['String'];
  terminalId: Scalars['ID'];
};

export type MutationUpdateCardMachineSupervisorCodeArgs = {
  locationId: Scalars['ID'];
  supervisorCode: Scalars['String'];
  terminalId: Scalars['ID'];
};

export type MutationUpdateContactRoleLocationsArgs = {
  contactId: Scalars['ID'];
  payload: EditContactRoleLocationsInput;
};

export type MutationUpdateInvoiceArgs = {
  invoiceId: Scalars['ID'];
  payload: CreateOrUpdateInvoiceInput;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationUpdateInvoiceLineArgs = {
  invoiceId: Scalars['ID'];
  invoiceLineId: Scalars['ID'];
  payload: UpdateInvoiceLineInput;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationUpdateInvoiceSettingsArgs = {
  invoiceSettingsId: Scalars['ID'];
  payload: UpdateInvoiceSettingsInput;
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationUpdateLocationNicknameArgs = {
  locationId: Scalars['ID'];
  nickname: Scalars['String'];
};

export type MutationUpdatePreauthAllowedArgs = {
  locationId: Scalars['ID'];
  preauthAllowed: Scalars['Boolean'];
};

export type MutationUpdateProductArgs = {
  payload: UpdateProductInput;
  productId: Scalars['ID'];
  remotePaymentAccountId: Scalars['ID'];
};

export type MutationUpdateVatNumberArgs = {
  merchantId: Scalars['ID'];
  remotePaymentAccountId: Scalars['ID'];
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateViewedStateArgs = {
  state: ViewedState;
};

export type MutationUpdateWebhookSubscriptionArgs = {
  accountId: Scalars['ID'];
  params: UpdateWebhookSubscriptionInput;
  sandbox?: Scalars['Boolean'];
  subscriptionId: Scalars['ID'];
};

export type MutationUploadBusinessFundingDocumentArgs = {
  customerId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  locationId: Scalars['ID'];
  params: UploadFileInput;
};

export type MutationUpsertReceiptConfigurationArgs = {
  locationId: Scalars['ID'];
  payload: ReceiptConfigurationInput;
};

export type MutationUpsertRemotePaymentAppConfigBrandingArgs = {
  locationId: Scalars['ID'];
  payload: RemotePaymentAppConfigBrandingInput;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  content?: Maybe<Array<Maybe<OnboardingStep>>>;
  show?: Maybe<Scalars['Boolean']>;
};

export type OnboardingStep = {
  __typename?: 'OnboardingStep';
  buttonTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OnlineCheckoutActivationInput = {
  customerWebsiteComplianceAcknowledgement: Scalars['Boolean'];
  deliveryDays: Scalars['Int'];
  monthlyCardTurnoverPercentage: Scalars['Int'];
  url: Scalars['URL'];
};

export type OnlineCheckoutActivationRule = {
  __typename?: 'OnlineCheckoutActivationRule';
  declinedMessage?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ruleStatus: Scalars['String'];
};

export type OnlineCheckoutActivationStatusType = {
  __typename?: 'OnlineCheckoutActivationStatusType';
  approvalId?: Maybe<Scalars['String']>;
  customerWebsiteComplianceAcknowledgement?: Maybe<Scalars['Boolean']>;
  deliveryDays: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locationType?: Maybe<Scalars['String']>;
  monthlyCardTurnoverPercentage: Scalars['Int'];
  rules?: Maybe<Array<Maybe<OnlineCheckoutActivationRule>>>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['URL']>;
};

export type OperationOutcome = {
  outcome: TransactionOperationOutcomeType;
};

export type OperationOutcomeV2 = {
  outcome: TransactionV2OperationOutcomeType;
};

export type OrderId = {
  __typename?: 'OrderID';
  id: Scalars['String'];
};

export enum OrderReason {
  Convenience = 'Convenience',
  IncreaseInTrading = 'IncreaseInTrading',
  SwapOut = 'SwapOut',
}

export type OrderStatus = {
  __typename?: 'OrderStatus';
  ordered?: Maybe<Scalars['Boolean']>;
};

export type OrderedCardMachine = {
  modelId: Scalars['ID'];
  quantity: Scalars['String'];
};

export type PciCompliance = {
  __typename?: 'PCICompliance';
  /**
   * When lastCompletionDate is null, this will be null.
   * If now is greater than dueDate, it will return a negative number.
   */
  daysToExpiration?: Maybe<Scalars['Int']>;
  documents: Array<PciDocument>;
  dueDate: Scalars['Timestamp'];
  lastCompletionDate?: Maybe<Scalars['Timestamp']>;
  pciAgree?: Maybe<Scalars['Boolean']>;
  pciComplete?: Maybe<Scalars['Boolean']>;
  status: PciStatus;
};

export type PciDocument = {
  __typename?: 'PCIDocument';
  type: PciDocumentType;
  version: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  startCursor: Scalars['Int'];
};

export type PartnerAccessContext = {
  __typename?: 'PartnerAccessContext';
  apps?: Maybe<Array<AppAccess>>;
  id: Scalars['String'];
};

export type PayByQrIntakeForm = {
  ContactEmail: Scalars['String'];
  CustomerId: Scalars['String'];
  EposProvider: Scalars['String'];
  Features: Array<Scalars['String']>;
  LeadSource: Scalars['String'];
  PatEnabled: Scalars['String'];
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  amount: Scalars['Int'];
  authCode?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  flags: PaymentDetailsFlags;
  last4Pan?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  paymentIntentId: Scalars['ID'];
  paymentMethods?: Maybe<Array<PaymentMethods>>;
  reference?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  tradingName?: Maybe<Scalars['String']>;
  transactionDateTime?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PaymentDetailsFlags = {
  __typename?: 'PaymentDetailsFlags';
  showCollect: Scalars['Boolean'];
  showDigitalReceipt: Scalars['Boolean'];
  showRefund: Scalars['Boolean'];
  showReversal: Scalars['Boolean'];
  showVoid: Scalars['Boolean'];
};

export type PaymentEvents = {
  __typename?: 'PaymentEvents';
  authCode?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  cardholderName?: Maybe<Scalars['String']>;
  eventType?: Maybe<PaymentIntentEventType>;
  expiryDate?: Maybe<Scalars['String']>;
  transactionDateTime?: Maybe<Scalars['String']>;
};

export enum PaymentFrequency {
  Monthly = 'Monthly',
  NotApplicable = 'NotApplicable',
  NotSet = 'NotSet',
  Once = 'Once',
  Weekly = 'Weekly',
  Yearly = 'Yearly',
}

export enum PaymentIntentEventType {
  AuthChallenged = 'AuthChallenged',
  Authorized = 'Authorized',
  Canceled = 'Canceled',
  Captured = 'Captured',
  Created = 'Created',
  Declined = 'Declined',
  Error = 'Error',
  ManualAuthReady = 'ManualAuthReady',
  Refunded = 'Refunded',
  Reversed = 'Reversed',
  Unknown = 'Unknown',
}

export type PaymentIntentPaged = {
  __typename?: 'PaymentIntentPaged';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data?: Maybe<Array<PaymentIntentResponse>>;
};

export type PaymentIntentResponse = {
  __typename?: 'PaymentIntentResponse';
  amount?: Maybe<Money>;
  captureMode?: Maybe<CaptureMode>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  paymentDetails?: Maybe<PaymentDetails>;
  paymentEvents?: Maybe<Array<Maybe<PaymentEvents>>>;
  paymentSource?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  requestedAmount?: Maybe<Money>;
  status?: Maybe<PaymentIntentStatus>;
  tipsAmount?: Maybe<Money>;
  totalAmount?: Maybe<Money>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum PaymentIntentStatus {
  Authorized = 'Authorized',
  Canceled = 'Canceled',
  Captured = 'Captured',
  Created = 'Created',
  Refunded = 'Refunded',
  Reversed = 'Reversed',
}

export type PaymentIntentsInput = {
  cursor?: InputMaybe<Cursor>;
  endDate?: InputMaybe<Scalars['String']>;
  paymentSource?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<PaymentIntentStatus>>>;
};

export enum PaymentLinkStatus {
  Paid = 'Paid',
  Uncollected = 'Uncollected',
  Unpaid = 'Unpaid',
  Void = 'Void',
}

export type PaymentLinkSummary = {
  __typename?: 'PaymentLinkSummary';
  amount: Scalars['Int'];
  createdAt: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  paymentIntentId: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status: PaymentLinkStatus;
  transactionId?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PaymentLinksCollectResult = {
  __typename?: 'PaymentLinksCollectResult';
  status: CollectStatus;
};

export type PaymentLinksReversalResult = {
  __typename?: 'PaymentLinksReversalResult';
  status: ReleaseStatus;
};

export type PaymentLinksSearchCursors = {
  __typename?: 'PaymentLinksSearchCursors';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type PaymentLinksSearchInput = {
  dateRange?: InputMaybe<DateRange>;
  fromDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<PaymentLinkStatus>>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type PaymentLinksSearchResult = {
  __typename?: 'PaymentLinksSearchResult';
  cursors: PaymentLinksSearchCursors;
  paymentLinks: Array<PaymentLinkSummary>;
};

export enum PaymentMethods {
  Card = 'Card',
  PayByBank = 'PayByBank',
  SavedCard = 'SavedCard',
  Wallet = 'Wallet',
}

export enum PaymentStatus {
  Paid = 'Paid',
  Uncollected = 'Uncollected',
  Unpaid = 'Unpaid',
  Void = 'Void',
}

export type PaymentSummary = {
  __typename?: 'PaymentSummary';
  amount: Scalars['Int'];
  createdAt: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  paymentIntentId: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  transactionId?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum PaymentType {
  PreAuth = 'PreAuth',
  Sale = 'Sale',
}

export type PaymentsSearchCursors = {
  __typename?: 'PaymentsSearchCursors';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type PaymentsSearchInput = {
  dateRange?: InputMaybe<DateRange>;
  fromDate?: InputMaybe<Scalars['String']>;
  paymentSources?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<PaymentStatus>>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type PaymentsSearchResult = {
  __typename?: 'PaymentsSearchResult';
  amount: Scalars['Int'];
  createdAt: Scalars['String'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  paymentIntentId: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  transactionId?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PaymentsSearchResultPaged = {
  __typename?: 'PaymentsSearchResultPaged';
  cursors: PaymentsSearchCursors;
  payments: Array<PaymentsSearchResult>;
};

export enum PciDocumentType {
  DataSecurityPolicy = 'DataSecurityPolicy',
  P2PeInstructionManual = 'P2PEInstructionManual',
}

export type PciSignedUrl = {
  __typename?: 'PciSignedUrl';
  signedUrl: Scalars['String'];
};

export type PciSignedUrlResponse = FailedGetPciSignedUrl | PciSignedUrl;

export enum PciStatus {
  Compliant = 'Compliant',
  NotCompliant = 'NotCompliant',
}

export type PendingTransfersList = {
  __typename?: 'PendingTransfersList';
  currencyIsoCode?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  transfers: Array<Transfer>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  countryIsoAlpha3: Scalars['String'];
  internationalCallingCode: Scalars['String'];
  telephoneNumber: Scalars['String'];
};

export type PollTransactionSearchInput = {
  filters: TransactionSearchInput;
  searchDateTime?: InputMaybe<Scalars['String']>;
};

export type PollTransactionSearchResult = {
  __typename?: 'PollTransactionSearchResult';
  newTransactions: Scalars['Boolean'];
};

export type PollTransactionV2Result = {
  __typename?: 'PollTransactionV2Result';
  cursor: Scalars['String'];
  newTransactions: Scalars['Boolean'];
};

export type PreAuthCollected = OperationOutcome & {
  __typename?: 'PreAuthCollected';
  outcome: TransactionOperationOutcomeType;
};

export type PreAuthReleased = OperationOutcome & {
  __typename?: 'PreAuthReleased';
  outcome: TransactionOperationOutcomeType;
};

export type Pricing = {
  __typename?: 'Pricing';
  serviceFees?: Maybe<Array<ServiceFee>>;
  transactionFees?: Maybe<Array<ChargeableItem>>;
  transactionRates?: Maybe<Array<ChargeableItem>>;
  transactionSurcharges?: Maybe<Array<ChargeableItem>>;
};

export type Product = {
  __typename?: 'Product';
  createdDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productCategoryId?: Maybe<Scalars['String']>;
  productImages?: Maybe<Array<Maybe<ProductImage>>>;
  unitOfMeasure?: Maybe<Scalars['String']>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  id: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  thumbnail?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductImageInput = {
  id?: InputMaybe<Scalars['ID']>;
  productId?: InputMaybe<Scalars['ID']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ProductOrderInput = {
  customerId: Scalars['String'];
  dataPoints?: InputMaybe<Array<DataPoint>>;
  locations?: InputMaybe<Array<ProductOrderLocation>>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProductOrderLocation = {
  dataPoints?: InputMaybe<Array<DataPoint>>;
  locationId: Scalars['String'];
  productIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProductPagedResult = {
  __typename?: 'ProductPagedResult';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Product>;
};

export type ProductQuestionConditionResponse = {
  __typename?: 'ProductQuestionConditionResponse';
  conditionType: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type ProductQuestionConfigResponse = {
  __typename?: 'ProductQuestionConfigResponse';
  configId: Scalars['String'];
  productId: Scalars['ID'];
  steps: Array<ProductQuestionStepResponse>;
};

export type ProductQuestionConfigSummaryConfirmationContentActionResponse = {
  __typename?: 'ProductQuestionConfigSummaryConfirmationContentActionResponse';
  analyticsAction?: Maybe<Scalars['String']>;
  analyticsLabel?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkTo?: Maybe<Scalars['String']>;
  linkType?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ProductQuestionConfigSummaryConfirmationContentResponse = {
  __typename?: 'ProductQuestionConfigSummaryConfirmationContentResponse';
  actions?: Maybe<Array<Maybe<ProductQuestionConfigSummaryConfirmationContentActionResponse>>>;
  descriptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ProductQuestionConfigSummaryDetailsResponse = {
  __typename?: 'ProductQuestionConfigSummaryDetailsResponse';
  addOns?: Maybe<Array<Maybe<AddOnSummaryResponse>>>;
  additionalPricingInfo?: Maybe<Array<Maybe<ProductQuestionConfigSummaryPricingInfoResponse>>>;
  analyticsCategory?: Maybe<Scalars['String']>;
  confirmationContent?: Maybe<ProductQuestionConfigSummaryConfirmationContentResponse>;
  confirmationRequired?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  pricingInfo?: Maybe<ProductQuestionConfigSummaryPricingInfoResponse>;
  showCurrentDirectDebitDisclaimer: Scalars['Boolean'];
};

export type ProductQuestionConfigSummaryPricingInfoResponse = {
  __typename?: 'ProductQuestionConfigSummaryPricingInfoResponse';
  description?: Maybe<Scalars['String']>;
  displayPrice?: Maybe<Scalars['String']>;
  feeId: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ProductQuestionConfigSummaryResponse = {
  __typename?: 'ProductQuestionConfigSummaryResponse';
  configId: Scalars['String'];
  eSignatureRequired: Scalars['Boolean'];
  feeDisclaimerRequired: Scalars['Boolean'];
  productId: Scalars['ID'];
  summaryDetails?: Maybe<ProductQuestionConfigSummaryDetailsResponse>;
};

export type ProductQuestionOptionResponse = {
  __typename?: 'ProductQuestionOptionResponse';
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  value: Scalars['String'];
};

export type ProductQuestionResponse = {
  __typename?: 'ProductQuestionResponse';
  currencyCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Array<ProductQuestionOptionResponse>>;
  type: Scalars['String'];
  validationRules?: Maybe<Array<ProductQuestionValidationRuleResponse>>;
};

export type ProductQuestionStepResponse = {
  __typename?: 'ProductQuestionStepResponse';
  analyticsCategory?: Maybe<Scalars['String']>;
  analyticsLabel?: Maybe<Scalars['String']>;
  conditions?: Maybe<Array<ProductQuestionConditionResponse>>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  questionScope?: Maybe<Scalars['String']>;
  questions: Array<ProductQuestionResponse>;
  title: Scalars['String'];
  validationErrorPage?: Maybe<ValidationErrorPage>;
};

export type ProductQuestionValidationRuleResponse = {
  __typename?: 'ProductQuestionValidationRuleResponse';
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  validationFunc: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type Query = {
  __typename?: 'Query';
  accountVerification: AccountVerificationResponse;
  accountingConnection: AccountingConnection;
  accountingConnectionLinks: Array<AccountingConnectionLink>;
  billingEntityInvoiceCsvBreakdown?: Maybe<Scalars['String']>;
  billingEntityInvoicePdfZip?: Maybe<Scalars['String']>;
  billingEntityInvoiceSignedUrl?: Maybe<Scalars['String']>;
  billingEntityInvoiceSignedZipUrl?: Maybe<Scalars['String']>;
  billingEntityInvoices: BillingInvoicesResponse;
  billingHomepageInvoice?: Maybe<BillingInvoice>;
  /** @deprecated Use billingEntityInvoicePdfZip */
  billingInvoicePdfZip?: Maybe<Scalars['String']>;
  /** @deprecated Use billingEntityInvoiceSignedUrl */
  billingInvoiceSignedUrl?: Maybe<Scalars['String']>;
  billingLatestInvoice?: Maybe<BillingLocationInvoice>;
  /** @deprecated Use billingEntityInvoices */
  billingLocationInvoices: BillingLocationInvoiceResponse;
  brazeApiKey: BrazeApiKey;
  businessFundingState: BusinessFundingState;
  businessFundingStateV2: BusinessFundingState;
  cardHolders: CardHolderPagedResult;
  cardMachine?: Maybe<CardMachine>;
  cardMachineActivationCode?: Maybe<CardMachineActivationCode>;
  cardMachineAdminCodes?: Maybe<CardMachineAdminCodes>;
  cardMachineLocationProducts: Array<CardMachineProduct>;
  cardMachineProducts: Array<CardMachineProduct>;
  cardMachineSupervisorCode?: Maybe<CardMachineSupervisorCode>;
  cardMachineSupervisorCodeUpdateStatus?: Maybe<CardMachineSupervisorCodeStatusResponse>;
  carriers: Array<Carrier>;
  checkPermission?: Maybe<Scalars['Void']>;
  completeTransfers: TransferList;
  confirmAccountVerificationConsent: AccountVerificationResponse;
  contact?: Maybe<Contact>;
  contactLocations: Array<Location>;
  contactPreferences: Array<ContactPreference>;
  contacts: Array<ContactPartial>;
  contractDocument: ContractDocumentResponse;
  customer?: Maybe<Customer>;
  customerConfiguration: Array<CustomerProduct>;
  customerLocations: Array<Location>;
  customerReferralUrl: Scalars['String'];
  customerReferrals: CustomerReferral;
  customerReport: ReportSearchResponse;
  customerRole?: Maybe<Role>;
  customerRolePermission: Scalars['Boolean'];
  customerRolePermissions: Array<CustomerRolePermission>;
  customerRolesAndPermissions: Array<CustomerRolePermissions>;
  customers: Array<Customer>;
  dropOffPoints: Array<DropOffPoint>;
  getAdoptionsTracking: TrackingStatusResponse;
  getProductQuestionConfig: ProductQuestionConfigResponse;
  getProductQuestionConfigByMarket: ProductQuestionConfigResponse;
  getProductQuestionConfigSummary: ProductQuestionConfigSummaryResponse;
  getProductQuestionConfigSummaryByMarket: ProductQuestionConfigSummaryResponse;
  getUserPushPreferences?: Maybe<PushPreferences>;
  holidays: Array<Holiday>;
  institutions: Array<InstitutionGroup>;
  internalUserInfo?: Maybe<InternalUser>;
  invite?: Maybe<Invite>;
  invoice?: Maybe<Invoice>;
  invoiceLines?: Maybe<Array<InvoiceLine>>;
  invoiceSettings: InvoiceSettings;
  invoiceSettingsById: InvoiceSettings;
  invoices: InvoicePagedResult;
  latestTransfer?: Maybe<Transfer>;
  links: Array<DataReferenceValue>;
  location?: Maybe<Location>;
  locationCardMachineProducts: LocationCardMachineProducts;
  locationSupervisorCodeUpdateStatus?: Maybe<LocationSupervisorCodeStatusResponse>;
  marketplaceProducts: Array<MarketplaceProduct>;
  marketplaceProductsV2: Array<MarketplaceProductV2>;
  onboardingInfo?: Maybe<Onboarding>;
  onlineCheckoutActivationStatus?: Maybe<OnlineCheckoutActivationStatusType>;
  paymentDetails: PaymentDetails;
  paymentIntents?: Maybe<PaymentIntentPaged>;
  paymentLink: PaymentLinkSummary;
  paymentLinks: PaymentLinksSearchResult;
  payments?: Maybe<PaymentsSearchResultPaged>;
  pciComplianceDocumentSignedUrl: PciSignedUrlResponse;
  pendingTransfers: PendingTransfersList;
  phoneNumbers: Array<DataReferenceValue>;
  pollBusinessFundingStateChange: BusinessFundingStateChangedResponse;
  pollTransactionsSearch: PollTransactionSearchResult;
  pollTransactionsV2: PollTransactionV2Result;
  products: ProductPagedResult;
  queueBookingEligibility: Scalars['Boolean'];
  receiptConfiguration: ReceiptConfigurationResponse;
  refundReasons: Array<DataReferenceValue>;
  remotePayment?: Maybe<RemotePayment>;
  remotePaymentCredentials: RemotePaymentCredentials;
  remotePayments: Array<RemotePayment>;
  remotePaymentsAccounts?: Maybe<Array<Maybe<RemotePaymentsAccount>>>;
  reportMetaData: ReportMetaDataResponse;
  rpApiKey: RpApiKey;
  rpApiKeys: Array<RpApiKey>;
  searchCardMachines: CardMachineSearchResult;
  searchCardMachinesV2: CardMachineSearchResultV2;
  searchInvites: Array<Invite>;
  searchLocations: LocationSearchResponse;
  searchTapDevices: Array<Maybe<TapDevice>>;
  searchTeamMembersInvites: TeamMembersInvites;
  searchTransactions: TransactionSearchResult;
  searchTransactionsV2: TransactionV2SearchResult;
  summaryTransactionsV2: TransactionV2SummaryResult;
  supportedEpos: Array<Scalars['String']>;
  tapDevice?: Maybe<TapDeviceReference>;
  tapDeviceDetails?: Maybe<TapDevice>;
  terminalOrders: Array<TerminalOrder>;
  terminalReturns: Array<TerminalReturn>;
  terminalSearchTransactions?: Maybe<TransactionSearchResult>;
  terminalTransactionByIdV2: TransactionV2;
  timeSeries: TimeSeriesResponse;
  todaysTransfers?: Maybe<TodaysTransfers>;
  totals: TotalsResponse;
  transaction: Transaction;
  transferDetails: Transfer;
  validateBankAccount: BankAccountValidationResponse;
  valueProp: ValuePropViewModel;
  valuePropByMarket: ValuePropViewModel;
  verifyBankAccount: BankAccountVerificationResponse;
  webhooksEvents: Array<WebhookEvents>;
  webhooksSubscriptions: Array<WebhookSubscription>;
};

export type QueryAccountVerificationArgs = {
  customerId: Scalars['ID'];
};

export type QueryAccountingConnectionArgs = {
  customerId: Scalars['ID'];
  platform: Scalars['String'];
};

export type QueryAccountingConnectionLinksArgs = {
  customerId: Scalars['ID'];
};

export type QueryBillingEntityInvoiceCsvBreakdownArgs = {
  invoice: BillingEntityInvoiceRequest;
};

export type QueryBillingEntityInvoicePdfZipArgs = {
  invoices: Array<BillingEntityInvoiceRequest>;
};

export type QueryBillingEntityInvoiceSignedUrlArgs = {
  invoice: BillingEntityInvoiceRequest;
};

export type QueryBillingEntityInvoiceSignedZipUrlArgs = {
  request: BillingSignedZipUrlRequest;
};

export type QueryBillingEntityInvoicesArgs = {
  entities: Array<BillingGetEntityInvoicesRequest>;
};

export type QueryBillingHomepageInvoiceArgs = {
  customerId: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
};

export type QueryBillingInvoicePdfZipArgs = {
  invoiceIds: Array<Scalars['String']>;
  locationId: Scalars['ID'];
};

export type QueryBillingInvoiceSignedUrlArgs = {
  invoiceId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type QueryBillingLatestInvoiceArgs = {
  customerId: Scalars['ID'];
};

export type QueryBillingLocationInvoicesArgs = {
  customerId: Scalars['ID'];
};

export type QueryBrazeApiKeyArgs = {
  client: BrazeClient;
  marketId: Scalars['ID'];
};

export type QueryBusinessFundingStateArgs = {
  customerId: Scalars['ID'];
};

export type QueryBusinessFundingStateV2Args = {
  customerId: Scalars['ID'];
};

export type QueryCardHoldersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  remotePaymentAccountId: Scalars['ID'];
};

export type QueryCardMachineArgs = {
  cardMachineId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type QueryCardMachineActivationCodeArgs = {
  cardMachineId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type QueryCardMachineAdminCodesArgs = {
  cardMachineId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type QueryCardMachineLocationProductsArgs = {
  customerId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type QueryCardMachineProductsArgs = {
  customerId: Scalars['ID'];
};

export type QueryCardMachineSupervisorCodeArgs = {
  cardMachineId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type QueryCardMachineSupervisorCodeUpdateStatusArgs = {
  locationId: Scalars['ID'];
  statusId: Scalars['String'];
  terminalId: Scalars['ID'];
};

export type QueryCarriersArgs = {
  marketId?: InputMaybe<Scalars['ID']>;
  postcode?: InputMaybe<Scalars['String']>;
};

export type QueryCheckPermissionArgs = {
  customerId: Scalars['ID'];
  permission: Scalars['String'];
};

export type QueryCompleteTransfersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryConfirmAccountVerificationConsentArgs = {
  customerId: Scalars['ID'];
};

export type QueryContactLocationsArgs = {
  options?: InputMaybe<ContactLocationsQueryOptions>;
};

export type QueryContactPreferencesArgs = {
  customerId: Scalars['ID'];
};

export type QueryContactsArgs = {
  locationId: Scalars['ID'];
};

export type QueryContractDocumentArgs = {
  customerId: Scalars['ID'];
  leadId: Scalars['String'];
};

export type QueryCustomerArgs = {
  customerId: Scalars['ID'];
};

export type QueryCustomerConfigurationArgs = {
  customerId: Scalars['ID'];
};

export type QueryCustomerLocationsArgs = {
  customerId: Scalars['ID'];
  options?: InputMaybe<CustomerLocationsQueryOptions>;
};

export type QueryCustomerReferralUrlArgs = {
  customerId: Scalars['ID'];
};

export type QueryCustomerReferralsArgs = {
  customerId: Scalars['ID'];
};

export type QueryCustomerReportArgs = {
  customerId: Scalars['String'];
  report: ReportNames;
};

export type QueryCustomerRoleArgs = {
  customerId: Scalars['ID'];
};

export type QueryCustomerRolePermissionArgs = {
  customerId: Scalars['ID'];
  permission: Scalars['String'];
  role: Role;
};

export type QueryCustomerRolePermissionsArgs = {
  customerId: Scalars['ID'];
  role: Role;
};

export type QueryCustomerRolesAndPermissionsArgs = {
  customerId: Scalars['ID'];
};

export type QueryCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  params?: InputMaybe<CustomerRoleFilterInput>;
};

export type QueryDropOffPointsArgs = {
  carrierId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type QueryGetAdoptionsTrackingArgs = {
  adoptionIds?: InputMaybe<Array<Scalars['ID']>>;
  customerId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type QueryGetProductQuestionConfigArgs = {
  customerId: Scalars['String'];
  productId: Scalars['ID'];
};

export type QueryGetProductQuestionConfigByMarketArgs = {
  customerId: Scalars['String'];
  marketId: Scalars['String'];
  productId: Scalars['ID'];
};

export type QueryGetProductQuestionConfigSummaryArgs = {
  customerId: Scalars['String'];
  productId: Scalars['ID'];
};

export type QueryGetProductQuestionConfigSummaryByMarketArgs = {
  customerId: Scalars['String'];
  marketId: Scalars['String'];
  productId: Scalars['ID'];
};

export type QueryGetUserPushPreferencesArgs = {
  marketId: Scalars['String'];
};

export type QueryHolidaysArgs = {
  customerId: Scalars['ID'];
};

export type QueryInviteArgs = {
  inviteId: Scalars['ID'];
};

export type QueryInvoiceArgs = {
  invoiceId: Scalars['ID'];
  remotePaymentAccountId: Scalars['ID'];
};

export type QueryInvoiceLinesArgs = {
  invoiceId: Scalars['ID'];
  remotePaymentAccountId: Scalars['ID'];
};

export type QueryInvoiceSettingsArgs = {
  remotePaymentAccountId: Scalars['ID'];
};

export type QueryInvoiceSettingsByIdArgs = {
  invoiceSettingsId: Scalars['ID'];
  remotePaymentAccountId: Scalars['ID'];
};

export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dueDateAfter?: InputMaybe<Scalars['String']>;
  dueDateBefore?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  remotePaymentAccountId: Scalars['ID'];
  statuses?: InputMaybe<Array<InvoiceStatus>>;
};

export type QueryLatestTransferArgs = {
  customerId: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
};

export type QueryLinksArgs = {
  marketId: Scalars['ID'];
};

export type QueryLocationArgs = {
  locationId: Scalars['ID'];
};

export type QueryLocationCardMachineProductsArgs = {
  locationId: Scalars['ID'];
};

export type QueryLocationSupervisorCodeUpdateStatusArgs = {
  locationId: Scalars['ID'];
  statusId: Scalars['String'];
};

export type QueryMarketplaceProductsArgs = {
  customerDataInput: CustomerDataInput;
};

export type QueryMarketplaceProductsV2Args = {
  customerDataInput: CustomerDataInput;
};

export type QueryOnboardingInfoArgs = {
  customerId: Scalars['String'];
  firstName: Scalars['String'];
  mcc: Scalars['String'];
};

export type QueryOnlineCheckoutActivationStatusArgs = {
  accountId: Scalars['ID'];
};

export type QueryPaymentDetailsArgs = {
  locationId: Scalars['ID'];
  paymentIntentId: Scalars['ID'];
};

export type QueryPaymentIntentsArgs = {
  accountId: Scalars['ID'];
  params: PaymentIntentsInput;
  sandbox?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPaymentLinkArgs = {
  locationId: Scalars['ID'];
  paymentIntentId: Scalars['ID'];
};

export type QueryPaymentLinksArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  filters: PaymentLinksSearchInput;
  locationId: Scalars['ID'];
  pageSize: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPaymentsArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  filters: PaymentsSearchInput;
  locationId: Scalars['ID'];
  pageSize: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPciComplianceDocumentSignedUrlArgs = {
  customerId: Scalars['ID'];
  pciDocumentType: PciDocumentType;
};

export type QueryPendingTransfersArgs = {
  customerId: Scalars['ID'];
};

export type QueryPhoneNumbersArgs = {
  marketId: Scalars['ID'];
};

export type QueryPollBusinessFundingStateChangeArgs = {
  customerId: Scalars['ID'];
  hasBusinessFundingStateChangedRequest: HasBusinessFundingStateChangedRequest;
};

export type QueryPollTransactionsSearchArgs = {
  search: PollTransactionSearchInput;
};

export type QueryPollTransactionsV2Args = {
  search: TransactionV2PollInput;
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  remotePaymentAccountId: Scalars['ID'];
};

export type QueryQueueBookingEligibilityArgs = {
  customerId: Scalars['ID'];
};

export type QueryReceiptConfigurationArgs = {
  locationId: Scalars['ID'];
};

export type QueryRemotePaymentArgs = {
  locationId: Scalars['ID'];
  remoteId: Scalars['ID'];
};

export type QueryRemotePaymentCredentialsArgs = {
  locationId: Scalars['ID'];
  remoteId: Scalars['ID'];
};

export type QueryRemotePaymentsArgs = {
  locationId: Scalars['ID'];
};

export type QueryRpApiKeyArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryRpApiKeysArgs = {
  accountId: Scalars['ID'];
  params?: InputMaybe<RpApiKeySearchInput>;
};

export type QuerySearchCardMachinesArgs = {
  filters: CardMachineSearchInput;
};

export type QuerySearchCardMachinesV2Args = {
  filters: CardMachineSearchInputV2;
};

export type QuerySearchInvitesArgs = {
  filter?: InputMaybe<StatusFilter>;
};

export type QuerySearchLocationsArgs = {
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QuerySearchTapDevicesArgs = {
  filters: TapDeviceSearchInput;
};

export type QuerySearchTeamMembersInvitesArgs = {
  filter: SearchTeamMembersInvitesInput;
};

export type QuerySearchTransactionsArgs = {
  search: TransactionPagedSearchInput;
};

export type QuerySearchTransactionsV2Args = {
  search: TransactionV2SearchInput;
};

export type QuerySummaryTransactionsV2Args = {
  search: TransactionV2SearchInput;
};

export type QueryTapDeviceArgs = {
  fingerprint: Scalars['String'];
  locationId: Scalars['ID'];
};

export type QueryTapDeviceDetailsArgs = {
  locationId: Scalars['ID'];
  terminalId: Scalars['ID'];
};

export type QueryTerminalOrdersArgs = {
  filters: TerminalOrdersInput;
};

export type QueryTerminalReturnsArgs = {
  filters: TerminalReturnsInput;
};

export type QueryTerminalSearchTransactionsArgs = {
  search: TerminalTransactionPagedSearchInput;
};

export type QueryTerminalTransactionByIdV2Args = {
  getRemainingBalance?: InputMaybe<Scalars['Boolean']>;
  locationId: Scalars['ID'];
  terminalId?: InputMaybe<Scalars['ID']>;
  timeZone?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['ID'];
};

export type QueryTimeSeriesArgs = {
  datePeriod: DatePeriod;
  locationIds: Array<Scalars['String']>;
  reportType: TimeSeriesReportType;
  userCurrentDate: Scalars['String'];
};

export type QueryTodaysTransfersArgs = {
  customerId: Scalars['ID'];
};

export type QueryTotalsArgs = {
  datePeriod: DatePeriod;
  locationIds: Array<Scalars['String']>;
  reportType: TotalsReportType;
  userCurrentDate: Scalars['String'];
};

export type QueryTransactionArgs = {
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
};

export type QueryTransferDetailsArgs = {
  customerId: Scalars['ID'];
  paymentOrderId: Scalars['String'];
};

export type QueryValidateBankAccountArgs = {
  bankAccountInput: BankAccountValidationInput;
  customerId: Scalars['ID'];
};

export type QueryValuePropArgs = {
  customerId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type QueryValuePropByMarketArgs = {
  customerId: Scalars['ID'];
  marketId: Scalars['String'];
  productId: Scalars['ID'];
};

export type QueryVerifyBankAccountArgs = {
  bankAccountInput: BankAccountValidationInput;
  customerId: Scalars['ID'];
};

export type QueryWebhooksEventsArgs = {
  accountId: Scalars['ID'];
  sandbox?: Scalars['Boolean'];
};

export type QueryWebhooksSubscriptionsArgs = {
  accountId: Scalars['ID'];
  sandbox?: Scalars['Boolean'];
};

export type QueryAccountVerification = {
  __typename?: 'QueryAccountVerification';
  customerId: Scalars['ID'];
};

export type QueueBookingForm = {
  company: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  mobilephone: Scalars['String'];
  zip: Scalars['String'];
};

export enum ReasonCode {
  Ac01 = 'AC01',
  Acns = 'ACNS',
  Annm = 'ANNM',
  Bamm = 'BAMM',
  Banm = 'BANM',
  Cass = 'CASS',
  Ivcr = 'IVCR',
  Mbam = 'MBAM',
  NA = 'N_A',
  Opto = 'OPTO',
  Pamm = 'PAMM',
  Panm = 'PANM',
  Scnf = 'SCNF',
  Scns = 'SCNS',
}

export type ReceiptConfigurationInput = {
  customerEmailReceiptEnabled?: InputMaybe<Scalars['Boolean']>;
  merchantEmailAddress?: InputMaybe<Scalars['EmailAddress']>;
  merchantEmailReceiptEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type ReceiptConfigurationResponse = {
  __typename?: 'ReceiptConfigurationResponse';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerEmailReceiptEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  lastUpdated?: Maybe<Scalars['String']>;
  merchantEmailAddress?: Maybe<Scalars['EmailAddress']>;
  merchantEmailReceiptEnabled?: Maybe<Scalars['Boolean']>;
};

export type ReceiptTokenResponse = {
  __typename?: 'ReceiptTokenResponse';
  expireAt?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type Referral = {
  __typename?: 'Referral';
  refereeContactId?: Maybe<Scalars['String']>;
  refereeCustomerId?: Maybe<Scalars['String']>;
  referrerReward?: Maybe<ReferrerReward>;
  status?: Maybe<ReferralStatus>;
};

export enum ReferralStatus {
  Accepted = 'Accepted',
  Completed = 'Completed',
  Pending = 'Pending',
}

export type ReferrerReward = {
  __typename?: 'ReferrerReward';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type RegisteredAddress = {
  __typename?: 'RegisteredAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryIsoAlpha2?: Maybe<Scalars['String']>;
  countryIsoAlpha3?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  houseNameNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type RegisteredInformation = {
  __typename?: 'RegisteredInformation';
  companyNumber?: Maybe<Scalars['String']>;
  /** @deprecated countryIsoAlpha2 is deprecated. Please use countryIsoAlpha3 instead. */
  countryIsoAlpha2?: Maybe<Scalars['String']>;
  countryIsoAlpha3?: Maybe<Scalars['String']>;
  incorporationDate?: Maybe<Scalars['Timestamp']>;
  registeredAddress?: Maybe<RegisteredAddress>;
  registeredName?: Maybe<Scalars['String']>;
};

export type ReleasePreAuthResponse = FailedTransactionOperation | PreAuthReleased;

export enum ReleaseStatus {
  Created = 'Created',
  Failed = 'Failed',
  Successful = 'Successful',
}

export type RemotePayment = {
  __typename?: 'RemotePayment';
  accountId?: Maybe<Scalars['String']>;
  amexMid?: Maybe<Scalars['String']>;
  remoteId: Scalars['String'];
  remotePaymentLocationType: Scalars['String'];
  remotePaymentSettings: Array<RemotePaymentSetting>;
  url: Scalars['String'];
};

export type RemotePaymentAppConfig = {
  __typename?: 'RemotePaymentAppConfig';
  accountId: Scalars['String'];
  branding: RemotePaymentAppConfigBranding;
  connect?: Maybe<RemotePaymentAppConfigConnect>;
  maxPaymentAmount: Scalars['Int'];
  onlineCheckout: RemotePaymentAppConfigOnlineCheckout;
  payByBank?: Maybe<RemotePaymentAppConfigPayByBank>;
  paymentLinks: RemotePaymentAppConfigPaymentLinks;
  portal: RemotePaymentAppConfigPortal;
  preauth: RemotePaymentAppConfigPreauth;
  remotePayments?: Maybe<RemotePaymentAppConfigRemotePayments>;
  virtualTerminal: RemotePaymentAppConfigVirtualTerminal;
};

export type RemotePaymentAppConfigBranding = {
  __typename?: 'RemotePaymentAppConfigBranding';
  enabled: Scalars['Boolean'];
  faviconUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type RemotePaymentAppConfigBrandingInput = {
  faviconUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
};

export type RemotePaymentAppConfigConnect = {
  __typename?: 'RemotePaymentAppConfigConnect';
  enabled: Scalars['Boolean'];
};

export type RemotePaymentAppConfigOnlineCheckout = {
  __typename?: 'RemotePaymentAppConfigOnlineCheckout';
  enabled: Scalars['Boolean'];
};

export type RemotePaymentAppConfigPayByBank = {
  __typename?: 'RemotePaymentAppConfigPayByBank';
  enabled?: Maybe<Scalars['Boolean']>;
};

export type RemotePaymentAppConfigPaymentLinks = {
  __typename?: 'RemotePaymentAppConfigPaymentLinks';
  enabled: Scalars['Boolean'];
};

export type RemotePaymentAppConfigPortal = {
  __typename?: 'RemotePaymentAppConfigPortal';
  enabled: Scalars['Boolean'];
};

export type RemotePaymentAppConfigPreauth = {
  __typename?: 'RemotePaymentAppConfigPreauth';
  enabled: Scalars['Boolean'];
};

export type RemotePaymentAppConfigRemotePayments = {
  __typename?: 'RemotePaymentAppConfigRemotePayments';
  enabled: Scalars['Boolean'];
};

export type RemotePaymentAppConfigVirtualTerminal = {
  __typename?: 'RemotePaymentAppConfigVirtualTerminal';
  enabled: Scalars['Boolean'];
};

export type RemotePaymentCredentials = {
  __typename?: 'RemotePaymentCredentials';
  gatewayUsername: Scalars['String'];
  jwtToken: Scalars['String'];
};

export type RemotePaymentSetting = {
  __typename?: 'RemotePaymentSetting';
  header: Scalars['String'];
  value: Scalars['String'];
};

export type RemotePaymentsAccount = {
  __typename?: 'RemotePaymentsAccount';
  accessedViaPartnerIds?: Maybe<Array<Scalars['String']>>;
  appConfig: RemotePaymentsAppConfig;
  id: Scalars['String'];
  location?: Maybe<RemotePaymentsAccountLocation>;
  locationId?: Maybe<Scalars['String']>;
  merchant?: Maybe<RemotePaymentsAccountMerchant>;
  merchantId?: Maybe<Scalars['String']>;
  partners?: Maybe<Array<PartnerAccessContext>>;
  type: RemotePaymentsAccountType;
};

export type RemotePaymentsAccountLocation = {
  __typename?: 'RemotePaymentsAccountLocation';
  id: Scalars['String'];
  tradingAddress?: Maybe<Address>;
  tradingName: Scalars['String'];
};

export type RemotePaymentsAccountLocationAddress = {
  __typename?: 'RemotePaymentsAccountLocationAddress';
  city: Scalars['String'];
  houseNameNumber: Scalars['String'];
  postCode: Scalars['String'];
  street: Scalars['String'];
};

export type RemotePaymentsAccountMerchant = {
  __typename?: 'RemotePaymentsAccountMerchant';
  id: Scalars['String'];
  registeredName: Scalars['String'];
};

export enum RemotePaymentsAccountType {
  Dojo = 'Dojo',
  Sandbox = 'Sandbox',
}

export type RemotePaymentsAppConfig = {
  __typename?: 'RemotePaymentsAppConfig';
  branding: RemotePaymentAppConfigBranding;
  connect?: Maybe<RemotePaymentAppConfigConnect>;
  maxPaymentAmount: Scalars['Int'];
  onlineCheckout: RemotePaymentAppConfigOnlineCheckout;
  payByBank?: Maybe<RemotePaymentAppConfigPayByBank>;
  paymentLinks: RemotePaymentAppConfigPaymentLinks;
  portal: RemotePaymentAppConfigPortal;
  preauth: RemotePaymentAppConfigPreauth;
  remotePayments?: Maybe<RemotePaymentAppConfigRemotePayments>;
  virtualTerminal: RemotePaymentAppConfigVirtualTerminal;
};

export type RemoveContactAccepted = {
  __typename?: 'RemoveContactAccepted';
  accepted: Scalars['Boolean'];
};

export enum RemoveContactErrorType {
  CloseTapDevicesApi = 'CloseTapDevicesApi',
  DisconnectContactApi = 'DisconnectContactApi',
  OwnerRoleType = 'OwnerRoleType',
  RemoveContactRoleValidationFailed = 'RemoveContactRoleValidationFailed',
  RequestingContactRoleValidationFailed = 'RequestingContactRoleValidationFailed',
}

export type RemoveContactFailed = Error & {
  __typename?: 'RemoveContactFailed';
  errorType: RemoveContactErrorType;
  message: Scalars['String'];
};

export type RemoveContactResponse = RemoveContactAccepted | RemoveContactFailed;

export enum ReportNames {
  Insights = 'Insights',
  Main = 'Main',
}

export type ReportSearchResponse = {
  __typename?: 'ReportSearchResponse';
  expiresIn: Scalars['Int'];
  path: Scalars['String'];
};

export type RequestError = {
  __typename?: 'RequestError';
  message: Scalars['String'];
};

export type ResubmitOnlineCheckoutActivationInput = {
  onlineCheckoutActivationCaseId: Scalars['String'];
};

export type ReturnAddress = {
  __typename?: 'ReturnAddress';
  city?: Maybe<Scalars['String']>;
  countryIsoAlpha3?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  houseNameNumber?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type ReturnCarrier = {
  __typename?: 'ReturnCarrier';
  carrierId: Scalars['ID'];
  name: Scalars['String'];
};

export type ReturnContact = {
  __typename?: 'ReturnContact';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export enum ReturnReason {
  NoLongerNeeded = 'NoLongerNeeded',
  SwapOut = 'SwapOut',
}

export enum ReturnStatus {
  Cancelled = 'Cancelled',
  Collected = 'Collected',
  InProgress = 'InProgress',
  Requested = 'Requested',
}

export type ReturningCardMachine = {
  __typename?: 'ReturningCardMachine';
  id: Scalars['String'];
  manufacturerDescription: Scalars['String'];
  manufacturerId?: Maybe<Scalars['String']>;
  modelDescription: Scalars['String'];
  modelId: Scalars['String'];
  modelName: Scalars['String'];
  name: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type ReversalPaymentLinkInput = {
  paymentIntentId: Scalars['ID'];
};

export type ReviewOnlineCheckoutActivationInput = {
  url: Scalars['URL'];
};

export type ReviewOnlineCheckoutActivationResult = {
  __typename?: 'ReviewOnlineCheckoutActivationResult';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  url: Scalars['URL'];
};

export enum Role {
  Admin = 'Admin',
  Developer = 'Developer',
  Manager = 'Manager',
  Owner = 'Owner',
  Staff = 'Staff',
  Viewer = 'Viewer',
}

export type RolePermission = {
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type RolePermissions = {
  permissions: Array<RolePermission>;
  role: Role;
};

export type RpApiKey = {
  __typename?: 'RpApiKey';
  accountId: Scalars['String'];
  apiKeyType: RpApiKeyType;
  appId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  encrypted: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  scope: Scalars['String'];
};

export type RpApiKeyAddInput = {
  apiKeyType: RpApiKeyType;
  appId?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type RpApiKeySearchInput = {
  apiKeyType?: InputMaybe<RpApiKeyType>;
};

export enum RpApiKeyType {
  Prod = 'Prod',
  Sandbox = 'Sandbox',
}

export type RpApiKeyUpdateInput = {
  expirationDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SearchTeamMembersInvitesInput = {
  customerId: Scalars['ID'];
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  roles?: InputMaybe<Array<Role>>;
  statuses?: InputMaybe<Array<TeamMemberInviteStatus>>;
};

export type SepaInformation = {
  __typename?: 'SepaInformation';
  businessToBusinessSupported: Scalars['Boolean'];
  cardClearingSupported: Scalars['Boolean'];
  cor1Supported: Scalars['Boolean'];
  creditInstantSupported: Scalars['Boolean'];
  creditTransferSupported: Scalars['Boolean'];
  directDebitSupported: Scalars['Boolean'];
};

export type ServiceFee = {
  __typename?: 'ServiceFee';
  description?: Maybe<Scalars['String']>;
  fixedValue?: Maybe<ChargeableItemValue>;
  id: Scalars['String'];
  paymentFrequency?: Maybe<Scalars['String']>;
  percentageValue?: Maybe<ChargeableItemValue>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address?: Maybe<TransactionAddress>;
  deliveryNotes?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Snapshot = {
  __typename?: 'Snapshot';
  amount?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
};

export type StatusFilter = {
  status_equals?: InputMaybe<Array<Scalars['String']>>;
};

export type SubmitApplicationRequest = {
  accountVerificationType: AccountVerificationType;
  campaign?: InputMaybe<Scalars['String']>;
  channelOverride?: InputMaybe<Scalars['String']>;
};

export type SubmitApplicationV2Request = {
  applicationSubmissionType: ApplicationSubmissionType;
  campaign?: InputMaybe<Scalars['String']>;
  channelOverride?: InputMaybe<Scalars['String']>;
};

export type SubmitBusinessFundingError = {
  __typename?: 'SubmitBusinessFundingError';
  errorType: SubmitBusinessFundingErrorKind;
};

export enum SubmitBusinessFundingErrorKind {
  Api = 'Api',
  ProcessTimeout = 'ProcessTimeout',
}

export type SubmitBusinessFundingReferralResponse = {
  __typename?: 'SubmitBusinessFundingReferralResponse';
  message?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
};

export type SubmitBusinessFundingResponse = BusinessFundingState | SubmitBusinessFundingError;

export type SubmitLeadRequest = {
  campaign?: InputMaybe<Scalars['String']>;
  channelOverride?: InputMaybe<Scalars['String']>;
};

export enum SupervisorCodeStatus {
  Failed = 'FAILED',
  Partialfailed = 'PARTIALFAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Unknown = 'UNKNOWN',
}

export type TapDevice = {
  __typename?: 'TapDevice';
  contact?: Maybe<Contact>;
  customerId: Scalars['ID'];
  deviceModel: Scalars['String'];
  fingerprint: Scalars['String'];
  flags?: Maybe<TapDeviceFlags>;
  location?: Maybe<Location>;
  locationId: Scalars['ID'];
  modelId: Scalars['String'];
  modelType: TapDeviceModelType;
  nickname: Scalars['String'];
  provisionedAt: Scalars['Timestamp'];
  provisionedBy: Scalars['String'];
  registered: Scalars['Boolean'];
  status: TapDeviceStatus;
  terminalId: Scalars['ID'];
};

export type TapDeviceClosed = {
  __typename?: 'TapDeviceClosed';
  customerId: Scalars['ID'];
  locationId: Scalars['ID'];
  terminalId: Scalars['ID'];
};

export type TapDeviceFlags = {
  __typename?: 'TapDeviceFlags';
  showCloseDevice: Scalars['Boolean'];
};

export enum TapDeviceModelType {
  AndroidPlugin = 'AndroidPlugin',
  ApplePlugin = 'ApplePlugin',
}

export type TapDeviceOrderCreated = {
  __typename?: 'TapDeviceOrderCreated';
  orderId: Scalars['String'];
};

export type TapDeviceReference = {
  __typename?: 'TapDeviceReference';
  fingerprint: Scalars['String'];
  registered: Scalars['Boolean'];
  status: TapDeviceReferenceStatus;
  terminalId: Scalars['ID'];
};

export enum TapDeviceReferenceStatus {
  AcquiringSetupCompleted = 'AcquiringSetupCompleted',
  Active = 'Active',
  AuthGatewaySetupCompleted = 'AuthGatewaySetupCompleted',
  Closed = 'Closed',
  OrderCompleted = 'OrderCompleted',
  OrderStarted = 'OrderStarted',
  TapDeviceSetupCompleted = 'TapDeviceSetupCompleted',
  TidAssigned = 'TidAssigned',
}

export type TapDeviceSearchInput = {
  customerId: Scalars['ID'];
  fingerprints?: InputMaybe<Array<Scalars['String']>>;
  locationIds: Array<Scalars['String']>;
  statuses?: InputMaybe<Array<TapDeviceStatus>>;
};

export enum TapDeviceStatus {
  Active = 'Active',
  Closed = 'Closed',
}

export type TapTransactionEmail = {
  __typename?: 'TapTransactionEmail';
  email: Scalars['String'];
  transactionId: Scalars['String'];
};

export type TeamMember = {
  __typename?: 'TeamMember';
  contact: Contact;
  locationIds?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<ClientRole>;
};

export enum TeamMemberInviteStatus {
  Accepted = 'Accepted',
  Active = 'Active',
  Expired = 'Expired',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Revoked = 'Revoked',
}

export type TeamMembersInvites = {
  __typename?: 'TeamMembersInvites';
  invites?: Maybe<Array<Invite>>;
  teamMembers?: Maybe<Array<TeamMember>>;
};

export type TerminalOrder = {
  __typename?: 'TerminalOrder';
  activationCode?: Maybe<Scalars['String']>;
  cardMachines: Array<TerminalOrderCardMachine>;
  contact?: Maybe<TerminalOrderContact>;
  createdDate: Scalars['String'];
  deliveryAddress?: Maybe<TerminalOrderAddress>;
  locationId: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  orderCancelledDate?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  orderState?: Maybe<TerminalOrderState>;
  scheduledDeliveryDate?: Maybe<Scalars['String']>;
  shipmentStatuses?: Maybe<Array<TerminalOrderShipmentStatusChange>>;
  trackingId?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  tradingAddress?: Maybe<TerminalOrderAddress>;
  tradingName: Scalars['String'];
  updatedDate: Scalars['String'];
};

export type TerminalOrderAddress = {
  __typename?: 'TerminalOrderAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryIsoAlpha2?: Maybe<Scalars['String']>;
  countryIsoAlpha3: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  houseNameNumber?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type TerminalOrderCardMachine = {
  __typename?: 'TerminalOrderCardMachine';
  modelId: Scalars['String'];
  modelName?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['String']>;
};

export type TerminalOrderContact = {
  __typename?: 'TerminalOrderContact';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type TerminalOrderCreated = {
  __typename?: 'TerminalOrderCreated';
  orderId: Scalars['String'];
};

export type TerminalOrderShipmentStatusChange = {
  __typename?: 'TerminalOrderShipmentStatusChange';
  date: Scalars['String'];
  status: Scalars['String'];
};

export type TerminalOrderState = {
  __typename?: 'TerminalOrderState';
  cancelledDate?: Maybe<Scalars['String']>;
  currentStatus?: Maybe<TerminalOrderStatus>;
  deliveredDate?: Maybe<Scalars['String']>;
  deliveryIssueDate?: Maybe<Scalars['String']>;
  dispatchedDate?: Maybe<Scalars['String']>;
  orderPlacedDate: Scalars['String'];
  redeliveryDate?: Maybe<Scalars['String']>;
};

export enum TerminalOrderStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  DeliveryIssue = 'DeliveryIssue',
  Dispatched = 'Dispatched',
  OrderPlaced = 'OrderPlaced',
  Redelivery = 'Redelivery',
}

export type TerminalOrdersInput = {
  customerId: Scalars['ID'];
  locationIds: Array<Scalars['String']>;
  orderStatuses?: InputMaybe<Array<TerminalOrderStatus>>;
};

export type TerminalReturn = {
  __typename?: 'TerminalReturn';
  address: ReturnAddress;
  cardMachines: Array<ReturningCardMachine>;
  carrier: ReturnCarrier;
  contact: ReturnContact;
  customerId: Scalars['String'];
  groupCorrelationId: Scalars['String'];
  id: Scalars['String'];
  locationId: Scalars['String'];
  marketId: Scalars['String'];
  provider: Scalars['String'];
  requestCreatedAt: Scalars['String'];
  returnDate: Scalars['String'];
  returnMode: CollectionMode;
  returnReason: Scalars['String'];
  returnStatus: ReturnStatus;
  tradingAddress?: Maybe<AddressContact>;
  tradingName: Scalars['String'];
  tradingNickname?: Maybe<Scalars['String']>;
};

export type TerminalReturnCancelled = {
  __typename?: 'TerminalReturnCancelled';
  response: Scalars['String'];
};

export type TerminalReturnCreated = {
  __typename?: 'TerminalReturnCreated';
  returnId: Scalars['String'];
};

export type TerminalReturnsInput = {
  customerId: Scalars['ID'];
  locationIds: Array<Scalars['String']>;
  returnStatuses?: InputMaybe<Array<ReturnStatus>>;
};

export type TerminalSupervisorCodeUpdateStatus = {
  __typename?: 'TerminalSupervisorCodeUpdateStatus';
  status: CardMachineSupervisorCodeUpdatedStatus;
  terminalId: Scalars['String'];
};

export type TerminalTransactionPagedSearchInput = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  filters: TerminalTransactionSearchInput;
  pageSize: Scalars['Int'];
};

export type TerminalTransactionSearchInput = {
  cardMachineTerminalIds?: InputMaybe<Array<Scalars['String']>>;
  cardTypes?: InputMaybe<Array<TransactionCardType>>;
  dateRange: Scalars['String'];
  dcc?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['String']>;
  fromTime?: InputMaybe<Scalars['String']>;
  locationIds: Array<Scalars['String']>;
  outcomes?: InputMaybe<Array<TransactionOutcome>>;
  schemes?: InputMaybe<Array<TransactionScheme>>;
  sourceTypes?: InputMaybe<Array<TransactionSourceType>>;
  timeRange: Scalars['String'];
  timeZone: Scalars['String'];
  toDate?: InputMaybe<Scalars['String']>;
  toTime?: InputMaybe<Scalars['String']>;
  transactionTypes?: InputMaybe<Array<TransactionType>>;
};

export enum TimeSeriesReportType {
  Refunds = 'Refunds',
  ReturningCards = 'ReturningCards',
  Sales = 'Sales',
  Transactions = 'Transactions',
}

export type TimeoutLocationNicknameUpdate = Error & {
  __typename?: 'TimeoutLocationNicknameUpdate';
  message: Scalars['String'];
};

export type TimeoutPciUpdate = Error & {
  __typename?: 'TimeoutPCIUpdate';
  message: Scalars['String'];
};

export type TimeoutUpdate = Error & {
  __typename?: 'TimeoutUpdate';
  message: Scalars['String'];
};

export type TodaysTransfers = {
  __typename?: 'TodaysTransfers';
  sumOfTodaysTransfers: Scalars['Float'];
  transfers: Array<Transfer>;
};

export enum TotalsReportType {
  AverageSalesValue = 'AverageSalesValue',
  Refunds = 'Refunds',
  ReturningCards = 'ReturningCards',
  Sales = 'Sales',
  Transactions = 'Transactions',
}

export type TrackingStatusLocationResponse = {
  __typename?: 'TrackingStatusLocationResponse';
  locationId: Scalars['ID'];
  products: Array<TrackingStatusProductResponse>;
  tradingName: Scalars['String'];
};

export type TrackingStatusProductResponse = {
  __typename?: 'TrackingStatusProductResponse';
  productId: Scalars['ID'];
  riskCaseStatus?: Maybe<Scalars['String']>;
};

export type TrackingStatusResponse = {
  __typename?: 'TrackingStatusResponse';
  adoptions: Array<AdoptionTrackingStatusResponse>;
  customerId: Scalars['ID'];
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int'];
  authorisationCode?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<TransactionAddress>;
  cardMachine?: Maybe<TransactionCardMachineDetails>;
  cardType: TransactionCardType;
  cardholderDetails?: Maybe<CardholderDetails>;
  cashbackAmount?: Maybe<Scalars['Int']>;
  charges?: Maybe<TransactionCharges>;
  currency: Scalars['String'];
  dcc?: Maybe<TransactionDcc>;
  description?: Maybe<Scalars['String']>;
  displayStatus: TransactionDisplayStatus;
  flags: TransactionFlags;
  gratuityAmount?: Maybe<Scalars['Int']>;
  lastUpdateDateTime: Scalars['String'];
  locationId: Scalars['String'];
  maskedPAN: Scalars['String'];
  merchantOrderNumber?: Maybe<Scalars['String']>;
  paymentMethod: TransactionPaymentMethod;
  preAuth?: Maybe<TransactionPreAuth>;
  refund?: Maybe<TransactionRefund>;
  remainingBalance?: Maybe<Scalars['Int']>;
  remoteId?: Maybe<Scalars['String']>;
  scheme: TransactionScheme;
  shippingAddress?: Maybe<ShippingAddress>;
  source: TransactionSourceType;
  terminalType?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  transactionDateTime: Scalars['String'];
  transactionHistory?: Maybe<Array<Maybe<TransactionHistory>>>;
  transactionId: Scalars['ID'];
};

export type TransactionAddress = {
  __typename?: 'TransactionAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TransactionCardMachineDetails = {
  __typename?: 'TransactionCardMachineDetails';
  name: Scalars['String'];
  serialNumber?: Maybe<Scalars['String']>;
  terminalId: Scalars['String'];
};

export enum TransactionCardType {
  Charge = 'Charge',
  Credit = 'Credit',
  Debit = 'Debit',
  Unknown = 'Unknown',
}

export type TransactionCharges = {
  __typename?: 'TransactionCharges';
  authorisationFee?: Maybe<Scalars['Int']>;
  interchangeFee?: Maybe<Scalars['Int']>;
  refundFee?: Maybe<Scalars['Int']>;
  secureFeeAmount?: Maybe<Scalars['Int']>;
  totalCharge?: Maybe<Scalars['Int']>;
  transactionCharge?: Maybe<Scalars['Int']>;
  vatFeeAmount?: Maybe<Scalars['Int']>;
};

export type TransactionDateGroup = {
  __typename?: 'TransactionDateGroup';
  cashBackCount: Scalars['Int'];
  cashback: Scalars['Int'];
  count: Scalars['Int'];
  currencyCode?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  gratuityCount: Scalars['Int'];
  netSales: Scalars['Float'];
  refundCount: Scalars['Int'];
  salesCount: Scalars['Int'];
  totalCashback: Scalars['Int'];
  totalGratuity: Scalars['Int'];
  totalRefund: Scalars['Int'];
  totalSales: Scalars['Float'];
  transactions?: Maybe<Array<TransactionSummary>>;
};

export type TransactionDcc = {
  __typename?: 'TransactionDcc';
  cardholderAmountString: Scalars['String'];
  cardholderCurrency: Scalars['String'];
  exchangeRate: Scalars['String'];
  markupPercentage: Scalars['String'];
  merchantCurrency: Scalars['String'];
};

export type TransactionDisplayStatus = {
  __typename?: 'TransactionDisplayStatus';
  isReversed: Scalars['Boolean'];
  outcome: TransactionOutcome;
  preAuthStatus?: Maybe<TransactionPreAuthStatus>;
  refundStatus?: Maybe<TransactionRefundStatus>;
  type: TransactionType;
};

export type TransactionF2fRefunded = OperationOutcome & {
  __typename?: 'TransactionF2fRefunded';
  outcome: TransactionOperationOutcomeType;
};

export type TransactionF2fRefundedResponse = FailedTransactionOperation | TransactionF2fRefunded;

export type TransactionFlags = {
  __typename?: 'TransactionFlags';
  showCharges: Scalars['Boolean'];
  showDigitalReceipt: Scalars['Boolean'];
  showFeesAndCharges: Scalars['Boolean'];
  showRefund: Scalars['Boolean'];
  showReversal: Scalars['Boolean'];
};

export type TransactionHistory = {
  __typename?: 'TransactionHistory';
  amount: Scalars['Int'];
  outcome: TransactionOutcome;
  transactionDateTime: Scalars['String'];
  transactionId: Scalars['ID'];
  type: TransactionType;
};

export enum TransactionOperationOutcomeType {
  Declined = 'DECLINED',
  Failed = 'FAILED',
  Notrequested = 'NOTREQUESTED',
  Successful = 'SUCCESSFUL',
  Unknown = 'UNKNOWN',
}

export enum TransactionOutcome {
  Authorised = 'Authorised',
  Declined = 'Declined',
  Held = 'Held',
  Reversed = 'Reversed',
  UnderReview = 'UnderReview',
}

export type TransactionPagedSearchInput = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  filters: TransactionSearchInput;
  pageSize: Scalars['Int'];
};

export enum TransactionPaymentMethod {
  Chip = 'Chip',
  ContactLess = 'ContactLess',
  DojoBookings = 'DojoBookings',
  DojoPocket = 'DojoPocket',
  ECommerce = 'ECommerce',
  Keyed = 'Keyed',
  PayByQr = 'PayByQr',
  PaymentLink = 'PaymentLink',
  Swipe = 'Swipe',
  TapToPayOnIPhone = 'TapToPayOnIPhone',
  VirtualTerminal = 'VirtualTerminal',
}

export type TransactionPreAuth = {
  __typename?: 'TransactionPreAuth';
  expiryDate?: Maybe<Scalars['String']>;
  previousTransactionId?: Maybe<Scalars['String']>;
};

export enum TransactionPreAuthStatus {
  Expired = 'Expired',
  FullyCollected = 'FullyCollected',
  PartiallyCollected = 'PartiallyCollected',
  Released = 'Released',
  Uncollected = 'Uncollected',
}

export type TransactionRefund = {
  __typename?: 'TransactionRefund';
  notes?: Maybe<Scalars['String']>;
  previousTransactionId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export enum TransactionRefundStatus {
  Full = 'Full',
  Partial = 'Partial',
}

export type TransactionRefunded = OperationOutcome & {
  __typename?: 'TransactionRefunded';
  outcome: TransactionOperationOutcomeType;
};

export type TransactionRefundedResponse = FailedTransactionOperation | TransactionRefunded;

export type TransactionReversed = OperationOutcome & {
  __typename?: 'TransactionReversed';
  outcome: TransactionOperationOutcomeType;
};

export type TransactionReversedResponse = FailedTransactionOperation | TransactionReversed;

export enum TransactionScheme {
  Amex = 'Amex',
  Diners = 'Diners',
  Discover = 'Discover',
  Jcb = 'Jcb',
  Maestro = 'Maestro',
  Mastercard = 'Mastercard',
  Other = 'Other',
  Unionpay = 'Unionpay',
  Visa = 'Visa',
}

export type TransactionSearchCursors = {
  __typename?: 'TransactionSearchCursors';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type TransactionSearchInput = {
  cardMachineTerminalIds?: InputMaybe<Array<Scalars['String']>>;
  cardTypes?: InputMaybe<Array<TransactionCardType>>;
  customerId?: InputMaybe<Scalars['String']>;
  dateRange: Scalars['String'];
  dcc?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['String']>;
  fromTime?: InputMaybe<Scalars['String']>;
  locationIds: Array<Scalars['String']>;
  outcomes?: InputMaybe<Array<TransactionOutcome>>;
  schemes?: InputMaybe<Array<TransactionScheme>>;
  searchText?: InputMaybe<Scalars['String']>;
  sourceTypes?: InputMaybe<Array<TransactionSourceType>>;
  timeRange: Scalars['String'];
  timeZone?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
  toTime?: InputMaybe<Scalars['String']>;
  transactionStatuses?: InputMaybe<Array<TransactionStatuses>>;
  transactionTypes?: InputMaybe<Array<TransactionType>>;
};

export type TransactionSearchResult = {
  __typename?: 'TransactionSearchResult';
  cursors: TransactionSearchCursors;
  searchTotals: TransactionSearchTotals;
  transactionDateGroups: Array<Maybe<TransactionDateGroup>>;
};

export type TransactionSearchTotals = {
  __typename?: 'TransactionSearchTotals';
  cashBackCount: Scalars['Int'];
  cashback: Scalars['Int'];
  count: Scalars['Int'];
  currencyCode?: Maybe<Scalars['String']>;
  gratuityCount: Scalars['Int'];
  netSales: Scalars['Float'];
  refundCount: Scalars['Int'];
  salesCount: Scalars['Int'];
  totalCashback: Scalars['Int'];
  totalGratuity: Scalars['Int'];
  totalRefund: Scalars['Int'];
  totalSales: Scalars['Float'];
};

export enum TransactionSourceType {
  CardMachine = 'CardMachine',
  CardMachineAll = 'CardMachineAll',
  DojoBookings = 'DojoBookings',
  DojoPocket = 'DojoPocket',
  ECommerce = 'ECommerce',
  PayByQr = 'PayByQr',
  PaymentLink = 'PaymentLink',
  TapToPayOnIPhone = 'TapToPayOnIPhone',
  VirtualTerminal = 'VirtualTerminal',
}

export enum TransactionStatuses {
  AllPreAuth = 'AllPreAuth',
  Collected = 'Collected',
  Declined = 'Declined',
  Expired = 'Expired',
  Held = 'Held',
  Refunded = 'Refunded',
  Released = 'Released',
  Reversed = 'Reversed',
  Sale = 'Sale',
  Uncollected = 'Uncollected',
  UnderReview = 'UnderReview',
}

export type TransactionSummary = {
  __typename?: 'TransactionSummary';
  amount: Scalars['Int'];
  currency: Scalars['String'];
  displayStatus: TransactionDisplayStatus;
  isDcc?: Maybe<Scalars['Boolean']>;
  lastUpdateDateTime: Scalars['String'];
  locationId: Scalars['String'];
  scheme: TransactionScheme;
  timeZone?: Maybe<Scalars['String']>;
  transactionDateTime: Scalars['String'];
  transactionId: Scalars['ID'];
};

export enum TransactionType {
  Collection = 'Collection',
  PreAuth = 'PreAuth',
  PreAuthAll = 'PreAuthAll',
  PreAuthAwaitingCollection = 'PreAuthAwaitingCollection',
  PreAuthExpired = 'PreAuthExpired',
  PreAuthReleased = 'PreAuthReleased',
  Refund = 'Refund',
  Sale = 'Sale',
}

export type TransactionV2 = {
  __typename?: 'TransactionV2';
  amounts?: Maybe<TransactionV2Amount>;
  application?: Maybe<TransactionV2Application>;
  card?: Maybe<TransactionV2Card>;
  consumer?: Maybe<TransactionV2Consumer>;
  customer?: Maybe<TransactionV2Customer>;
  dcc?: Maybe<TransactionV2Dcc>;
  description?: Maybe<Scalars['String']>;
  isDcc?: Maybe<Scalars['Boolean']>;
  lastUpdateDateTime?: Maybe<Scalars['String']>;
  location?: Maybe<TransactionV2Location>;
  merchantLabel?: Maybe<Scalars['String']>;
  merchantOrderNumber?: Maybe<Scalars['String']>;
  payment?: Maybe<TransactionV2Payment>;
  preAuth?: Maybe<TransactionV2PreAuth>;
  refund?: Maybe<TransactionV2Refund>;
  remainingBalance?: Maybe<Scalars['Int']>;
  remoteId?: Maybe<Scalars['String']>;
  reversal?: Maybe<TransactionV2Reversal>;
  source?: Maybe<TransactionV2Source>;
  status?: Maybe<TransactionV2Status>;
  terminal?: Maybe<TransactionV2Terminal>;
  transactionDateTime: Scalars['String'];
  transactionId: Scalars['ID'];
  type?: Maybe<TransactionV2Type>;
};

export type TransactionV2Amount = {
  __typename?: 'TransactionV2Amount';
  balanceAmount?: Maybe<TransactionV2Amount>;
  cashbackAmount?: Maybe<TransactionV2Amount>;
  description?: Maybe<Scalars['String']>;
  gratuityAmount?: Maybe<TransactionV2Amount>;
  refundAmount?: Maybe<TransactionV2Amount>;
  saleAmount?: Maybe<TransactionV2Amount>;
  subunit?: Maybe<Scalars['Int']>;
  transactionAmount?: Maybe<TransactionV2Amount>;
  unit?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type TransactionV2Application = {
  __typename?: 'TransactionV2Application';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type TransactionV2Card = {
  __typename?: 'TransactionV2Card';
  cardSequenceNumber?: Maybe<Scalars['String']>;
  fullMaskedPAN?: Maybe<Scalars['String']>;
  maskedPAN?: Maybe<Scalars['String']>;
  scheme?: Maybe<TransactionV2CardSchemeType>;
  signatureRequired?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TransactionV2CardTypeType>;
};

export enum TransactionV2CardScheme {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Other = 'OTHER',
  Unionpay = 'UNIONPAY',
  Visa = 'VISA',
}

export type TransactionV2CardSchemeType = {
  __typename?: 'TransactionV2CardSchemeType';
  code?: Maybe<TransactionV2CardScheme>;
  name?: Maybe<Scalars['String']>;
};

export enum TransactionV2CardType {
  Charge = 'CHARGE',
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN',
}

export type TransactionV2CardTypeType = {
  __typename?: 'TransactionV2CardTypeType';
  code?: Maybe<TransactionV2CardType>;
  name?: Maybe<Scalars['String']>;
};

export type TransactionV2Consumer = {
  __typename?: 'TransactionV2Consumer';
  emailAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type TransactionV2Currency = {
  __typename?: 'TransactionV2Currency';
  code: Scalars['String'];
  format: Scalars['String'];
  symbol: Scalars['String'];
};

export type TransactionV2Cursors = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
};

export type TransactionV2Customer = {
  __typename?: 'TransactionV2Customer';
  currency?: Maybe<TransactionV2Currency>;
  id?: Maybe<Scalars['String']>;
};

export type TransactionV2Dcc = {
  __typename?: 'TransactionV2Dcc';
  acceptanceDisclaimer?: Maybe<Scalars['String']>;
  cardholderAmount?: Maybe<Scalars['Int']>;
  cardholderCurrency: Scalars['String'];
  cardholderMinorUnits?: Maybe<Scalars['Int']>;
  exchangeRate: Scalars['String'];
  markupMerchantAmount?: Maybe<Scalars['Int']>;
  markupPercentage?: Maybe<Scalars['String']>;
  markupPercentageOverCentralBank?: Maybe<Scalars['String']>;
  merchantCurrency: Scalars['String'];
  merchantMinorUnits?: Maybe<Scalars['Int']>;
  rateSource?: Maybe<Scalars['String']>;
};

export type TransactionV2F2fRefunded = OperationOutcomeV2 & {
  __typename?: 'TransactionV2F2fRefunded';
  outcome: TransactionV2OperationOutcomeType;
};

export type TransactionV2F2fRefundedResponse = FailedTransactionV2Operation | TransactionV2F2fRefunded;

export type TransactionV2Location = {
  __typename?: 'TransactionV2Location';
  id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export enum TransactionV2OperationOutcomeType {
  Declined = 'DECLINED',
  Failed = 'FAILED',
  Notrequested = 'NOTREQUESTED',
  Successful = 'SUCCESSFUL',
  Unknown = 'UNKNOWN',
}

export enum TransactionV2Outcome {
  Authorised = 'AUTHORISED',
  AwaitingCollection = 'AWAITING_COLLECTION',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  FullyCollected = 'FULLY_COLLECTED',
  Held = 'HELD',
  PartiallyCollected = 'PARTIALLY_COLLECTED',
  Released = 'RELEASED',
  Reversed = 'REVERSED',
  UnderReview = 'UNDER_REVIEW',
}

export type TransactionV2Payment = {
  __typename?: 'TransactionV2Payment';
  addressVerificationService?: Maybe<Scalars['String']>;
  authorisationCode?: Maybe<Scalars['String']>;
  avsResult?: Maybe<Scalars['String']>;
  cardholderVerificationMethod?: Maybe<Scalars['String']>;
  method?: Maybe<TransactionV2PaymentMethod>;
};

export enum TransactionV2PaymentMethod {
  Chip = 'CHIP',
  Contactless = 'CONTACTLESS',
  Ecommerce = 'ECOMMERCE',
  Keyed = 'KEYED',
  PaymentLink = 'PAYMENT_LINK',
  Swipe = 'SWIPE',
  Unknown = 'UNKNOWN',
  VirtualTerminal = 'VIRTUAL_TERMINAL',
}

export type TransactionV2PollInput = {
  cardSchemes?: InputMaybe<Array<TransactionV2CardScheme>>;
  cardTypes?: InputMaybe<Array<TransactionV2CardType>>;
  contactId?: InputMaybe<Scalars['String']>;
  correlationId?: InputMaybe<Scalars['String']>;
  crossTerminalSearch?: InputMaybe<Scalars['Boolean']>;
  cursor?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  dcc?: InputMaybe<Scalars['Boolean']>;
  fromDateTime: Scalars['String'];
  locationIds: Array<Scalars['String']>;
  terminalCursor?: InputMaybe<Scalars['String']>;
  terminalIds?: InputMaybe<Array<Scalars['String']>>;
  timeZone?: InputMaybe<Scalars['String']>;
  toDateTime: Scalars['String'];
  transactionOutcomes?: InputMaybe<Array<TransactionV2Outcome>>;
  transactionSources?: InputMaybe<Array<TransactionV2Source>>;
  transactionTypes?: InputMaybe<Array<TransactionV2Type>>;
};

export type TransactionV2PreAuth = {
  __typename?: 'TransactionV2PreAuth';
  expiryDate?: Maybe<Scalars['String']>;
  parentTransactionId?: Maybe<Scalars['String']>;
  status: TransactionV2PreAuthStatus;
};

export enum TransactionV2PreAuthStatus {
  AwaitingCollection = 'AWAITING_COLLECTION',
  Expired = 'EXPIRED',
  FullyCollected = 'FULLY_COLLECTED',
  PartiallyCollected = 'PARTIALLY_COLLECTED',
  Released = 'RELEASED',
}

export type TransactionV2Refund = {
  __typename?: 'TransactionV2Refund';
  notes?: Maybe<Scalars['String']>;
  parentTransactionId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionV2RefundState>;
};

export enum TransactionV2RefundState {
  Full = 'FULL',
  Partial = 'PARTIAL',
}

export type TransactionV2Reversal = {
  __typename?: 'TransactionV2Reversal';
  reason?: Maybe<Scalars['String']>;
};

export type TransactionV2SearchCursors = {
  __typename?: 'TransactionV2SearchCursors';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type TransactionV2SearchInput = {
  cardSchemes?: InputMaybe<Array<TransactionV2CardScheme>>;
  cardTypes?: InputMaybe<Array<TransactionV2CardType>>;
  contactId?: InputMaybe<Scalars['String']>;
  correlationId?: InputMaybe<Scalars['String']>;
  crossTerminalSearch?: InputMaybe<Scalars['Boolean']>;
  cursors?: InputMaybe<TransactionV2Cursors>;
  customerId?: InputMaybe<Scalars['String']>;
  dcc?: InputMaybe<Scalars['Boolean']>;
  fromDateTime: Scalars['String'];
  locationIds: Array<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  preAuthStatuses?: InputMaybe<Array<TransactionV2PreAuthStatus>>;
  terminalIds?: InputMaybe<Array<Scalars['String']>>;
  timeZone?: InputMaybe<Scalars['String']>;
  toDateTime: Scalars['String'];
  transactionOutcomes?: InputMaybe<Array<TransactionV2Outcome>>;
  transactionSources?: InputMaybe<Array<TransactionV2Source>>;
  transactionTypes?: InputMaybe<Array<TransactionV2Type>>;
  useTerminalCursor?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionV2SearchResult = {
  __typename?: 'TransactionV2SearchResult';
  cursors?: Maybe<TransactionV2SearchCursors>;
  terminalCursor?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<TransactionV2>>;
};

export enum TransactionV2Source {
  CardMachine = 'CARD_MACHINE',
  Ecommerce = 'ECOMMERCE',
  PaymentLink = 'PAYMENT_LINK',
  Tap = 'TAP',
  VirtualTerminal = 'VIRTUAL_TERMINAL',
}

export type TransactionV2Status = {
  __typename?: 'TransactionV2Status';
  isRefundable?: Maybe<Scalars['Boolean']>;
  isReversible?: Maybe<Scalars['Boolean']>;
  outcome?: Maybe<TransactionV2Outcome>;
};

export type TransactionV2Summary = {
  __typename?: 'TransactionV2Summary';
  count: Scalars['Int'];
  total: Scalars['Int'];
};

export type TransactionV2SummaryResult = {
  __typename?: 'TransactionV2SummaryResult';
  balance: TransactionV2Summary;
  cashback: TransactionV2Summary;
  currency: TransactionV2Currency;
  gratuity: TransactionV2Summary;
  gross: TransactionV2Summary;
  refund: TransactionV2Summary;
  sales: TransactionV2Summary;
};

export type TransactionV2Terminal = {
  __typename?: 'TransactionV2Terminal';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export enum TransactionV2Type {
  Collection = 'COLLECTION',
  PreAuth = 'PRE_AUTH',
  Refund = 'REFUND',
  Reversal = 'REVERSAL',
  Sale = 'SALE',
}

export type Transfer = {
  __typename?: 'Transfer';
  amountReleased?: Maybe<Scalars['Float']>;
  amountSafeguarded?: Maybe<Scalars['Float']>;
  currencyIsoCode: Scalars['String'];
  location?: Maybe<Location>;
  locationId: Scalars['String'];
  paymentDateTime: Scalars['String'];
  paymentDueDateTime: Scalars['String'];
  paymentOrderId: Scalars['String'];
  paymentReference?: Maybe<Scalars['String']>;
  paymentRunDateTime: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  totalSettledAmount: Scalars['Float'];
  transactionFrom?: Maybe<Scalars['String']>;
  transactionTo?: Maybe<Scalars['String']>;
  transferFee?: Maybe<Scalars['Float']>;
  transferStatus: TransferStatus;
  transferType: TransferType;
};

export type TransferList = {
  __typename?: 'TransferList';
  cursor?: Maybe<Scalars['String']>;
  hasMorePage: Scalars['Boolean'];
  monthlyAggregates: Array<MonthlyAggregate>;
};

export type TransferSearchInput = {
  customerId: Scalars['String'];
  locationIds: Array<Scalars['String']>;
};

export enum TransferStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Failed = 'Failed',
  FullyReleased = 'FullyReleased',
  NoTransfer = 'NoTransfer',
  PartiallyReleased = 'PartiallyReleased',
  Pending = 'Pending',
  Returned = 'Returned',
  UnderReview = 'UnderReview',
}

export enum TransferType {
  Instant = 'Instant',
  Scheduled = 'Scheduled',
}

export type UpdateCardHolder = {
  billingAddress?: InputMaybe<InputAddress>;
  customerName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<InputAddress>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateCardMachineLocationSupervisorCodeResponse = CardMachineLocationSupervisorCodeUpdated | FailedUpdate;

export type UpdateCardMachineNameResponse = CardMachineNameUpdated | FailedUpdate | TimeoutUpdate;

export type UpdateCardMachineSupervisorCodeResponse = CardMachineSupervisorCodeUpdated | FailedUpdate;

export type UpdateInvoiceLineInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  taxRates?: InputMaybe<Array<InvoiceTaxRateInput>>;
  unitPrice?: InputMaybe<AmountInput>;
  unitQuantity?: InputMaybe<Scalars['Int']>;
};

export type UpdateInvoiceSettingsInput = {
  email?: InputMaybe<Scalars['String']>;
  invoiceNumberPrefix?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateLocationNicknameResponse = FailedLocationNicknameUpdate | LocationNicknameUpdated | TimeoutLocationNicknameUpdate;

export type UpdateProductInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productCategoryId?: InputMaybe<Scalars['ID']>;
  productImages?: InputMaybe<Array<InputMaybe<ProductImageInput>>>;
  unitOfMeasure?: InputMaybe<Scalars['String']>;
};

export type UpdateVatNumberResponse = {
  __typename?: 'UpdateVatNumberResponse';
  vatNumber?: Maybe<Scalars['String']>;
};

export type UpdateWebhookSubscriptionInput = {
  description?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['URL']>;
};

export type UploadFileInput = {
  folderPath: Scalars['String'];
  isPublic?: Scalars['Boolean'];
  newFileName?: InputMaybe<Scalars['String']>;
};

export type ValidationErrorPage = {
  __typename?: 'ValidationErrorPage';
  button?: Maybe<Scalars['Boolean']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
  message?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type ValuePropContent = ValuePropFaqs | ValuePropPricing | ValuePropTestimonials | ValuePropUsps;

export type ValuePropFaqs = {
  __typename?: 'ValuePropFaqs';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

export type ValuePropModule = {
  __typename?: 'ValuePropModule';
  content?: Maybe<Array<Maybe<ValuePropContent>>>;
  title?: Maybe<Scalars['String']>;
  type: ModuleType;
};

export type ValuePropPricing = {
  __typename?: 'ValuePropPricing';
  detail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ValuePropTestimonials = {
  __typename?: 'ValuePropTestimonials';
  customer?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
};

export type ValuePropUsps = {
  __typename?: 'ValuePropUsps';
  detail?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ValuePropViewModel = {
  __typename?: 'ValuePropViewModel';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imagePath?: Maybe<Scalars['String']>;
  imagePath2?: Maybe<Scalars['String']>;
  modules: Array<Maybe<ValuePropModule>>;
  name?: Maybe<Scalars['String']>;
  offer?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productImagePath?: Maybe<Scalars['String']>;
};

export enum VatType {
  Fixed = 'Fixed',
  NonFixed = 'NonFixed',
  NotRegistered = 'NotRegistered',
  Unspecified = 'Unspecified',
}

export type VerificationResult = {
  __typename?: 'VerificationResult';
  accountType?: Maybe<AccountType>;
  createdAt?: Maybe<Scalars['String']>;
  matched?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<ReasonCode>;
  reasonDescription?: Maybe<Scalars['String']>;
};

export enum ViewedState {
  Completed = 'Completed',
  MaybeLater = 'MaybeLater',
  NotViewed = 'NotViewed',
}

export type WebhookEvents = {
  __typename?: 'WebhookEvents';
  events: Array<Scalars['String']>;
  model: Scalars['String'];
};

export type WebhookSubscription = {
  __typename?: 'WebhookSubscription';
  accountId: Scalars['String'];
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  events: Array<Scalars['String']>;
  id: Scalars['String'];
  secrets: Array<WebhookSubscriptionSecret>;
  updatedAt?: Maybe<Scalars['String']>;
  url: Scalars['URL'];
};

export type WebhookSubscriptionSecret = {
  __typename?: 'WebhookSubscriptionSecret';
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type PushPreferences = {
  __typename?: 'pushPreferences';
  preferenceFeedbackAndSurveys: Scalars['Boolean'];
  preferenceOffersAndPromotions: Scalars['Boolean'];
  preferenceServiceAlerts: Scalars['Boolean'];
  preferenceTipsAndNews: Scalars['Boolean'];
};

export type ReportMetaDataResponse = {
  __typename?: 'reportMetaDataResponse';
  lastModifiedDate: Scalars['String'];
};

export type TimeSeriesResponse = {
  __typename?: 'timeSeriesResponse';
  comparisonSnapshots: Array<Snapshot>;
  snapshots: Array<Snapshot>;
};

export type TotalsResponse = {
  __typename?: 'totalsResponse';
  adjustedComparisonEndDate?: Maybe<Scalars['String']>;
  comparisonTotal: Scalars['Float'];
  currentTotal: Scalars['Float'];
  percentageDifference: Scalars['Float'];
};

export type LocationCardMachinesQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;

export type LocationCardMachinesQuery = {
  __typename?: 'Query';
  location?: {
    __typename?: 'Location';
    locationId: string;
    nickname?: string | null;
    tradingName: string;
    tradingAddress?: { __typename?: 'AddressContact'; formattedAddress?: string | null } | null;
    cardMachines?: Array<{
      __typename?: 'CardMachine';
      terminalId: string;
      modelDescription: string;
      modelId?: string | null;
      name: string;
      serialNumber?: string | null;
    }> | null;
  } | null;
};

export type LocationSupervisorCodeUpdateStatusQueryVariables = Exact<{
  locationId: Scalars['ID'];
  statusId: Scalars['String'];
}>;

export type LocationSupervisorCodeUpdateStatusQuery = {
  __typename?: 'Query';
  locationSupervisorCodeUpdateStatus?:
    | {
        __typename?: 'LocationSupervisorCodeStatus';
        status: SupervisorCodeStatus;
        terminals: Array<{ __typename?: 'TerminalSupervisorCodeUpdateStatus'; terminalId: string; status: CardMachineSupervisorCodeUpdatedStatus }>;
      }
    | { __typename?: 'RequestError'; message: string }
    | null;
};

export type UpdateCardMachineLocationSupervisorCodeMutationVariables = Exact<{
  locationId: Scalars['ID'];
  supervisorCode: Scalars['String'];
}>;

export type UpdateCardMachineLocationSupervisorCodeMutation = {
  __typename?: 'Mutation';
  updateCardMachineLocationSupervisorCode:
    | { __typename?: 'CardMachineLocationSupervisorCodeUpdated'; statusId: string }
    | { __typename?: 'FailedUpdate'; message: string };
};

export type CreatePaymentLinkMutationVariables = Exact<{
  locationId: Scalars['ID'];
  params: CreatePaymentLinkInput;
}>;

export type CreatePaymentLinkMutation = {
  __typename?: 'Mutation';
  createPaymentLink: {
    __typename?: 'PaymentLinkSummary';
    paymentIntentId: string;
    type: PaymentType;
    url?: string | null;
    amount: number;
    currency: string;
    status: PaymentLinkStatus;
    description?: string | null;
    createdAt: string;
    updatedAt?: string | null;
    reference?: string | null;
  };
};

export type CreateVirtualTerminalPaymentMutationVariables = Exact<{
  locationId: Scalars['ID'];
  params: CreatePaymentInput;
}>;

export type CreateVirtualTerminalPaymentMutation = {
  __typename?: 'Mutation';
  createVirtualTerminalPayment: {
    __typename?: 'PaymentSummary';
    paymentIntentId: string;
    type: PaymentType;
    currency: string;
    status: PaymentStatus;
    description?: string | null;
    createdAt: string;
    amount: number;
    updatedAt?: string | null;
    url?: string | null;
    source?: string | null;
    reference?: string | null;
  };
};

export type GetInflightAdoptionsQueryVariables = Exact<{
  customerId: Scalars['ID'];
  productId: Scalars['ID'];
}>;

export type GetInflightAdoptionsQuery = {
  __typename?: 'Query';
  getAdoptionsTracking: {
    __typename?: 'TrackingStatusResponse';
    adoptions: Array<{
      __typename?: 'AdoptionTrackingStatusResponse';
      adoptionId: string;
      status: string;
      locations: Array<{ __typename?: 'TrackingStatusLocationResponse'; locationId: string }>;
    }>;
  };
  customerLocations: Array<{ __typename?: 'Location'; locationId: string }>;
};

export type GetProductAdoptionOrderDataQueryVariables = Exact<{
  customerId: Scalars['String'];
  productId: Scalars['ID'];
  marketId: Scalars['String'];
}>;

export type GetProductAdoptionOrderDataQuery = {
  __typename?: 'Query';
  marketplaceProductsV2: Array<{
    __typename?: 'MarketplaceProductV2';
    id: string;
    marketId: string;
    name: string;
    description: string;
    locations?: Array<string> | null;
    parentId?: string | null;
    pricing?: {
      __typename?: 'Pricing';
      serviceFees?: Array<{
        __typename?: 'ServiceFee';
        id: string;
        paymentFrequency?: string | null;
        fixedValue?: { __typename?: 'ChargeableItemValue'; defaultValue?: number | null } | null;
        percentageValue?: { __typename?: 'ChargeableItemValue'; defaultValue?: number | null } | null;
      }> | null;
      transactionSurcharges?: Array<{
        __typename?: 'ChargeableItem';
        id: string;
        fixedValue?: { __typename?: 'ChargeableItemValue'; defaultValue?: number | null } | null;
        percentageValue?: { __typename?: 'ChargeableItemValue'; defaultValue?: number | null } | null;
      }> | null;
    } | null;
  }>;
  getProductQuestionConfigByMarket: {
    __typename?: 'ProductQuestionConfigResponse';
    productId: string;
    configId: string;
    steps: Array<{
      __typename?: 'ProductQuestionStepResponse';
      id: string;
      title: string;
      detail?: string | null;
      image?: string | null;
      analyticsLabel?: string | null;
      analyticsCategory?: string | null;
      questionScope?: string | null;
      questions: Array<{
        __typename?: 'ProductQuestionResponse';
        id: string;
        type: string;
        name: string;
        label: string;
        currencyCode?: string | null;
        options?: Array<{
          __typename?: 'ProductQuestionOptionResponse';
          id: string;
          value: string;
          header?: string | null;
          disabled?: boolean | null;
          description?: string | null;
        }> | null;
        validationRules?: Array<{
          __typename?: 'ProductQuestionValidationRuleResponse';
          id: string;
          validationFunc: string;
          value?: any | null;
          errorMessage?: string | null;
        }> | null;
      }>;
      conditions?: Array<{ __typename?: 'ProductQuestionConditionResponse'; conditionType: string; value?: any | null }> | null;
      validationErrorPage?: {
        __typename?: 'ValidationErrorPage';
        enabled?: boolean | null;
        title?: string | null;
        message?: Array<string> | null;
        button?: boolean | null;
        image?: string | null;
        buttonText?: string | null;
        buttonUrl?: string | null;
      } | null;
    }>;
  };
  getProductQuestionConfigSummaryByMarket: {
    __typename?: 'ProductQuestionConfigSummaryResponse';
    productId: string;
    configId: string;
    feeDisclaimerRequired: boolean;
    eSignatureRequired: boolean;
    summaryDetails?: {
      __typename?: 'ProductQuestionConfigSummaryDetailsResponse';
      id: string;
      description?: string | null;
      image?: string | null;
      previewImage?: string | null;
      confirmationRequired?: boolean | null;
      analyticsCategory?: string | null;
      showCurrentDirectDebitDisclaimer: boolean;
      pricingInfo?: {
        __typename?: 'ProductQuestionConfigSummaryPricingInfoResponse';
        id: string;
        feeType: string;
        feeId: string;
        title?: string | null;
        description?: string | null;
        displayPrice?: string | null;
      } | null;
      additionalPricingInfo?: Array<{
        __typename?: 'ProductQuestionConfigSummaryPricingInfoResponse';
        id: string;
        feeType: string;
        feeId: string;
        title?: string | null;
        description?: string | null;
        displayPrice?: string | null;
      } | null> | null;
      confirmationContent?: {
        __typename?: 'ProductQuestionConfigSummaryConfirmationContentResponse';
        id: string;
        title?: string | null;
        descriptions?: Array<string | null> | null;
        actions?: Array<{
          __typename?: 'ProductQuestionConfigSummaryConfirmationContentActionResponse';
          id: string;
          text?: string | null;
          linkTo?: string | null;
          linkType?: string | null;
          analyticsLabel?: string | null;
          analyticsAction?: string | null;
        } | null> | null;
      } | null;
      addOns?: Array<{
        __typename?: 'AddOnSummaryResponse';
        id: string;
        productId: string;
        pricingInfo?: Array<{
          __typename?: 'ProductQuestionConfigSummaryPricingInfoResponse';
          id: string;
          feeType: string;
          feeId: string;
          title?: string | null;
          description?: string | null;
          displayPrice?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  };
};

export type AccountInformationQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type AccountInformationQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    customerReference: string;
    businessLegalType: string;
    registeredInformation?: {
      __typename?: 'RegisteredInformation';
      registeredName?: string | null;
      registeredAddress?: { __typename?: 'RegisteredAddress'; formattedAddress?: string | null } | null;
    } | null;
  } | null;
};

export type BusinessFundingStateQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type BusinessFundingStateQuery = {
  __typename?: 'Query';
  businessFundingStateV2: {
    __typename?: 'BusinessFundingState';
    customerId: string;
    amount?: number | null;
    currencyIsoCode?: string | null;
    status: BusinessFundingStatus;
    businessFundingUrl?: string | null;
    leadId?: string | null;
    isEligibleForFlexOffers?: boolean | null;
    isEligibleForInstantSigning?: boolean | null;
    minFundingAmount?: number | null;
    maxFundingAmount?: number | null;
    offersExpiryDate?: string | null;
    selectedOfferId?: string | null;
    isPreApproved?: boolean | null;
    offers?: Array<{
      __typename?: 'BusinessFundingOffer';
      offerId: string;
      youWillGet: number;
      youWillRepay: number;
      sweep: number;
      estimatedRepaymentDate?: string | null;
      currencyIsoCode?: string | null;
      order: number;
      fee?: number | null;
    } | null> | null;
  };
};

export type BusinessFundingContractSignedUrlQueryVariables = Exact<{
  customerId: Scalars['ID'];
  leadId: Scalars['String'];
}>;

export type BusinessFundingContractSignedUrlQuery = { __typename?: 'Query'; contractDocument: { __typename?: 'ContractDocumentResponse'; signedUrl: string } };

export type SubmitBusinessFundingApplicationV2MutationVariables = Exact<{
  customerId: Scalars['ID'];
  submitApplicationRequest: SubmitApplicationV2Request;
}>;

export type SubmitBusinessFundingApplicationV2Mutation = {
  __typename?: 'Mutation';
  submitBusinessFundingApplicationV2:
    | { __typename: 'BusinessFundingState'; customerId: string }
    | { __typename: 'SubmitBusinessFundingError'; errorType: SubmitBusinessFundingErrorKind };
};

export type SubmitBusinessFundingApplicationV3MutationVariables = Exact<{
  customerId: Scalars['ID'];
  submitApplicationRequest: SubmitApplicationV2Request;
}>;

export type SubmitBusinessFundingApplicationV3Mutation = {
  __typename?: 'Mutation';
  submitBusinessFundingApplicationV3:
    | { __typename: 'BusinessFundingState'; customerId: string }
    | { __typename: 'SubmitBusinessFundingError'; errorType: SubmitBusinessFundingErrorKind };
};

export type AcceptOfferMutationVariables = Exact<{
  customerId: Scalars['ID'];
  acceptOfferRequest: AcceptOfferRequest;
}>;

export type AcceptOfferMutation = { __typename?: 'Mutation'; acceptOffer?: any | null };

export type SignContractMutationVariables = Exact<{
  customerId: Scalars['ID'];
  leadId: Scalars['String'];
}>;

export type SignContractMutation = { __typename?: 'Mutation'; signContract?: any | null };

export type CancelAccountVerificationAndLeadMutationVariables = Exact<{
  customerId: Scalars['ID'];
  leadId?: InputMaybe<Scalars['String']>;
}>;

export type CancelAccountVerificationAndLeadMutation = { __typename?: 'Mutation'; cancelAccountVerificationAndLead?: any | null };

export type CalculateFlexOffersMutationVariables = Exact<{
  customerId: Scalars['ID'];
  calculateFlexOffersRequest: CalculateFlexOffersRequest;
}>;

export type CalculateFlexOffersMutation = {
  __typename?: 'Mutation';
  calculateFlexOffers?:
    | { __typename: 'BusinessFundingState'; customerId: string }
    | { __typename: 'CalculateFlexOffersError'; errorType: CalculateFlexOffersErrorKind }
    | null;
};

export type CancelBusinessFundingLeadMutationVariables = Exact<{
  customerId: Scalars['ID'];
  leadId: Scalars['String'];
}>;

export type CancelBusinessFundingLeadMutation = { __typename?: 'Mutation'; cancelBusinessFundingLead?: any | null };

export type BusinessFundingAccountVerificationQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type BusinessFundingAccountVerificationQuery = {
  __typename?: 'Query';
  accountVerification:
    | {
        __typename: 'AccountVerification';
        accountVerificationStatus: AccountVerificationStatus;
        accountVerificationType: AccountVerificationType;
        statusChangedDate?: string | null;
        authorisationUrl?: string | null;
        institutionId?: string | null;
        selectedAccountId?: string | null;
        accounts?: Array<{
          __typename?: 'Account';
          accountName: string;
          maskedAccountNumber?: string | null;
          accountId: string;
          inEligibilityReason?: InEligibilityReason | null;
        } | null> | null;
        documents?: Array<{ __typename?: 'BusinessFundingDocument'; id?: string | null; name?: string | null } | null> | null;
      }
    | { __typename: 'AccountVerificationError'; errorType: AccountVerificationErrorKind };
};

export type CreateBusinessFundingAccountVerificationIntentMutationVariables = Exact<{
  customerId: Scalars['ID'];
  institutionId: Scalars['String'];
}>;

export type CreateBusinessFundingAccountVerificationIntentMutation = {
  __typename?: 'Mutation';
  createAccountVerificationIntent: { __typename?: 'InitiateAccountVerification'; authorisationUrl: string };
};

export type InitiateBusinessFundingAccountVerificationMutationVariables = Exact<{
  customerId: Scalars['ID'];
  institutionId: Scalars['String'];
  callbackUrl: Scalars['String'];
}>;

export type InitiateBusinessFundingAccountVerificationMutation = {
  __typename?: 'Mutation';
  initiateAccountVerification: { __typename?: 'InitiateAccountVerification'; authorisationUrl: string };
};

export type RetrieveBusinessFundingAccountTransactionsMutationVariables = Exact<{
  customerId: Scalars['ID'];
  accountId: Scalars['String'];
}>;

export type RetrieveBusinessFundingAccountTransactionsMutation = {
  __typename?: 'Mutation';
  retrieveAccountTransactions: { __typename?: 'AccountVerification'; authorisationUrl?: string | null };
};

export type CancelBusinessFundingAccountVerificationMutationVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type CancelBusinessFundingAccountVerificationMutation = { __typename?: 'Mutation'; cancelAccountVerification?: any | null };

export type InstitutionsQueryVariables = Exact<{ [key: string]: never }>;

export type InstitutionsQuery = {
  __typename?: 'Query';
  institutions: Array<{
    __typename?: 'InstitutionGroup';
    id: string;
    iconUrl: string;
    name: string;
    institutions: Array<{ __typename?: 'Institution'; id: string; iconUrl: string; name: string; fullName: string } | null>;
  }>;
};

export type UploadBusinessFundingDocumentMutationVariables = Exact<{
  customerId: Scalars['ID'];
  file: Scalars['Upload'];
}>;

export type UploadBusinessFundingDocumentMutation = {
  __typename?: 'Mutation';
  uploadBusinessFundingDocument:
    | {
        __typename: 'AccountVerification';
        accountVerificationStatus: AccountVerificationStatus;
        documents?: Array<{ __typename?: 'BusinessFundingDocument'; id?: string | null; name?: string | null } | null> | null;
      }
    | { __typename: 'AccountVerificationError'; errorType: AccountVerificationErrorKind };
};

export type DeleteBusinessFundingDocumentMutationVariables = Exact<{
  customerId: Scalars['ID'];
  documentId: Scalars['String'];
}>;

export type DeleteBusinessFundingDocumentMutation = {
  __typename?: 'Mutation';
  deleteBusinessFundingDocument: { __typename?: 'BusinessFundingDocumentDeletedResponse'; documentId: string };
};

export type CloseTapDeviceMutationVariables = Exact<{
  customerId: Scalars['ID'];
  locationId: Scalars['ID'];
  terminalId: Scalars['ID'];
}>;

export type CloseTapDeviceMutation = {
  __typename?: 'Mutation';
  closeTapDevice:
    | { __typename?: 'FailedCloseTapDevice'; message: string }
    | { __typename?: 'TapDeviceClosed'; terminalId: string; locationId: string; customerId: string };
};

export type ContactInfoQueryVariables = Exact<{ [key: string]: never }>;

export type ContactInfoQuery = {
  __typename?: 'Query';
  contact?: { __typename?: 'Contact'; firstName: string; contactId: string; created?: any | null } | null;
};

export type CreateReceiptTokenMutationVariables = Exact<{
  locationId: Scalars['ID'];
  transactionId: Scalars['ID'];
}>;

export type CreateReceiptTokenMutation = {
  __typename?: 'Mutation';
  createReceiptToken: { __typename?: 'ReceiptTokenResponse'; token?: string | null; receiptUrl?: string | null; expireAt?: string | null };
};

export type ExportCsvMutationVariables = Exact<{
  search: TransactionSearchInput;
}>;

export type ExportCsvMutation = { __typename?: 'Mutation'; exportCsvSigned: { __typename?: 'ExportCsvResponse'; path: string } };

export type GetAdoptionsTrackingQueryVariables = Exact<{
  customerId: Scalars['ID'];
  productId: Scalars['ID'];
  adoptionIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetAdoptionsTrackingQuery = {
  __typename?: 'Query';
  getAdoptionsTracking: {
    __typename?: 'TrackingStatusResponse';
    customerId: string;
    adoptions: Array<{
      __typename?: 'AdoptionTrackingStatusResponse';
      adoptionId: string;
      status: string;
      products: Array<{ __typename?: 'TrackingStatusProductResponse'; productId: string; riskCaseStatus?: string | null }>;
      locations: Array<{
        __typename?: 'TrackingStatusLocationResponse';
        locationId: string;
        tradingName: string;
        products: Array<{ __typename?: 'TrackingStatusProductResponse'; productId: string; riskCaseStatus?: string | null }>;
      }>;
    }>;
  };
};

export type GetAdoptionsTrackingStatusesQueryVariables = Exact<{
  customerId: Scalars['ID'];
  productId: Scalars['ID'];
}>;

export type GetAdoptionsTrackingStatusesQuery = {
  __typename?: 'Query';
  getAdoptionsTracking: {
    __typename?: 'TrackingStatusResponse';
    customerId: string;
    adoptions: Array<{ __typename?: 'AdoptionTrackingStatusResponse'; adoptionId: string; status: string }>;
  };
};

export type GetLocationAddressQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;

export type GetLocationAddressQuery = {
  __typename?: 'Query';
  location?: {
    __typename?: 'Location';
    locationId: string;
    tradingAddress?: { __typename?: 'AddressContact'; formattedAddress?: string | null } | null;
  } | null;
};

export type HomepageTotalTakingsQueryVariables = Exact<{
  search: TransactionPagedSearchInput;
}>;

export type HomepageTotalTakingsQuery = {
  __typename?: 'Query';
  searchTransactions: {
    __typename?: 'TransactionSearchResult';
    searchTotals: { __typename?: 'TransactionSearchTotals'; count: number; netSales: number; currencyCode?: string | null };
  };
};

export type InternalUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type InternalUserInfoQuery = {
  __typename?: 'Query';
  internalUserInfo?: {
    __typename?: 'InternalUser';
    userId: string;
    role: InternalUserRole;
    email: string;
    permissions?: Array<string> | null;
    firstName: string;
    lastName: string;
  } | null;
};

export type LocationsWithCardMachinesQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type LocationsWithCardMachinesQuery = {
  __typename?: 'Query';
  customerLocations: Array<{
    __typename?: 'Location';
    locationId: string;
    tradingName: string;
    nickname?: string | null;
    status: LocationStatus;
    cardMachines?: Array<{
      __typename?: 'CardMachine';
      terminalId: string;
      name: string;
      serialNumber?: string | null;
      status?: CardMachineStatus | null;
      modelId?: string | null;
    }> | null;
    tradingAddress?: { __typename?: 'AddressContact'; formattedAddress?: string | null } | null;
  }>;
};

export type NumberOfCardMachinesQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type NumberOfCardMachinesQuery = {
  __typename?: 'Query';
  customerLocations: Array<{ __typename?: 'Location'; numberOfCardMachines: number; locationId: string }>;
};

export type OnboardingQueryVariables = Exact<{
  customerId: Scalars['String'];
  firstName: Scalars['String'];
  mcc: Scalars['String'];
}>;

export type OnboardingQuery = {
  __typename?: 'Query';
  onboardingInfo?: {
    __typename?: 'Onboarding';
    show?: boolean | null;
    content?: Array<{
      __typename?: 'OnboardingStep';
      title?: string | null;
      description?: string | null;
      icon?: string | null;
      buttonTitle?: string | null;
      image?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateViewedStateMutationVariables = Exact<{
  viewedstate: ViewedState;
}>;

export type UpdateViewedStateMutation = { __typename?: 'Mutation'; updateViewedState?: ViewedState | null };

export type OrderProductMutationVariables = Exact<{
  productOrderInput: ProductOrderInput;
}>;

export type OrderProductMutation = { __typename?: 'Mutation'; orderProduct: { __typename?: 'OrderStatus'; ordered?: boolean | null } };

export type PollTransactionsSearchQueryVariables = Exact<{
  search: PollTransactionSearchInput;
}>;

export type PollTransactionsSearchQuery = {
  __typename?: 'Query';
  pollTransactionsSearch: { __typename?: 'PollTransactionSearchResult'; newTransactions: boolean };
};

export type ProfileInformationQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type ProfileInformationQuery = {
  __typename?: 'Query';
  customerRole?: Role | null;
  customer?: {
    __typename?: 'Customer';
    registeredInformation?: {
      __typename?: 'RegisteredInformation';
      registeredName?: string | null;
      registeredAddress?: { __typename?: 'RegisteredAddress'; formattedAddress?: string | null } | null;
    } | null;
  } | null;
  contact?: {
    __typename?: 'Contact';
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumbers?: Array<{ __typename?: 'PhoneNumber'; internationalCallingCode: string; telephoneNumber: string }> | null;
  } | null;
};

export type QueueBookingEligibilityQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type QueueBookingEligibilityQuery = { __typename?: 'Query'; queueBookingEligibility: boolean };

export type SearchTransactionsQueryVariables = Exact<{
  search: TransactionPagedSearchInput;
}>;

export type SearchTransactionsQuery = {
  __typename?: 'Query';
  searchTransactions: {
    __typename?: 'TransactionSearchResult';
    searchTotals: {
      __typename?: 'TransactionSearchTotals';
      count: number;
      cashback: number;
      netSales: number;
      currencyCode?: string | null;
      cashBackCount: number;
      totalGratuity: number;
      totalRefund: number;
      totalSales: number;
      refundCount: number;
      salesCount: number;
      totalCashback: number;
      gratuityCount: number;
    };
    transactionDateGroups: Array<{
      __typename?: 'TransactionDateGroup';
      date: string;
      netSales: number;
      count: number;
      cashback: number;
      currencyCode?: string | null;
      transactions?: Array<{
        __typename?: 'TransactionSummary';
        transactionId: string;
        locationId: string;
        currency: string;
        amount: number;
        transactionDateTime: string;
        scheme: TransactionScheme;
        timeZone?: string | null;
        displayStatus: {
          __typename?: 'TransactionDisplayStatus';
          type: TransactionType;
          outcome: TransactionOutcome;
          isReversed: boolean;
          refundStatus?: TransactionRefundStatus | null;
          preAuthStatus?: TransactionPreAuthStatus | null;
        };
      }> | null;
    } | null>;
    cursors: { __typename?: 'TransactionSearchCursors'; before?: string | null; after?: string | null };
  };
};

export type SendReceiptMutationVariables = Exact<{
  transactionId: Scalars['ID'];
  locationId: Scalars['ID'];
  emailAddress: Scalars['String'];
}>;

export type SendReceiptMutation = { __typename?: 'Mutation'; sendReceipt?: any | null };

export type ValuePropByMarketQueryVariables = Exact<{
  customerId: Scalars['ID'];
  productId: Scalars['ID'];
  marketId: Scalars['String'];
}>;

export type ValuePropByMarketQuery = {
  __typename?: 'Query';
  valuePropByMarket: {
    __typename?: 'ValuePropViewModel';
    id?: string | null;
    name?: string | null;
    price?: string | null;
    description?: string | null;
    offer?: string | null;
    productImagePath?: string | null;
    imagePath?: string | null;
    imagePath2?: string | null;
    modules: Array<{
      __typename?: 'ValuePropModule';
      type: ModuleType;
      title?: string | null;
      content?: Array<
        | { __typename?: 'ValuePropFaqs'; question?: string | null; answer?: string | null }
        | { __typename?: 'ValuePropPricing'; title?: string | null; detail?: string | null }
        | { __typename?: 'ValuePropTestimonials'; customer?: string | null; quote?: string | null }
        | { __typename?: 'ValuePropUsps'; title?: string | null; icon?: string | null; detail?: string | null }
        | null
      > | null;
    } | null>;
  };
};

export type BrazeApiKeyQueryVariables = Exact<{
  client: BrazeClient;
  marketId: Scalars['ID'];
}>;

export type BrazeApiKeyQuery = { __typename?: 'Query'; brazeApiKey: { __typename?: 'BrazeApiKey'; key: string; brazeApiKeyJWT: string; endpoint: string } };

export type CustomersQueryVariables = Exact<{
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type CustomersQuery = {
  __typename?: 'Query';
  customers: Array<{
    __typename?: 'Customer';
    customerId: string;
    marketId: string;
    customerReference: string;
    isSandbox: boolean;
    registeredInformation?: { __typename?: 'RegisteredInformation'; registeredName?: string | null } | null;
    locations?: Array<{
      __typename?: 'Location';
      currencyCode?: string | null;
      locationId: string;
      tradingName: string;
      nickname?: string | null;
      industry?: { __typename?: 'Industry'; mcc?: string | null; description?: string | null } | null;
      tradingAddress?: { __typename?: 'AddressContact'; formattedAddress?: string | null } | null;
      remotePaymentAppConfig?: {
        __typename?: 'RemotePaymentAppConfig';
        maxPaymentAmount: number;
        paymentLinks: { __typename?: 'RemotePaymentAppConfigPaymentLinks'; enabled: boolean };
        virtualTerminal: { __typename?: 'RemotePaymentAppConfigVirtualTerminal'; enabled: boolean };
        portal: { __typename?: 'RemotePaymentAppConfigPortal'; enabled: boolean };
        payByBank?: { __typename?: 'RemotePaymentAppConfigPayByBank'; enabled?: boolean | null } | null;
        preauth: { __typename?: 'RemotePaymentAppConfigPreauth'; enabled: boolean };
      } | null;
    }> | null;
  }>;
};

export type CustomerQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type CustomerQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    customerId: string;
    marketId: string;
    customerReference: string;
    registeredInformation?: { __typename?: 'RegisteredInformation'; registeredName?: string | null } | null;
    locations?: Array<{
      __typename?: 'Location';
      locationId: string;
      tradingName: string;
      nickname?: string | null;
      remotePaymentAppConfig?: {
        __typename?: 'RemotePaymentAppConfig';
        paymentLinks: { __typename?: 'RemotePaymentAppConfigPaymentLinks'; enabled: boolean };
        portal: { __typename?: 'RemotePaymentAppConfigPortal'; enabled: boolean };
      } | null;
    }> | null;
  } | null;
};

export type MarketplaceProductIdsQueryVariables = Exact<{
  customerDataInput: CustomerDataInput;
}>;

export type MarketplaceProductIdsQuery = { __typename?: 'Query'; marketplaceProductsV2: Array<{ __typename?: 'MarketplaceProductV2'; id: string }> };

export type PendingInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type PendingInvitesQuery = { __typename?: 'Query'; searchInvites: Array<{ __typename?: 'Invite'; inviteId: string }> };

export type AcceptedInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type AcceptedInvitesQuery = {
  __typename?: 'Query';
  searchInvites: Array<{
    __typename?: 'Invite';
    inviteId: string;
    status: InviteStatus;
    customer?: {
      __typename?: 'Customer';
      customerId: string;
      registeredInformation?: { __typename?: 'RegisteredInformation'; registeredName?: string | null } | null;
    } | null;
  }>;
};

export type LinksQueryVariables = Exact<{
  marketId: Scalars['ID'];
}>;

export type LinksQuery = { __typename?: 'Query'; links: Array<{ __typename?: 'DataReferenceValue'; id: string; value: string }> };

export type CustomerRoleQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;

export type CustomerRoleQuery = { __typename?: 'Query'; customerRole?: Role | null };

export type PermissionsQueryVariables = Exact<{
  customerId: Scalars['ID'];
  role: Role;
}>;

export type PermissionsQuery = {
  __typename?: 'Query';
  customerRolePermissions: Array<{ __typename?: 'CustomerRolePermission'; name: string; category: string; title: string; enabled: boolean }>;
};

export type PhoneNumbersQueryVariables = Exact<{
  marketId: Scalars['ID'];
}>;

export type PhoneNumbersQuery = { __typename?: 'Query'; phoneNumbers: Array<{ __typename?: 'DataReferenceValue'; id: string; value: string }> };

export const LocationCardMachinesDocument = gql`
  query locationCardMachines($locationId: ID!) {
    location(locationId: $locationId) {
      locationId
      nickname
      tradingName
      tradingAddress {
        formattedAddress
      }
      cardMachines {
        terminalId
        modelDescription
        modelId
        name
        serialNumber
      }
    }
  }
`;

/**
 * __useLocationCardMachinesQuery__
 *
 * To run a query within a React component, call `useLocationCardMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationCardMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationCardMachinesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationCardMachinesQuery(baseOptions: Apollo.QueryHookOptions<LocationCardMachinesQuery, LocationCardMachinesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationCardMachinesQuery, LocationCardMachinesQueryVariables>(LocationCardMachinesDocument, options);
}
export function useLocationCardMachinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationCardMachinesQuery, LocationCardMachinesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationCardMachinesQuery, LocationCardMachinesQueryVariables>(LocationCardMachinesDocument, options);
}
export type LocationCardMachinesQueryHookResult = ReturnType<typeof useLocationCardMachinesQuery>;
export type LocationCardMachinesLazyQueryHookResult = ReturnType<typeof useLocationCardMachinesLazyQuery>;
export type LocationCardMachinesQueryResult = Apollo.QueryResult<LocationCardMachinesQuery, LocationCardMachinesQueryVariables>;
export const LocationSupervisorCodeUpdateStatusDocument = gql`
  query locationSupervisorCodeUpdateStatus($locationId: ID!, $statusId: String!) {
    locationSupervisorCodeUpdateStatus(locationId: $locationId, statusId: $statusId) {
      ... on LocationSupervisorCodeStatus {
        status
        terminals {
          terminalId
          status
        }
      }
      ... on RequestError {
        message
      }
    }
  }
`;

/**
 * __useLocationSupervisorCodeUpdateStatusQuery__
 *
 * To run a query within a React component, call `useLocationSupervisorCodeUpdateStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSupervisorCodeUpdateStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationSupervisorCodeUpdateStatusQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useLocationSupervisorCodeUpdateStatusQuery(
  baseOptions: Apollo.QueryHookOptions<LocationSupervisorCodeUpdateStatusQuery, LocationSupervisorCodeUpdateStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationSupervisorCodeUpdateStatusQuery, LocationSupervisorCodeUpdateStatusQueryVariables>(
    LocationSupervisorCodeUpdateStatusDocument,
    options
  );
}
export function useLocationSupervisorCodeUpdateStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationSupervisorCodeUpdateStatusQuery, LocationSupervisorCodeUpdateStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationSupervisorCodeUpdateStatusQuery, LocationSupervisorCodeUpdateStatusQueryVariables>(
    LocationSupervisorCodeUpdateStatusDocument,
    options
  );
}
export type LocationSupervisorCodeUpdateStatusQueryHookResult = ReturnType<typeof useLocationSupervisorCodeUpdateStatusQuery>;
export type LocationSupervisorCodeUpdateStatusLazyQueryHookResult = ReturnType<typeof useLocationSupervisorCodeUpdateStatusLazyQuery>;
export type LocationSupervisorCodeUpdateStatusQueryResult = Apollo.QueryResult<
  LocationSupervisorCodeUpdateStatusQuery,
  LocationSupervisorCodeUpdateStatusQueryVariables
>;
export const UpdateCardMachineLocationSupervisorCodeDocument = gql`
  mutation UpdateCardMachineLocationSupervisorCode($locationId: ID!, $supervisorCode: String!) {
    updateCardMachineLocationSupervisorCode(locationId: $locationId, supervisorCode: $supervisorCode) {
      ... on CardMachineLocationSupervisorCodeUpdated {
        statusId
      }
      ... on FailedUpdate {
        message
      }
    }
  }
`;
export type UpdateCardMachineLocationSupervisorCodeMutationFn = Apollo.MutationFunction<
  UpdateCardMachineLocationSupervisorCodeMutation,
  UpdateCardMachineLocationSupervisorCodeMutationVariables
>;

/**
 * __useUpdateCardMachineLocationSupervisorCodeMutation__
 *
 * To run a mutation, you first call `useUpdateCardMachineLocationSupervisorCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMachineLocationSupervisorCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMachineLocationSupervisorCodeMutation, { data, loading, error }] = useUpdateCardMachineLocationSupervisorCodeMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      supervisorCode: // value for 'supervisorCode'
 *   },
 * });
 */
export function useUpdateCardMachineLocationSupervisorCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCardMachineLocationSupervisorCodeMutation, UpdateCardMachineLocationSupervisorCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardMachineLocationSupervisorCodeMutation, UpdateCardMachineLocationSupervisorCodeMutationVariables>(
    UpdateCardMachineLocationSupervisorCodeDocument,
    options
  );
}
export type UpdateCardMachineLocationSupervisorCodeMutationHookResult = ReturnType<typeof useUpdateCardMachineLocationSupervisorCodeMutation>;
export type UpdateCardMachineLocationSupervisorCodeMutationResult = Apollo.MutationResult<UpdateCardMachineLocationSupervisorCodeMutation>;
export type UpdateCardMachineLocationSupervisorCodeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardMachineLocationSupervisorCodeMutation,
  UpdateCardMachineLocationSupervisorCodeMutationVariables
>;
export const CreatePaymentLinkDocument = gql`
  mutation createPaymentLink($locationId: ID!, $params: CreatePaymentLinkInput!) {
    createPaymentLink(locationId: $locationId, params: $params) {
      paymentIntentId
      type
      url
      amount
      currency
      status
      description
      createdAt
      updatedAt
      reference
    }
  }
`;
export type CreatePaymentLinkMutationFn = Apollo.MutationFunction<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>;

/**
 * __useCreatePaymentLinkMutation__
 *
 * To run a mutation, you first call `useCreatePaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentLinkMutation, { data, loading, error }] = useCreatePaymentLinkMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreatePaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>(CreatePaymentLinkDocument, options);
}
export type CreatePaymentLinkMutationHookResult = ReturnType<typeof useCreatePaymentLinkMutation>;
export type CreatePaymentLinkMutationResult = Apollo.MutationResult<CreatePaymentLinkMutation>;
export type CreatePaymentLinkMutationOptions = Apollo.BaseMutationOptions<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>;
export const CreateVirtualTerminalPaymentDocument = gql`
  mutation createVirtualTerminalPayment($locationId: ID!, $params: CreatePaymentInput!) {
    createVirtualTerminalPayment(locationId: $locationId, params: $params) {
      paymentIntentId
      type
      currency
      status
      description
      createdAt
      amount
      updatedAt
      url
      source
      reference
    }
  }
`;
export type CreateVirtualTerminalPaymentMutationFn = Apollo.MutationFunction<
  CreateVirtualTerminalPaymentMutation,
  CreateVirtualTerminalPaymentMutationVariables
>;

/**
 * __useCreateVirtualTerminalPaymentMutation__
 *
 * To run a mutation, you first call `useCreateVirtualTerminalPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVirtualTerminalPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVirtualTerminalPaymentMutation, { data, loading, error }] = useCreateVirtualTerminalPaymentMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateVirtualTerminalPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVirtualTerminalPaymentMutation, CreateVirtualTerminalPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVirtualTerminalPaymentMutation, CreateVirtualTerminalPaymentMutationVariables>(CreateVirtualTerminalPaymentDocument, options);
}
export type CreateVirtualTerminalPaymentMutationHookResult = ReturnType<typeof useCreateVirtualTerminalPaymentMutation>;
export type CreateVirtualTerminalPaymentMutationResult = Apollo.MutationResult<CreateVirtualTerminalPaymentMutation>;
export type CreateVirtualTerminalPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateVirtualTerminalPaymentMutation,
  CreateVirtualTerminalPaymentMutationVariables
>;
export const GetInflightAdoptionsDocument = gql`
  query GetInflightAdoptions($customerId: ID!, $productId: ID!) {
    getAdoptionsTracking(customerId: $customerId, productId: $productId) {
      adoptions {
        adoptionId
        status
        locations {
          locationId
        }
      }
    }
    customerLocations(customerId: $customerId) {
      locationId
    }
  }
`;

/**
 * __useGetInflightAdoptionsQuery__
 *
 * To run a query within a React component, call `useGetInflightAdoptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInflightAdoptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInflightAdoptionsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetInflightAdoptionsQuery(baseOptions: Apollo.QueryHookOptions<GetInflightAdoptionsQuery, GetInflightAdoptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInflightAdoptionsQuery, GetInflightAdoptionsQueryVariables>(GetInflightAdoptionsDocument, options);
}
export function useGetInflightAdoptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInflightAdoptionsQuery, GetInflightAdoptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInflightAdoptionsQuery, GetInflightAdoptionsQueryVariables>(GetInflightAdoptionsDocument, options);
}
export type GetInflightAdoptionsQueryHookResult = ReturnType<typeof useGetInflightAdoptionsQuery>;
export type GetInflightAdoptionsLazyQueryHookResult = ReturnType<typeof useGetInflightAdoptionsLazyQuery>;
export type GetInflightAdoptionsQueryResult = Apollo.QueryResult<GetInflightAdoptionsQuery, GetInflightAdoptionsQueryVariables>;
export const GetProductAdoptionOrderDataDocument = gql`
  query GetProductAdoptionOrderData($customerId: String!, $productId: ID!, $marketId: String!) {
    marketplaceProductsV2(customerDataInput: { customerId: $customerId, productId: $productId }) {
      id
      marketId
      name
      description
      pricing {
        serviceFees {
          id
          paymentFrequency
          fixedValue {
            defaultValue
          }
          percentageValue {
            defaultValue
          }
        }
        transactionSurcharges {
          id
          fixedValue {
            defaultValue
          }
          percentageValue {
            defaultValue
          }
        }
      }
      locations
      parentId
    }
    getProductQuestionConfigByMarket(customerId: $customerId, productId: $productId, marketId: $marketId) {
      productId
      configId
      steps {
        id
        title
        detail
        image
        questions {
          id
          type
          name
          label
          options {
            id
            value
            header
            disabled
            description
          }
          validationRules {
            id
            validationFunc
            value
            errorMessage
          }
          currencyCode
        }
        conditions {
          conditionType
          value
        }
        analyticsLabel
        analyticsCategory
        questionScope
        validationErrorPage {
          enabled
          title
          message
          button
          image
          buttonText
          buttonUrl
        }
      }
    }
    getProductQuestionConfigSummaryByMarket(customerId: $customerId, productId: $productId, marketId: $marketId) {
      productId
      configId
      feeDisclaimerRequired
      eSignatureRequired
      summaryDetails {
        id
        description
        image
        previewImage
        pricingInfo {
          id
          feeType
          feeId
          title
          description
          displayPrice
        }
        additionalPricingInfo {
          id
          feeType
          feeId
          title
          description
          displayPrice
        }
        confirmationRequired
        confirmationContent {
          id
          title
          descriptions
          actions {
            id
            text
            linkTo
            linkType
            analyticsLabel
            analyticsAction
          }
        }
        analyticsCategory
        addOns {
          id
          productId
          pricingInfo {
            id
            feeType
            feeId
            title
            description
            displayPrice
          }
        }
        showCurrentDirectDebitDisclaimer
      }
    }
  }
`;

/**
 * __useGetProductAdoptionOrderDataQuery__
 *
 * To run a query within a React component, call `useGetProductAdoptionOrderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAdoptionOrderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAdoptionOrderDataQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      productId: // value for 'productId'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useGetProductAdoptionOrderDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductAdoptionOrderDataQuery, GetProductAdoptionOrderDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductAdoptionOrderDataQuery, GetProductAdoptionOrderDataQueryVariables>(GetProductAdoptionOrderDataDocument, options);
}
export function useGetProductAdoptionOrderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductAdoptionOrderDataQuery, GetProductAdoptionOrderDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductAdoptionOrderDataQuery, GetProductAdoptionOrderDataQueryVariables>(GetProductAdoptionOrderDataDocument, options);
}
export type GetProductAdoptionOrderDataQueryHookResult = ReturnType<typeof useGetProductAdoptionOrderDataQuery>;
export type GetProductAdoptionOrderDataLazyQueryHookResult = ReturnType<typeof useGetProductAdoptionOrderDataLazyQuery>;
export type GetProductAdoptionOrderDataQueryResult = Apollo.QueryResult<GetProductAdoptionOrderDataQuery, GetProductAdoptionOrderDataQueryVariables>;
export const AccountInformationDocument = gql`
  query AccountInformation($customerId: ID!) {
    customer(customerId: $customerId) {
      customerReference
      businessLegalType
      registeredInformation {
        registeredName
        registeredAddress {
          formattedAddress
        }
      }
    }
  }
`;

/**
 * __useAccountInformationQuery__
 *
 * To run a query within a React component, call `useAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountInformationQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAccountInformationQuery(baseOptions: Apollo.QueryHookOptions<AccountInformationQuery, AccountInformationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountInformationQuery, AccountInformationQueryVariables>(AccountInformationDocument, options);
}
export function useAccountInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountInformationQuery, AccountInformationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountInformationQuery, AccountInformationQueryVariables>(AccountInformationDocument, options);
}
export type AccountInformationQueryHookResult = ReturnType<typeof useAccountInformationQuery>;
export type AccountInformationLazyQueryHookResult = ReturnType<typeof useAccountInformationLazyQuery>;
export type AccountInformationQueryResult = Apollo.QueryResult<AccountInformationQuery, AccountInformationQueryVariables>;
export const BusinessFundingStateDocument = gql`
  query businessFundingState($customerId: ID!) {
    businessFundingStateV2(customerId: $customerId) {
      customerId
      amount
      currencyIsoCode
      status
      businessFundingUrl
      leadId
      isEligibleForFlexOffers
      isEligibleForInstantSigning
      minFundingAmount
      maxFundingAmount
      offers {
        offerId
        youWillGet
        youWillRepay
        sweep
        estimatedRepaymentDate
        currencyIsoCode
        order
        fee
      }
      offersExpiryDate
      selectedOfferId
      isPreApproved
    }
  }
`;

/**
 * __useBusinessFundingStateQuery__
 *
 * To run a query within a React component, call `useBusinessFundingStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessFundingStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessFundingStateQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useBusinessFundingStateQuery(baseOptions: Apollo.QueryHookOptions<BusinessFundingStateQuery, BusinessFundingStateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessFundingStateQuery, BusinessFundingStateQueryVariables>(BusinessFundingStateDocument, options);
}
export function useBusinessFundingStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessFundingStateQuery, BusinessFundingStateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessFundingStateQuery, BusinessFundingStateQueryVariables>(BusinessFundingStateDocument, options);
}
export type BusinessFundingStateQueryHookResult = ReturnType<typeof useBusinessFundingStateQuery>;
export type BusinessFundingStateLazyQueryHookResult = ReturnType<typeof useBusinessFundingStateLazyQuery>;
export type BusinessFundingStateQueryResult = Apollo.QueryResult<BusinessFundingStateQuery, BusinessFundingStateQueryVariables>;
export const BusinessFundingContractSignedUrlDocument = gql`
  query businessFundingContractSignedUrl($customerId: ID!, $leadId: String!) {
    contractDocument(customerId: $customerId, leadId: $leadId) {
      signedUrl
    }
  }
`;

/**
 * __useBusinessFundingContractSignedUrlQuery__
 *
 * To run a query within a React component, call `useBusinessFundingContractSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessFundingContractSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessFundingContractSignedUrlQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useBusinessFundingContractSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessFundingContractSignedUrlQuery, BusinessFundingContractSignedUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessFundingContractSignedUrlQuery, BusinessFundingContractSignedUrlQueryVariables>(
    BusinessFundingContractSignedUrlDocument,
    options
  );
}
export function useBusinessFundingContractSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessFundingContractSignedUrlQuery, BusinessFundingContractSignedUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessFundingContractSignedUrlQuery, BusinessFundingContractSignedUrlQueryVariables>(
    BusinessFundingContractSignedUrlDocument,
    options
  );
}
export type BusinessFundingContractSignedUrlQueryHookResult = ReturnType<typeof useBusinessFundingContractSignedUrlQuery>;
export type BusinessFundingContractSignedUrlLazyQueryHookResult = ReturnType<typeof useBusinessFundingContractSignedUrlLazyQuery>;
export type BusinessFundingContractSignedUrlQueryResult = Apollo.QueryResult<
  BusinessFundingContractSignedUrlQuery,
  BusinessFundingContractSignedUrlQueryVariables
>;
export const SubmitBusinessFundingApplicationV2Document = gql`
  mutation submitBusinessFundingApplicationV2($customerId: ID!, $submitApplicationRequest: SubmitApplicationV2Request!) {
    submitBusinessFundingApplicationV2(customerId: $customerId, submitApplicationRequest: $submitApplicationRequest) {
      __typename
      ... on BusinessFundingState {
        customerId
      }
      ... on SubmitBusinessFundingError {
        errorType
      }
    }
  }
`;
export type SubmitBusinessFundingApplicationV2MutationFn = Apollo.MutationFunction<
  SubmitBusinessFundingApplicationV2Mutation,
  SubmitBusinessFundingApplicationV2MutationVariables
>;

/**
 * __useSubmitBusinessFundingApplicationV2Mutation__
 *
 * To run a mutation, you first call `useSubmitBusinessFundingApplicationV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBusinessFundingApplicationV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBusinessFundingApplicationV2Mutation, { data, loading, error }] = useSubmitBusinessFundingApplicationV2Mutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      submitApplicationRequest: // value for 'submitApplicationRequest'
 *   },
 * });
 */
export function useSubmitBusinessFundingApplicationV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitBusinessFundingApplicationV2Mutation, SubmitBusinessFundingApplicationV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitBusinessFundingApplicationV2Mutation, SubmitBusinessFundingApplicationV2MutationVariables>(
    SubmitBusinessFundingApplicationV2Document,
    options
  );
}
export type SubmitBusinessFundingApplicationV2MutationHookResult = ReturnType<typeof useSubmitBusinessFundingApplicationV2Mutation>;
export type SubmitBusinessFundingApplicationV2MutationResult = Apollo.MutationResult<SubmitBusinessFundingApplicationV2Mutation>;
export type SubmitBusinessFundingApplicationV2MutationOptions = Apollo.BaseMutationOptions<
  SubmitBusinessFundingApplicationV2Mutation,
  SubmitBusinessFundingApplicationV2MutationVariables
>;
export const SubmitBusinessFundingApplicationV3Document = gql`
  mutation submitBusinessFundingApplicationV3($customerId: ID!, $submitApplicationRequest: SubmitApplicationV2Request!) {
    submitBusinessFundingApplicationV3(customerId: $customerId, submitApplicationRequest: $submitApplicationRequest) {
      __typename
      ... on BusinessFundingState {
        customerId
      }
      ... on SubmitBusinessFundingError {
        errorType
      }
    }
  }
`;
export type SubmitBusinessFundingApplicationV3MutationFn = Apollo.MutationFunction<
  SubmitBusinessFundingApplicationV3Mutation,
  SubmitBusinessFundingApplicationV3MutationVariables
>;

/**
 * __useSubmitBusinessFundingApplicationV3Mutation__
 *
 * To run a mutation, you first call `useSubmitBusinessFundingApplicationV3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBusinessFundingApplicationV3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBusinessFundingApplicationV3Mutation, { data, loading, error }] = useSubmitBusinessFundingApplicationV3Mutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      submitApplicationRequest: // value for 'submitApplicationRequest'
 *   },
 * });
 */
export function useSubmitBusinessFundingApplicationV3Mutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitBusinessFundingApplicationV3Mutation, SubmitBusinessFundingApplicationV3MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitBusinessFundingApplicationV3Mutation, SubmitBusinessFundingApplicationV3MutationVariables>(
    SubmitBusinessFundingApplicationV3Document,
    options
  );
}
export type SubmitBusinessFundingApplicationV3MutationHookResult = ReturnType<typeof useSubmitBusinessFundingApplicationV3Mutation>;
export type SubmitBusinessFundingApplicationV3MutationResult = Apollo.MutationResult<SubmitBusinessFundingApplicationV3Mutation>;
export type SubmitBusinessFundingApplicationV3MutationOptions = Apollo.BaseMutationOptions<
  SubmitBusinessFundingApplicationV3Mutation,
  SubmitBusinessFundingApplicationV3MutationVariables
>;
export const AcceptOfferDocument = gql`
  mutation AcceptOffer($customerId: ID!, $acceptOfferRequest: AcceptOfferRequest!) {
    acceptOffer(customerId: $customerId, acceptOfferRequest: $acceptOfferRequest)
  }
`;
export type AcceptOfferMutationFn = Apollo.MutationFunction<AcceptOfferMutation, AcceptOfferMutationVariables>;

/**
 * __useAcceptOfferMutation__
 *
 * To run a mutation, you first call `useAcceptOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOfferMutation, { data, loading, error }] = useAcceptOfferMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      acceptOfferRequest: // value for 'acceptOfferRequest'
 *   },
 * });
 */
export function useAcceptOfferMutation(baseOptions?: Apollo.MutationHookOptions<AcceptOfferMutation, AcceptOfferMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptOfferMutation, AcceptOfferMutationVariables>(AcceptOfferDocument, options);
}
export type AcceptOfferMutationHookResult = ReturnType<typeof useAcceptOfferMutation>;
export type AcceptOfferMutationResult = Apollo.MutationResult<AcceptOfferMutation>;
export type AcceptOfferMutationOptions = Apollo.BaseMutationOptions<AcceptOfferMutation, AcceptOfferMutationVariables>;
export const SignContractDocument = gql`
  mutation SignContract($customerId: ID!, $leadId: String!) {
    signContract(customerId: $customerId, leadId: $leadId)
  }
`;
export type SignContractMutationFn = Apollo.MutationFunction<SignContractMutation, SignContractMutationVariables>;

/**
 * __useSignContractMutation__
 *
 * To run a mutation, you first call `useSignContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signContractMutation, { data, loading, error }] = useSignContractMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useSignContractMutation(baseOptions?: Apollo.MutationHookOptions<SignContractMutation, SignContractMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignContractMutation, SignContractMutationVariables>(SignContractDocument, options);
}
export type SignContractMutationHookResult = ReturnType<typeof useSignContractMutation>;
export type SignContractMutationResult = Apollo.MutationResult<SignContractMutation>;
export type SignContractMutationOptions = Apollo.BaseMutationOptions<SignContractMutation, SignContractMutationVariables>;
export const CancelAccountVerificationAndLeadDocument = gql`
  mutation CancelAccountVerificationAndLead($customerId: ID!, $leadId: String) {
    cancelAccountVerificationAndLead(customerId: $customerId, leadId: $leadId)
  }
`;
export type CancelAccountVerificationAndLeadMutationFn = Apollo.MutationFunction<
  CancelAccountVerificationAndLeadMutation,
  CancelAccountVerificationAndLeadMutationVariables
>;

/**
 * __useCancelAccountVerificationAndLeadMutation__
 *
 * To run a mutation, you first call `useCancelAccountVerificationAndLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAccountVerificationAndLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAccountVerificationAndLeadMutation, { data, loading, error }] = useCancelAccountVerificationAndLeadMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useCancelAccountVerificationAndLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelAccountVerificationAndLeadMutation, CancelAccountVerificationAndLeadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelAccountVerificationAndLeadMutation, CancelAccountVerificationAndLeadMutationVariables>(
    CancelAccountVerificationAndLeadDocument,
    options
  );
}
export type CancelAccountVerificationAndLeadMutationHookResult = ReturnType<typeof useCancelAccountVerificationAndLeadMutation>;
export type CancelAccountVerificationAndLeadMutationResult = Apollo.MutationResult<CancelAccountVerificationAndLeadMutation>;
export type CancelAccountVerificationAndLeadMutationOptions = Apollo.BaseMutationOptions<
  CancelAccountVerificationAndLeadMutation,
  CancelAccountVerificationAndLeadMutationVariables
>;
export const CalculateFlexOffersDocument = gql`
  mutation calculateFlexOffers($customerId: ID!, $calculateFlexOffersRequest: CalculateFlexOffersRequest!) {
    calculateFlexOffers(customerId: $customerId, calculateFlexOffersRequest: $calculateFlexOffersRequest) {
      __typename
      ... on BusinessFundingState {
        customerId
      }
      ... on CalculateFlexOffersError {
        errorType
      }
    }
  }
`;
export type CalculateFlexOffersMutationFn = Apollo.MutationFunction<CalculateFlexOffersMutation, CalculateFlexOffersMutationVariables>;

/**
 * __useCalculateFlexOffersMutation__
 *
 * To run a mutation, you first call `useCalculateFlexOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateFlexOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateFlexOffersMutation, { data, loading, error }] = useCalculateFlexOffersMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      calculateFlexOffersRequest: // value for 'calculateFlexOffersRequest'
 *   },
 * });
 */
export function useCalculateFlexOffersMutation(baseOptions?: Apollo.MutationHookOptions<CalculateFlexOffersMutation, CalculateFlexOffersMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CalculateFlexOffersMutation, CalculateFlexOffersMutationVariables>(CalculateFlexOffersDocument, options);
}
export type CalculateFlexOffersMutationHookResult = ReturnType<typeof useCalculateFlexOffersMutation>;
export type CalculateFlexOffersMutationResult = Apollo.MutationResult<CalculateFlexOffersMutation>;
export type CalculateFlexOffersMutationOptions = Apollo.BaseMutationOptions<CalculateFlexOffersMutation, CalculateFlexOffersMutationVariables>;
export const CancelBusinessFundingLeadDocument = gql`
  mutation cancelBusinessFundingLead($customerId: ID!, $leadId: String!) {
    cancelBusinessFundingLead(customerId: $customerId, leadId: $leadId)
  }
`;
export type CancelBusinessFundingLeadMutationFn = Apollo.MutationFunction<CancelBusinessFundingLeadMutation, CancelBusinessFundingLeadMutationVariables>;

/**
 * __useCancelBusinessFundingLeadMutation__
 *
 * To run a mutation, you first call `useCancelBusinessFundingLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBusinessFundingLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBusinessFundingLeadMutation, { data, loading, error }] = useCancelBusinessFundingLeadMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useCancelBusinessFundingLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelBusinessFundingLeadMutation, CancelBusinessFundingLeadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelBusinessFundingLeadMutation, CancelBusinessFundingLeadMutationVariables>(CancelBusinessFundingLeadDocument, options);
}
export type CancelBusinessFundingLeadMutationHookResult = ReturnType<typeof useCancelBusinessFundingLeadMutation>;
export type CancelBusinessFundingLeadMutationResult = Apollo.MutationResult<CancelBusinessFundingLeadMutation>;
export type CancelBusinessFundingLeadMutationOptions = Apollo.BaseMutationOptions<
  CancelBusinessFundingLeadMutation,
  CancelBusinessFundingLeadMutationVariables
>;
export const BusinessFundingAccountVerificationDocument = gql`
  query businessFundingAccountVerification($customerId: ID!) {
    accountVerification(customerId: $customerId) {
      __typename
      ... on AccountVerification {
        accountVerificationStatus
        accountVerificationType
        statusChangedDate
        authorisationUrl
        institutionId
        selectedAccountId
        accounts {
          accountName
          maskedAccountNumber
          accountId
          inEligibilityReason
        }
        documents {
          id
          name
        }
      }
      ... on AccountVerificationError {
        errorType
      }
    }
  }
`;

/**
 * __useBusinessFundingAccountVerificationQuery__
 *
 * To run a query within a React component, call `useBusinessFundingAccountVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessFundingAccountVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessFundingAccountVerificationQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useBusinessFundingAccountVerificationQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessFundingAccountVerificationQuery, BusinessFundingAccountVerificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessFundingAccountVerificationQuery, BusinessFundingAccountVerificationQueryVariables>(
    BusinessFundingAccountVerificationDocument,
    options
  );
}
export function useBusinessFundingAccountVerificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessFundingAccountVerificationQuery, BusinessFundingAccountVerificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessFundingAccountVerificationQuery, BusinessFundingAccountVerificationQueryVariables>(
    BusinessFundingAccountVerificationDocument,
    options
  );
}
export type BusinessFundingAccountVerificationQueryHookResult = ReturnType<typeof useBusinessFundingAccountVerificationQuery>;
export type BusinessFundingAccountVerificationLazyQueryHookResult = ReturnType<typeof useBusinessFundingAccountVerificationLazyQuery>;
export type BusinessFundingAccountVerificationQueryResult = Apollo.QueryResult<
  BusinessFundingAccountVerificationQuery,
  BusinessFundingAccountVerificationQueryVariables
>;
export const CreateBusinessFundingAccountVerificationIntentDocument = gql`
  mutation createBusinessFundingAccountVerificationIntent($customerId: ID!, $institutionId: String!) {
    createAccountVerificationIntent(customerId: $customerId, institutionId: $institutionId) {
      authorisationUrl
    }
  }
`;
export type CreateBusinessFundingAccountVerificationIntentMutationFn = Apollo.MutationFunction<
  CreateBusinessFundingAccountVerificationIntentMutation,
  CreateBusinessFundingAccountVerificationIntentMutationVariables
>;

/**
 * __useCreateBusinessFundingAccountVerificationIntentMutation__
 *
 * To run a mutation, you first call `useCreateBusinessFundingAccountVerificationIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessFundingAccountVerificationIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessFundingAccountVerificationIntentMutation, { data, loading, error }] = useCreateBusinessFundingAccountVerificationIntentMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useCreateBusinessFundingAccountVerificationIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessFundingAccountVerificationIntentMutation,
    CreateBusinessFundingAccountVerificationIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBusinessFundingAccountVerificationIntentMutation, CreateBusinessFundingAccountVerificationIntentMutationVariables>(
    CreateBusinessFundingAccountVerificationIntentDocument,
    options
  );
}
export type CreateBusinessFundingAccountVerificationIntentMutationHookResult = ReturnType<typeof useCreateBusinessFundingAccountVerificationIntentMutation>;
export type CreateBusinessFundingAccountVerificationIntentMutationResult = Apollo.MutationResult<CreateBusinessFundingAccountVerificationIntentMutation>;
export type CreateBusinessFundingAccountVerificationIntentMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessFundingAccountVerificationIntentMutation,
  CreateBusinessFundingAccountVerificationIntentMutationVariables
>;
export const InitiateBusinessFundingAccountVerificationDocument = gql`
  mutation initiateBusinessFundingAccountVerification($customerId: ID!, $institutionId: String!, $callbackUrl: String!) {
    initiateAccountVerification(customerId: $customerId, institutionId: $institutionId, callbackUrl: $callbackUrl) {
      authorisationUrl
    }
  }
`;
export type InitiateBusinessFundingAccountVerificationMutationFn = Apollo.MutationFunction<
  InitiateBusinessFundingAccountVerificationMutation,
  InitiateBusinessFundingAccountVerificationMutationVariables
>;

/**
 * __useInitiateBusinessFundingAccountVerificationMutation__
 *
 * To run a mutation, you first call `useInitiateBusinessFundingAccountVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateBusinessFundingAccountVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateBusinessFundingAccountVerificationMutation, { data, loading, error }] = useInitiateBusinessFundingAccountVerificationMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      institutionId: // value for 'institutionId'
 *      callbackUrl: // value for 'callbackUrl'
 *   },
 * });
 */
export function useInitiateBusinessFundingAccountVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<InitiateBusinessFundingAccountVerificationMutation, InitiateBusinessFundingAccountVerificationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitiateBusinessFundingAccountVerificationMutation, InitiateBusinessFundingAccountVerificationMutationVariables>(
    InitiateBusinessFundingAccountVerificationDocument,
    options
  );
}
export type InitiateBusinessFundingAccountVerificationMutationHookResult = ReturnType<typeof useInitiateBusinessFundingAccountVerificationMutation>;
export type InitiateBusinessFundingAccountVerificationMutationResult = Apollo.MutationResult<InitiateBusinessFundingAccountVerificationMutation>;
export type InitiateBusinessFundingAccountVerificationMutationOptions = Apollo.BaseMutationOptions<
  InitiateBusinessFundingAccountVerificationMutation,
  InitiateBusinessFundingAccountVerificationMutationVariables
>;
export const RetrieveBusinessFundingAccountTransactionsDocument = gql`
  mutation retrieveBusinessFundingAccountTransactions($customerId: ID!, $accountId: String!) {
    retrieveAccountTransactions(customerId: $customerId, accountId: $accountId) {
      authorisationUrl
    }
  }
`;
export type RetrieveBusinessFundingAccountTransactionsMutationFn = Apollo.MutationFunction<
  RetrieveBusinessFundingAccountTransactionsMutation,
  RetrieveBusinessFundingAccountTransactionsMutationVariables
>;

/**
 * __useRetrieveBusinessFundingAccountTransactionsMutation__
 *
 * To run a mutation, you first call `useRetrieveBusinessFundingAccountTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveBusinessFundingAccountTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveBusinessFundingAccountTransactionsMutation, { data, loading, error }] = useRetrieveBusinessFundingAccountTransactionsMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRetrieveBusinessFundingAccountTransactionsMutation(
  baseOptions?: Apollo.MutationHookOptions<RetrieveBusinessFundingAccountTransactionsMutation, RetrieveBusinessFundingAccountTransactionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RetrieveBusinessFundingAccountTransactionsMutation, RetrieveBusinessFundingAccountTransactionsMutationVariables>(
    RetrieveBusinessFundingAccountTransactionsDocument,
    options
  );
}
export type RetrieveBusinessFundingAccountTransactionsMutationHookResult = ReturnType<typeof useRetrieveBusinessFundingAccountTransactionsMutation>;
export type RetrieveBusinessFundingAccountTransactionsMutationResult = Apollo.MutationResult<RetrieveBusinessFundingAccountTransactionsMutation>;
export type RetrieveBusinessFundingAccountTransactionsMutationOptions = Apollo.BaseMutationOptions<
  RetrieveBusinessFundingAccountTransactionsMutation,
  RetrieveBusinessFundingAccountTransactionsMutationVariables
>;
export const CancelBusinessFundingAccountVerificationDocument = gql`
  mutation cancelBusinessFundingAccountVerification($customerId: ID!) {
    cancelAccountVerification(customerId: $customerId)
  }
`;
export type CancelBusinessFundingAccountVerificationMutationFn = Apollo.MutationFunction<
  CancelBusinessFundingAccountVerificationMutation,
  CancelBusinessFundingAccountVerificationMutationVariables
>;

/**
 * __useCancelBusinessFundingAccountVerificationMutation__
 *
 * To run a mutation, you first call `useCancelBusinessFundingAccountVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBusinessFundingAccountVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBusinessFundingAccountVerificationMutation, { data, loading, error }] = useCancelBusinessFundingAccountVerificationMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCancelBusinessFundingAccountVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelBusinessFundingAccountVerificationMutation, CancelBusinessFundingAccountVerificationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelBusinessFundingAccountVerificationMutation, CancelBusinessFundingAccountVerificationMutationVariables>(
    CancelBusinessFundingAccountVerificationDocument,
    options
  );
}
export type CancelBusinessFundingAccountVerificationMutationHookResult = ReturnType<typeof useCancelBusinessFundingAccountVerificationMutation>;
export type CancelBusinessFundingAccountVerificationMutationResult = Apollo.MutationResult<CancelBusinessFundingAccountVerificationMutation>;
export type CancelBusinessFundingAccountVerificationMutationOptions = Apollo.BaseMutationOptions<
  CancelBusinessFundingAccountVerificationMutation,
  CancelBusinessFundingAccountVerificationMutationVariables
>;
export const InstitutionsDocument = gql`
  query institutions {
    institutions {
      id
      iconUrl
      name
      institutions {
        id
        iconUrl
        name
        fullName
      }
    }
  }
`;

/**
 * __useInstitutionsQuery__
 *
 * To run a query within a React component, call `useInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstitutionsQuery(baseOptions?: Apollo.QueryHookOptions<InstitutionsQuery, InstitutionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstitutionsQuery, InstitutionsQueryVariables>(InstitutionsDocument, options);
}
export function useInstitutionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstitutionsQuery, InstitutionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstitutionsQuery, InstitutionsQueryVariables>(InstitutionsDocument, options);
}
export type InstitutionsQueryHookResult = ReturnType<typeof useInstitutionsQuery>;
export type InstitutionsLazyQueryHookResult = ReturnType<typeof useInstitutionsLazyQuery>;
export type InstitutionsQueryResult = Apollo.QueryResult<InstitutionsQuery, InstitutionsQueryVariables>;
export const UploadBusinessFundingDocumentDocument = gql`
  mutation uploadBusinessFundingDocument($customerId: ID!, $file: Upload!) {
    uploadBusinessFundingDocument(customerId: $customerId, file: $file) {
      __typename
      ... on AccountVerification {
        accountVerificationStatus
        documents {
          id
          name
        }
      }
      ... on AccountVerificationError {
        errorType
      }
    }
  }
`;
export type UploadBusinessFundingDocumentMutationFn = Apollo.MutationFunction<
  UploadBusinessFundingDocumentMutation,
  UploadBusinessFundingDocumentMutationVariables
>;

/**
 * __useUploadBusinessFundingDocumentMutation__
 *
 * To run a mutation, you first call `useUploadBusinessFundingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBusinessFundingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBusinessFundingDocumentMutation, { data, loading, error }] = useUploadBusinessFundingDocumentMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadBusinessFundingDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadBusinessFundingDocumentMutation, UploadBusinessFundingDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadBusinessFundingDocumentMutation, UploadBusinessFundingDocumentMutationVariables>(
    UploadBusinessFundingDocumentDocument,
    options
  );
}
export type UploadBusinessFundingDocumentMutationHookResult = ReturnType<typeof useUploadBusinessFundingDocumentMutation>;
export type UploadBusinessFundingDocumentMutationResult = Apollo.MutationResult<UploadBusinessFundingDocumentMutation>;
export type UploadBusinessFundingDocumentMutationOptions = Apollo.BaseMutationOptions<
  UploadBusinessFundingDocumentMutation,
  UploadBusinessFundingDocumentMutationVariables
>;
export const DeleteBusinessFundingDocumentDocument = gql`
  mutation deleteBusinessFundingDocument($customerId: ID!, $documentId: String!) {
    deleteBusinessFundingDocument(customerId: $customerId, documentId: $documentId) {
      documentId
    }
  }
`;
export type DeleteBusinessFundingDocumentMutationFn = Apollo.MutationFunction<
  DeleteBusinessFundingDocumentMutation,
  DeleteBusinessFundingDocumentMutationVariables
>;

/**
 * __useDeleteBusinessFundingDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessFundingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessFundingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessFundingDocumentMutation, { data, loading, error }] = useDeleteBusinessFundingDocumentMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteBusinessFundingDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBusinessFundingDocumentMutation, DeleteBusinessFundingDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBusinessFundingDocumentMutation, DeleteBusinessFundingDocumentMutationVariables>(
    DeleteBusinessFundingDocumentDocument,
    options
  );
}
export type DeleteBusinessFundingDocumentMutationHookResult = ReturnType<typeof useDeleteBusinessFundingDocumentMutation>;
export type DeleteBusinessFundingDocumentMutationResult = Apollo.MutationResult<DeleteBusinessFundingDocumentMutation>;
export type DeleteBusinessFundingDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteBusinessFundingDocumentMutation,
  DeleteBusinessFundingDocumentMutationVariables
>;
export const CloseTapDeviceDocument = gql`
  mutation CloseTapDevice($customerId: ID!, $locationId: ID!, $terminalId: ID!) {
    closeTapDevice(customerId: $customerId, locationId: $locationId, terminalId: $terminalId) {
      ... on TapDeviceClosed {
        terminalId
        locationId
        customerId
      }
      ... on FailedCloseTapDevice {
        message
      }
    }
  }
`;
export type CloseTapDeviceMutationFn = Apollo.MutationFunction<CloseTapDeviceMutation, CloseTapDeviceMutationVariables>;

/**
 * __useCloseTapDeviceMutation__
 *
 * To run a mutation, you first call `useCloseTapDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTapDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTapDeviceMutation, { data, loading, error }] = useCloseTapDeviceMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      locationId: // value for 'locationId'
 *      terminalId: // value for 'terminalId'
 *   },
 * });
 */
export function useCloseTapDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CloseTapDeviceMutation, CloseTapDeviceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseTapDeviceMutation, CloseTapDeviceMutationVariables>(CloseTapDeviceDocument, options);
}
export type CloseTapDeviceMutationHookResult = ReturnType<typeof useCloseTapDeviceMutation>;
export type CloseTapDeviceMutationResult = Apollo.MutationResult<CloseTapDeviceMutation>;
export type CloseTapDeviceMutationOptions = Apollo.BaseMutationOptions<CloseTapDeviceMutation, CloseTapDeviceMutationVariables>;
export const ContactInfoDocument = gql`
  query ContactInfo {
    contact {
      firstName
      contactId
      created
    }
  }
`;

/**
 * __useContactInfoQuery__
 *
 * To run a query within a React component, call `useContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactInfoQuery(baseOptions?: Apollo.QueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, options);
}
export function useContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, options);
}
export type ContactInfoQueryHookResult = ReturnType<typeof useContactInfoQuery>;
export type ContactInfoLazyQueryHookResult = ReturnType<typeof useContactInfoLazyQuery>;
export type ContactInfoQueryResult = Apollo.QueryResult<ContactInfoQuery, ContactInfoQueryVariables>;
export const CreateReceiptTokenDocument = gql`
  mutation CreateReceiptToken($locationId: ID!, $transactionId: ID!) {
    createReceiptToken(locationId: $locationId, transactionId: $transactionId) {
      token
      receiptUrl
      expireAt
    }
  }
`;
export type CreateReceiptTokenMutationFn = Apollo.MutationFunction<CreateReceiptTokenMutation, CreateReceiptTokenMutationVariables>;

/**
 * __useCreateReceiptTokenMutation__
 *
 * To run a mutation, you first call `useCreateReceiptTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptTokenMutation, { data, loading, error }] = useCreateReceiptTokenMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useCreateReceiptTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceiptTokenMutation, CreateReceiptTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReceiptTokenMutation, CreateReceiptTokenMutationVariables>(CreateReceiptTokenDocument, options);
}
export type CreateReceiptTokenMutationHookResult = ReturnType<typeof useCreateReceiptTokenMutation>;
export type CreateReceiptTokenMutationResult = Apollo.MutationResult<CreateReceiptTokenMutation>;
export type CreateReceiptTokenMutationOptions = Apollo.BaseMutationOptions<CreateReceiptTokenMutation, CreateReceiptTokenMutationVariables>;
export const ExportCsvDocument = gql`
  mutation ExportCsv($search: TransactionSearchInput!) {
    exportCsvSigned(search: $search) {
      path
    }
  }
`;
export type ExportCsvMutationFn = Apollo.MutationFunction<ExportCsvMutation, ExportCsvMutationVariables>;

/**
 * __useExportCsvMutation__
 *
 * To run a mutation, you first call `useExportCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCsvMutation, { data, loading, error }] = useExportCsvMutation({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useExportCsvMutation(baseOptions?: Apollo.MutationHookOptions<ExportCsvMutation, ExportCsvMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportCsvMutation, ExportCsvMutationVariables>(ExportCsvDocument, options);
}
export type ExportCsvMutationHookResult = ReturnType<typeof useExportCsvMutation>;
export type ExportCsvMutationResult = Apollo.MutationResult<ExportCsvMutation>;
export type ExportCsvMutationOptions = Apollo.BaseMutationOptions<ExportCsvMutation, ExportCsvMutationVariables>;
export const GetAdoptionsTrackingDocument = gql`
  query GetAdoptionsTracking($customerId: ID!, $productId: ID!, $adoptionIds: [ID!]) {
    getAdoptionsTracking(customerId: $customerId, productId: $productId, adoptionIds: $adoptionIds) {
      customerId
      adoptions {
        adoptionId
        status
        products {
          productId
          riskCaseStatus
        }
        locations {
          locationId
          tradingName
          products {
            productId
            riskCaseStatus
          }
        }
      }
    }
  }
`;

/**
 * __useGetAdoptionsTrackingQuery__
 *
 * To run a query within a React component, call `useGetAdoptionsTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdoptionsTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdoptionsTrackingQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      productId: // value for 'productId'
 *      adoptionIds: // value for 'adoptionIds'
 *   },
 * });
 */
export function useGetAdoptionsTrackingQuery(baseOptions: Apollo.QueryHookOptions<GetAdoptionsTrackingQuery, GetAdoptionsTrackingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdoptionsTrackingQuery, GetAdoptionsTrackingQueryVariables>(GetAdoptionsTrackingDocument, options);
}
export function useGetAdoptionsTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdoptionsTrackingQuery, GetAdoptionsTrackingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdoptionsTrackingQuery, GetAdoptionsTrackingQueryVariables>(GetAdoptionsTrackingDocument, options);
}
export type GetAdoptionsTrackingQueryHookResult = ReturnType<typeof useGetAdoptionsTrackingQuery>;
export type GetAdoptionsTrackingLazyQueryHookResult = ReturnType<typeof useGetAdoptionsTrackingLazyQuery>;
export type GetAdoptionsTrackingQueryResult = Apollo.QueryResult<GetAdoptionsTrackingQuery, GetAdoptionsTrackingQueryVariables>;
export const GetAdoptionsTrackingStatusesDocument = gql`
  query GetAdoptionsTrackingStatuses($customerId: ID!, $productId: ID!) {
    getAdoptionsTracking(customerId: $customerId, productId: $productId, adoptionIds: []) {
      customerId
      adoptions {
        adoptionId
        status
      }
    }
  }
`;

/**
 * __useGetAdoptionsTrackingStatusesQuery__
 *
 * To run a query within a React component, call `useGetAdoptionsTrackingStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdoptionsTrackingStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdoptionsTrackingStatusesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAdoptionsTrackingStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdoptionsTrackingStatusesQuery, GetAdoptionsTrackingStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdoptionsTrackingStatusesQuery, GetAdoptionsTrackingStatusesQueryVariables>(GetAdoptionsTrackingStatusesDocument, options);
}
export function useGetAdoptionsTrackingStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdoptionsTrackingStatusesQuery, GetAdoptionsTrackingStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdoptionsTrackingStatusesQuery, GetAdoptionsTrackingStatusesQueryVariables>(GetAdoptionsTrackingStatusesDocument, options);
}
export type GetAdoptionsTrackingStatusesQueryHookResult = ReturnType<typeof useGetAdoptionsTrackingStatusesQuery>;
export type GetAdoptionsTrackingStatusesLazyQueryHookResult = ReturnType<typeof useGetAdoptionsTrackingStatusesLazyQuery>;
export type GetAdoptionsTrackingStatusesQueryResult = Apollo.QueryResult<GetAdoptionsTrackingStatusesQuery, GetAdoptionsTrackingStatusesQueryVariables>;
export const GetLocationAddressDocument = gql`
  query GetLocationAddress($locationId: ID!) {
    location(locationId: $locationId) {
      locationId
      tradingAddress {
        formattedAddress
      }
    }
  }
`;

/**
 * __useGetLocationAddressQuery__
 *
 * To run a query within a React component, call `useGetLocationAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationAddressQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationAddressQuery(baseOptions: Apollo.QueryHookOptions<GetLocationAddressQuery, GetLocationAddressQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationAddressQuery, GetLocationAddressQueryVariables>(GetLocationAddressDocument, options);
}
export function useGetLocationAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationAddressQuery, GetLocationAddressQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationAddressQuery, GetLocationAddressQueryVariables>(GetLocationAddressDocument, options);
}
export type GetLocationAddressQueryHookResult = ReturnType<typeof useGetLocationAddressQuery>;
export type GetLocationAddressLazyQueryHookResult = ReturnType<typeof useGetLocationAddressLazyQuery>;
export type GetLocationAddressQueryResult = Apollo.QueryResult<GetLocationAddressQuery, GetLocationAddressQueryVariables>;
export const HomepageTotalTakingsDocument = gql`
  query HomepageTotalTakings($search: TransactionPagedSearchInput!) {
    searchTransactions(search: $search) {
      searchTotals {
        count
        netSales
        currencyCode
      }
    }
  }
`;

/**
 * __useHomepageTotalTakingsQuery__
 *
 * To run a query within a React component, call `useHomepageTotalTakingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageTotalTakingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageTotalTakingsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useHomepageTotalTakingsQuery(baseOptions: Apollo.QueryHookOptions<HomepageTotalTakingsQuery, HomepageTotalTakingsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomepageTotalTakingsQuery, HomepageTotalTakingsQueryVariables>(HomepageTotalTakingsDocument, options);
}
export function useHomepageTotalTakingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomepageTotalTakingsQuery, HomepageTotalTakingsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomepageTotalTakingsQuery, HomepageTotalTakingsQueryVariables>(HomepageTotalTakingsDocument, options);
}
export type HomepageTotalTakingsQueryHookResult = ReturnType<typeof useHomepageTotalTakingsQuery>;
export type HomepageTotalTakingsLazyQueryHookResult = ReturnType<typeof useHomepageTotalTakingsLazyQuery>;
export type HomepageTotalTakingsQueryResult = Apollo.QueryResult<HomepageTotalTakingsQuery, HomepageTotalTakingsQueryVariables>;
export const InternalUserInfoDocument = gql`
  query InternalUserInfo {
    internalUserInfo {
      userId
      role
      email
      permissions
      firstName
      lastName
    }
  }
`;

/**
 * __useInternalUserInfoQuery__
 *
 * To run a query within a React component, call `useInternalUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useInternalUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<InternalUserInfoQuery, InternalUserInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InternalUserInfoQuery, InternalUserInfoQueryVariables>(InternalUserInfoDocument, options);
}
export function useInternalUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InternalUserInfoQuery, InternalUserInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InternalUserInfoQuery, InternalUserInfoQueryVariables>(InternalUserInfoDocument, options);
}
export type InternalUserInfoQueryHookResult = ReturnType<typeof useInternalUserInfoQuery>;
export type InternalUserInfoLazyQueryHookResult = ReturnType<typeof useInternalUserInfoLazyQuery>;
export type InternalUserInfoQueryResult = Apollo.QueryResult<InternalUserInfoQuery, InternalUserInfoQueryVariables>;
export const LocationsWithCardMachinesDocument = gql`
  query locationsWithCardMachines($customerId: ID!) {
    customerLocations(customerId: $customerId) {
      locationId
      tradingName
      nickname
      status
      cardMachines {
        terminalId
        name
        serialNumber
        status
        modelId
      }
      tradingAddress {
        formattedAddress
      }
    }
  }
`;

/**
 * __useLocationsWithCardMachinesQuery__
 *
 * To run a query within a React component, call `useLocationsWithCardMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsWithCardMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsWithCardMachinesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useLocationsWithCardMachinesQuery(
  baseOptions: Apollo.QueryHookOptions<LocationsWithCardMachinesQuery, LocationsWithCardMachinesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationsWithCardMachinesQuery, LocationsWithCardMachinesQueryVariables>(LocationsWithCardMachinesDocument, options);
}
export function useLocationsWithCardMachinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationsWithCardMachinesQuery, LocationsWithCardMachinesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationsWithCardMachinesQuery, LocationsWithCardMachinesQueryVariables>(LocationsWithCardMachinesDocument, options);
}
export type LocationsWithCardMachinesQueryHookResult = ReturnType<typeof useLocationsWithCardMachinesQuery>;
export type LocationsWithCardMachinesLazyQueryHookResult = ReturnType<typeof useLocationsWithCardMachinesLazyQuery>;
export type LocationsWithCardMachinesQueryResult = Apollo.QueryResult<LocationsWithCardMachinesQuery, LocationsWithCardMachinesQueryVariables>;
export const NumberOfCardMachinesDocument = gql`
  query numberOfCardMachines($customerId: ID!) {
    customerLocations(customerId: $customerId) {
      numberOfCardMachines
      locationId
    }
  }
`;

/**
 * __useNumberOfCardMachinesQuery__
 *
 * To run a query within a React component, call `useNumberOfCardMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberOfCardMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOfCardMachinesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useNumberOfCardMachinesQuery(baseOptions: Apollo.QueryHookOptions<NumberOfCardMachinesQuery, NumberOfCardMachinesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NumberOfCardMachinesQuery, NumberOfCardMachinesQueryVariables>(NumberOfCardMachinesDocument, options);
}
export function useNumberOfCardMachinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NumberOfCardMachinesQuery, NumberOfCardMachinesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NumberOfCardMachinesQuery, NumberOfCardMachinesQueryVariables>(NumberOfCardMachinesDocument, options);
}
export type NumberOfCardMachinesQueryHookResult = ReturnType<typeof useNumberOfCardMachinesQuery>;
export type NumberOfCardMachinesLazyQueryHookResult = ReturnType<typeof useNumberOfCardMachinesLazyQuery>;
export type NumberOfCardMachinesQueryResult = Apollo.QueryResult<NumberOfCardMachinesQuery, NumberOfCardMachinesQueryVariables>;
export const OnboardingDocument = gql`
  query Onboarding($customerId: String!, $firstName: String!, $mcc: String!) {
    onboardingInfo(customerId: $customerId, firstName: $firstName, mcc: $mcc) {
      show
      content {
        title
        description
        icon
        buttonTitle
        image
      }
    }
  }
`;

/**
 * __useOnboardingQuery__
 *
 * To run a query within a React component, call `useOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      firstName: // value for 'firstName'
 *      mcc: // value for 'mcc'
 *   },
 * });
 */
export function useOnboardingQuery(baseOptions: Apollo.QueryHookOptions<OnboardingQuery, OnboardingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingQuery, OnboardingQueryVariables>(OnboardingDocument, options);
}
export function useOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingQuery, OnboardingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingQuery, OnboardingQueryVariables>(OnboardingDocument, options);
}
export type OnboardingQueryHookResult = ReturnType<typeof useOnboardingQuery>;
export type OnboardingLazyQueryHookResult = ReturnType<typeof useOnboardingLazyQuery>;
export type OnboardingQueryResult = Apollo.QueryResult<OnboardingQuery, OnboardingQueryVariables>;
export const UpdateViewedStateDocument = gql`
  mutation UpdateViewedState($viewedstate: ViewedState!) {
    updateViewedState(state: $viewedstate)
  }
`;
export type UpdateViewedStateMutationFn = Apollo.MutationFunction<UpdateViewedStateMutation, UpdateViewedStateMutationVariables>;

/**
 * __useUpdateViewedStateMutation__
 *
 * To run a mutation, you first call `useUpdateViewedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewedStateMutation, { data, loading, error }] = useUpdateViewedStateMutation({
 *   variables: {
 *      viewedstate: // value for 'viewedstate'
 *   },
 * });
 */
export function useUpdateViewedStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewedStateMutation, UpdateViewedStateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateViewedStateMutation, UpdateViewedStateMutationVariables>(UpdateViewedStateDocument, options);
}
export type UpdateViewedStateMutationHookResult = ReturnType<typeof useUpdateViewedStateMutation>;
export type UpdateViewedStateMutationResult = Apollo.MutationResult<UpdateViewedStateMutation>;
export type UpdateViewedStateMutationOptions = Apollo.BaseMutationOptions<UpdateViewedStateMutation, UpdateViewedStateMutationVariables>;
export const OrderProductDocument = gql`
  mutation OrderProduct($productOrderInput: ProductOrderInput!) {
    orderProduct(productOrderInput: $productOrderInput) {
      ordered
    }
  }
`;
export type OrderProductMutationFn = Apollo.MutationFunction<OrderProductMutation, OrderProductMutationVariables>;

/**
 * __useOrderProductMutation__
 *
 * To run a mutation, you first call `useOrderProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderProductMutation, { data, loading, error }] = useOrderProductMutation({
 *   variables: {
 *      productOrderInput: // value for 'productOrderInput'
 *   },
 * });
 */
export function useOrderProductMutation(baseOptions?: Apollo.MutationHookOptions<OrderProductMutation, OrderProductMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderProductMutation, OrderProductMutationVariables>(OrderProductDocument, options);
}
export type OrderProductMutationHookResult = ReturnType<typeof useOrderProductMutation>;
export type OrderProductMutationResult = Apollo.MutationResult<OrderProductMutation>;
export type OrderProductMutationOptions = Apollo.BaseMutationOptions<OrderProductMutation, OrderProductMutationVariables>;
export const PollTransactionsSearchDocument = gql`
  query pollTransactionsSearch($search: PollTransactionSearchInput!) {
    pollTransactionsSearch(search: $search) {
      newTransactions
    }
  }
`;

/**
 * __usePollTransactionsSearchQuery__
 *
 * To run a query within a React component, call `usePollTransactionsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollTransactionsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollTransactionsSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePollTransactionsSearchQuery(baseOptions: Apollo.QueryHookOptions<PollTransactionsSearchQuery, PollTransactionsSearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PollTransactionsSearchQuery, PollTransactionsSearchQueryVariables>(PollTransactionsSearchDocument, options);
}
export function usePollTransactionsSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PollTransactionsSearchQuery, PollTransactionsSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PollTransactionsSearchQuery, PollTransactionsSearchQueryVariables>(PollTransactionsSearchDocument, options);
}
export type PollTransactionsSearchQueryHookResult = ReturnType<typeof usePollTransactionsSearchQuery>;
export type PollTransactionsSearchLazyQueryHookResult = ReturnType<typeof usePollTransactionsSearchLazyQuery>;
export type PollTransactionsSearchQueryResult = Apollo.QueryResult<PollTransactionsSearchQuery, PollTransactionsSearchQueryVariables>;
export const ProfileInformationDocument = gql`
  query ProfileInformation($customerId: ID!) {
    customerRole(customerId: $customerId)
    customer(customerId: $customerId) {
      registeredInformation {
        registeredName
        registeredAddress {
          formattedAddress
        }
      }
    }
    contact {
      firstName
      lastName
      emailAddress
      phoneNumbers {
        internationalCallingCode
        telephoneNumber
      }
    }
  }
`;

/**
 * __useProfileInformationQuery__
 *
 * To run a query within a React component, call `useProfileInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileInformationQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useProfileInformationQuery(baseOptions: Apollo.QueryHookOptions<ProfileInformationQuery, ProfileInformationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileInformationQuery, ProfileInformationQueryVariables>(ProfileInformationDocument, options);
}
export function useProfileInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileInformationQuery, ProfileInformationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileInformationQuery, ProfileInformationQueryVariables>(ProfileInformationDocument, options);
}
export type ProfileInformationQueryHookResult = ReturnType<typeof useProfileInformationQuery>;
export type ProfileInformationLazyQueryHookResult = ReturnType<typeof useProfileInformationLazyQuery>;
export type ProfileInformationQueryResult = Apollo.QueryResult<ProfileInformationQuery, ProfileInformationQueryVariables>;
export const QueueBookingEligibilityDocument = gql`
  query queueBookingEligibility($customerId: ID!) {
    queueBookingEligibility(customerId: $customerId)
  }
`;

/**
 * __useQueueBookingEligibilityQuery__
 *
 * To run a query within a React component, call `useQueueBookingEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueueBookingEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueueBookingEligibilityQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useQueueBookingEligibilityQuery(baseOptions: Apollo.QueryHookOptions<QueueBookingEligibilityQuery, QueueBookingEligibilityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueueBookingEligibilityQuery, QueueBookingEligibilityQueryVariables>(QueueBookingEligibilityDocument, options);
}
export function useQueueBookingEligibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueueBookingEligibilityQuery, QueueBookingEligibilityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueueBookingEligibilityQuery, QueueBookingEligibilityQueryVariables>(QueueBookingEligibilityDocument, options);
}
export type QueueBookingEligibilityQueryHookResult = ReturnType<typeof useQueueBookingEligibilityQuery>;
export type QueueBookingEligibilityLazyQueryHookResult = ReturnType<typeof useQueueBookingEligibilityLazyQuery>;
export type QueueBookingEligibilityQueryResult = Apollo.QueryResult<QueueBookingEligibilityQuery, QueueBookingEligibilityQueryVariables>;
export const SearchTransactionsDocument = gql`
  query SearchTransactions($search: TransactionPagedSearchInput!) {
    searchTransactions(search: $search) {
      searchTotals {
        count
        cashback
        netSales
        currencyCode
        cashBackCount
        totalGratuity
        totalRefund
        totalSales
        refundCount
        salesCount
        totalCashback
        gratuityCount
      }
      transactionDateGroups {
        date
        netSales
        count
        cashback
        currencyCode
        transactions {
          transactionId
          locationId
          currency
          amount
          transactionDateTime
          scheme
          timeZone
          displayStatus {
            type
            outcome
            isReversed
            refundStatus
            preAuthStatus
          }
        }
      }
      cursors {
        before
        after
      }
    }
  }
`;

/**
 * __useSearchTransactionsQuery__
 *
 * To run a query within a React component, call `useSearchTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTransactionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchTransactionsQuery(baseOptions: Apollo.QueryHookOptions<SearchTransactionsQuery, SearchTransactionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTransactionsQuery, SearchTransactionsQueryVariables>(SearchTransactionsDocument, options);
}
export function useSearchTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTransactionsQuery, SearchTransactionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTransactionsQuery, SearchTransactionsQueryVariables>(SearchTransactionsDocument, options);
}
export type SearchTransactionsQueryHookResult = ReturnType<typeof useSearchTransactionsQuery>;
export type SearchTransactionsLazyQueryHookResult = ReturnType<typeof useSearchTransactionsLazyQuery>;
export type SearchTransactionsQueryResult = Apollo.QueryResult<SearchTransactionsQuery, SearchTransactionsQueryVariables>;
export const SendReceiptDocument = gql`
  mutation SendReceipt($transactionId: ID!, $locationId: ID!, $emailAddress: String!) {
    sendReceipt(transactionId: $transactionId, locationId: $locationId, emailAddress: $emailAddress)
  }
`;
export type SendReceiptMutationFn = Apollo.MutationFunction<SendReceiptMutation, SendReceiptMutationVariables>;

/**
 * __useSendReceiptMutation__
 *
 * To run a mutation, you first call `useSendReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReceiptMutation, { data, loading, error }] = useSendReceiptMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      locationId: // value for 'locationId'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useSendReceiptMutation(baseOptions?: Apollo.MutationHookOptions<SendReceiptMutation, SendReceiptMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendReceiptMutation, SendReceiptMutationVariables>(SendReceiptDocument, options);
}
export type SendReceiptMutationHookResult = ReturnType<typeof useSendReceiptMutation>;
export type SendReceiptMutationResult = Apollo.MutationResult<SendReceiptMutation>;
export type SendReceiptMutationOptions = Apollo.BaseMutationOptions<SendReceiptMutation, SendReceiptMutationVariables>;
export const ValuePropByMarketDocument = gql`
  query ValuePropByMarket($customerId: ID!, $productId: ID!, $marketId: String!) {
    valuePropByMarket(customerId: $customerId, productId: $productId, marketId: $marketId) {
      id
      name
      price
      description
      offer
      productImagePath
      imagePath
      imagePath2
      modules {
        type
        title
        content {
          ... on ValuePropFaqs {
            question
            answer
          }
          ... on ValuePropUsps {
            title
            icon
            detail
          }
          ... on ValuePropPricing {
            title
            detail
          }
          ... on ValuePropTestimonials {
            customer
            quote
          }
        }
      }
    }
  }
`;

/**
 * __useValuePropByMarketQuery__
 *
 * To run a query within a React component, call `useValuePropByMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useValuePropByMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValuePropByMarketQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      productId: // value for 'productId'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useValuePropByMarketQuery(baseOptions: Apollo.QueryHookOptions<ValuePropByMarketQuery, ValuePropByMarketQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValuePropByMarketQuery, ValuePropByMarketQueryVariables>(ValuePropByMarketDocument, options);
}
export function useValuePropByMarketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValuePropByMarketQuery, ValuePropByMarketQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValuePropByMarketQuery, ValuePropByMarketQueryVariables>(ValuePropByMarketDocument, options);
}
export type ValuePropByMarketQueryHookResult = ReturnType<typeof useValuePropByMarketQuery>;
export type ValuePropByMarketLazyQueryHookResult = ReturnType<typeof useValuePropByMarketLazyQuery>;
export type ValuePropByMarketQueryResult = Apollo.QueryResult<ValuePropByMarketQuery, ValuePropByMarketQueryVariables>;
export const BrazeApiKeyDocument = gql`
  query BrazeApiKey($client: BrazeClient!, $marketId: ID!) {
    brazeApiKey(client: $client, marketId: $marketId) {
      key
      brazeApiKeyJWT
      endpoint
    }
  }
`;

/**
 * __useBrazeApiKeyQuery__
 *
 * To run a query within a React component, call `useBrazeApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrazeApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrazeApiKeyQuery({
 *   variables: {
 *      client: // value for 'client'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useBrazeApiKeyQuery(baseOptions: Apollo.QueryHookOptions<BrazeApiKeyQuery, BrazeApiKeyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrazeApiKeyQuery, BrazeApiKeyQueryVariables>(BrazeApiKeyDocument, options);
}
export function useBrazeApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrazeApiKeyQuery, BrazeApiKeyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrazeApiKeyQuery, BrazeApiKeyQueryVariables>(BrazeApiKeyDocument, options);
}
export type BrazeApiKeyQueryHookResult = ReturnType<typeof useBrazeApiKeyQuery>;
export type BrazeApiKeyLazyQueryHookResult = ReturnType<typeof useBrazeApiKeyLazyQuery>;
export type BrazeApiKeyQueryResult = Apollo.QueryResult<BrazeApiKeyQuery, BrazeApiKeyQueryVariables>;
export const CustomersDocument = gql`
  query customers($roles: [String]) {
    customers(params: { roles: $roles }) {
      customerId
      marketId
      customerReference
      registeredInformation {
        registeredName
      }
      isSandbox
      locations {
        currencyCode
        locationId
        industry {
          mcc
          description
        }
        tradingName
        tradingAddress {
          formattedAddress
        }
        nickname
        remotePaymentAppConfig {
          maxPaymentAmount
          paymentLinks {
            enabled
          }
          virtualTerminal {
            enabled
          }
          portal {
            enabled
          }
          payByBank {
            enabled
          }
          preauth {
            enabled
          }
        }
        currencyCode
      }
    }
  }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const CustomerDocument = gql`
  query customer($customerId: ID!) {
    customer(customerId: $customerId) {
      customerId
      marketId
      customerReference
      registeredInformation {
        registeredName
      }
      locations {
        locationId
        tradingName
        nickname
        remotePaymentAppConfig {
          paymentLinks {
            enabled
          }
          portal {
            enabled
          }
        }
      }
    }
  }
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const MarketplaceProductIdsDocument = gql`
  query MarketplaceProductIds($customerDataInput: CustomerDataInput!) {
    marketplaceProductsV2(customerDataInput: $customerDataInput) {
      id
    }
  }
`;

/**
 * __useMarketplaceProductIdsQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductIdsQuery({
 *   variables: {
 *      customerDataInput: // value for 'customerDataInput'
 *   },
 * });
 */
export function useMarketplaceProductIdsQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceProductIdsQuery, MarketplaceProductIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketplaceProductIdsQuery, MarketplaceProductIdsQueryVariables>(MarketplaceProductIdsDocument, options);
}
export function useMarketplaceProductIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductIdsQuery, MarketplaceProductIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketplaceProductIdsQuery, MarketplaceProductIdsQueryVariables>(MarketplaceProductIdsDocument, options);
}
export type MarketplaceProductIdsQueryHookResult = ReturnType<typeof useMarketplaceProductIdsQuery>;
export type MarketplaceProductIdsLazyQueryHookResult = ReturnType<typeof useMarketplaceProductIdsLazyQuery>;
export type MarketplaceProductIdsQueryResult = Apollo.QueryResult<MarketplaceProductIdsQuery, MarketplaceProductIdsQueryVariables>;
export const PendingInvitesDocument = gql`
  query pendingInvites {
    searchInvites(filter: { status_equals: ["Pending"] }) {
      inviteId
    }
  }
`;

/**
 * __usePendingInvitesQuery__
 *
 * To run a query within a React component, call `usePendingInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingInvitesQuery(baseOptions?: Apollo.QueryHookOptions<PendingInvitesQuery, PendingInvitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingInvitesQuery, PendingInvitesQueryVariables>(PendingInvitesDocument, options);
}
export function usePendingInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingInvitesQuery, PendingInvitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PendingInvitesQuery, PendingInvitesQueryVariables>(PendingInvitesDocument, options);
}
export type PendingInvitesQueryHookResult = ReturnType<typeof usePendingInvitesQuery>;
export type PendingInvitesLazyQueryHookResult = ReturnType<typeof usePendingInvitesLazyQuery>;
export type PendingInvitesQueryResult = Apollo.QueryResult<PendingInvitesQuery, PendingInvitesQueryVariables>;
export const AcceptedInvitesDocument = gql`
  query acceptedInvites {
    searchInvites(filter: { status_equals: ["Accepted"] }) {
      inviteId
      status
      customer {
        customerId
        registeredInformation {
          registeredName
        }
      }
    }
  }
`;

/**
 * __useAcceptedInvitesQuery__
 *
 * To run a query within a React component, call `useAcceptedInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptedInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptedInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcceptedInvitesQuery(baseOptions?: Apollo.QueryHookOptions<AcceptedInvitesQuery, AcceptedInvitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AcceptedInvitesQuery, AcceptedInvitesQueryVariables>(AcceptedInvitesDocument, options);
}
export function useAcceptedInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcceptedInvitesQuery, AcceptedInvitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AcceptedInvitesQuery, AcceptedInvitesQueryVariables>(AcceptedInvitesDocument, options);
}
export type AcceptedInvitesQueryHookResult = ReturnType<typeof useAcceptedInvitesQuery>;
export type AcceptedInvitesLazyQueryHookResult = ReturnType<typeof useAcceptedInvitesLazyQuery>;
export type AcceptedInvitesQueryResult = Apollo.QueryResult<AcceptedInvitesQuery, AcceptedInvitesQueryVariables>;
export const LinksDocument = gql`
  query Links($marketId: ID!) {
    links(marketId: $marketId) {
      id
      value
    }
  }
`;

/**
 * __useLinksQuery__
 *
 * To run a query within a React component, call `useLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useLinksQuery(baseOptions: Apollo.QueryHookOptions<LinksQuery, LinksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinksQuery, LinksQueryVariables>(LinksDocument, options);
}
export function useLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinksQuery, LinksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinksQuery, LinksQueryVariables>(LinksDocument, options);
}
export type LinksQueryHookResult = ReturnType<typeof useLinksQuery>;
export type LinksLazyQueryHookResult = ReturnType<typeof useLinksLazyQuery>;
export type LinksQueryResult = Apollo.QueryResult<LinksQuery, LinksQueryVariables>;
export const CustomerRoleDocument = gql`
  query customerRole($customerId: ID!) {
    customerRole(customerId: $customerId)
  }
`;

/**
 * __useCustomerRoleQuery__
 *
 * To run a query within a React component, call `useCustomerRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRoleQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerRoleQuery(baseOptions: Apollo.QueryHookOptions<CustomerRoleQuery, CustomerRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerRoleQuery, CustomerRoleQueryVariables>(CustomerRoleDocument, options);
}
export function useCustomerRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerRoleQuery, CustomerRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerRoleQuery, CustomerRoleQueryVariables>(CustomerRoleDocument, options);
}
export type CustomerRoleQueryHookResult = ReturnType<typeof useCustomerRoleQuery>;
export type CustomerRoleLazyQueryHookResult = ReturnType<typeof useCustomerRoleLazyQuery>;
export type CustomerRoleQueryResult = Apollo.QueryResult<CustomerRoleQuery, CustomerRoleQueryVariables>;
export const PermissionsDocument = gql`
  query permissions($customerId: ID!, $role: Role!) {
    customerRolePermissions(customerId: $customerId, role: $role) {
      name
      category
      title
      enabled
    }
  }
`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
}
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
}
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const PhoneNumbersDocument = gql`
  query PhoneNumbers($marketId: ID!) {
    phoneNumbers(marketId: $marketId) {
      id
      value
    }
  }
`;

/**
 * __usePhoneNumbersQuery__
 *
 * To run a query within a React component, call `usePhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumbersQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function usePhoneNumbersQuery(baseOptions: Apollo.QueryHookOptions<PhoneNumbersQuery, PhoneNumbersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhoneNumbersQuery, PhoneNumbersQueryVariables>(PhoneNumbersDocument, options);
}
export function usePhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumbersQuery, PhoneNumbersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhoneNumbersQuery, PhoneNumbersQueryVariables>(PhoneNumbersDocument, options);
}
export type PhoneNumbersQueryHookResult = ReturnType<typeof usePhoneNumbersQuery>;
export type PhoneNumbersLazyQueryHookResult = ReturnType<typeof usePhoneNumbersLazyQuery>;
export type PhoneNumbersQueryResult = Apollo.QueryResult<PhoneNumbersQuery, PhoneNumbersQueryVariables>;
