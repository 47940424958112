import { useCustomers } from '@/providers/CustomersProvider/CustomersProvider';
import { useApolloClient } from '@apollo/client';
import { DjpLinksProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export const LegacyLinksProvider = ({ children }: { children: ReactNode }) => {
  const client = useApolloClient();
  const { customer } = useCustomers();

  // fall back to GB if customer is not loaded yet
  const marketId = customer?.marketId ?? 'GB';

  return (
    <DjpLinksProvider marketId={marketId} client={client as any}>
      {children}
    </DjpLinksProvider>
  );
};
