import useBraze from '@/hooks/useBraze';
import { DjpBrazeProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export type LegacyBrazeProviderProps = {
  children: ReactNode;
};

export const LegacyBrazeProvider = ({ children }: LegacyBrazeProviderProps) => {
  const appBoy = useBraze();

  return <DjpBrazeProvider appboy={appBoy}>{children}</DjpBrazeProvider>;
};
