import { useLogger } from 'framework';
import { DjpLoggerProvider } from 'legacy-stencil-react';
import { ReactNode, useEffect, useState } from 'react';

export type LegacyLoggerProviderProps = {
  children: ReactNode;
};

export const LegacyLoggerProvider = ({ children }: LegacyLoggerProviderProps) => {
  const [isClient, setIsClient] = useState(false);
  const logger = useLogger();

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isClient && <DjpLoggerProvider logger={logger} />}
      {children}
    </>
  );
};
