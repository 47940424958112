import { ChevronDown } from '@dojo-engineering/react-icons';
import { clsx } from '@dojo-engineering/styled-system';
import { useAnalytics } from 'framework';
import { i18n } from 'legacy-i18n';
import { PropsWithChildren, memo, useCallback, useState } from 'react';
import NavItem from '../navItem/navItem';
import styles from './navFolder.module.css';

interface NavFolderProps extends PropsWithChildren {
  analyticKey: { category: string; action: string };
  children: JSX.Element[];
  icon: JSX.Element;
  text: string;
}

const NavFolder = ({ analyticKey, children, icon, text }: NavFolderProps) => {
  const { analytics } = useAnalytics();
  const [showSubMenu, setSubMenu] = useState(false);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setSubMenu((prevState) => {
        const extendedAnalyticKey = prevState ? { ...analyticKey, action: analyticKey.action.replace('opened', 'closed') } : analyticKey;
        analytics.clickedElementV2(extendedAnalyticKey?.category, extendedAnalyticKey?.action);

        return !prevState;
      });
    },
    [analyticKey, analytics]
  );

  return (
    <>
      <button className={styles.folder} onClick={handleClick} type="button">
        <NavItem icon={icon}>{i18n.t(text)}</NavItem>
        <ChevronDown className={clsx(styles.chevronIcon, { [styles.rotated]: showSubMenu })} />
      </button>
      <div className={styles.collapsible} style={{ maxHeight: showSubMenu ? `${children.length * 68}px` : 0 }}>
        {children}
      </div>
    </>
  );
};

export default memo(NavFolder);
