import { config } from '@/config';
import * as CryptoJS from 'crypto-js';
import { useUser } from 'framework';
import { asyncWithLDProvider, LDOptions } from 'launchdarkly-react-client-sdk';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useCustomers } from './CustomersProvider/CustomersProvider';

export interface LaunchDarklyContextProps {
  getFlagValue?: (flagKey: string) => any;
}

const LaunchDarklyContext = createContext<LaunchDarklyContextProps>({});

const getLaunchDarklyContext = (customerId: string, userId: string, marketId: string) => {
  const userContext = {
    kind: 'user',
    key: userId ?? 'anonymous',
    os_type: 'web',
    marketId: marketId ?? 'anonymous',
  };

  const customerContext = {
    kind: 'customer',
    key: customerId ?? 'anonymous',
    marketId: marketId ?? 'anonymous',
  };

  const environmentContext = {
    kind: 'environment',
    key: config.serviceEnvironment?.toLowerCase(),
  };

  const multiContext = {
    customer: customerContext,
    environment: environmentContext,
    kind: 'multi',
    user: userContext,
  };

  // The context order for key:kind pairs is important
  const canonicalKey = `${customerContext.kind}:${customerContext.key}:${environmentContext.kind}:${environmentContext.key}:${userContext.kind}:${userContext.key}`;
  const keyBytes = CryptoJS.enc.Utf8.parse(config.launchDarklySDKKey);
  const userBytes = CryptoJS.enc.Utf8.parse(canonicalKey);
  const hash = CryptoJS.HmacSHA256(userBytes, keyBytes);

  return { context: multiContext, hash: hash };
};

const EmptyComponent: React.FC<{ children: ReactNode }> = ({ children }) => children;

export interface LaunchDarklyProviderProps {
  children: ReactNode;
}

export const LaunchDarklyProvider = ({ children }: LaunchDarklyProviderProps) => {
  const [LDProvider, setLDProvider] = useState<any>(() => EmptyComponent);
  const { user } = useUser();
  const { customer } = useCustomers();

  useEffect(() => {
    if (customer?.customerId && user?.dojoId) {
      const customLogger = {
        debug: (message: string) => console.debug(message),
        info: () => {}, // Do nothing for info logs
        warn: (message: string) => console.warn(message),
        error: (message: string) => console.error(message),
      };
      const { context, hash } = getLaunchDarklyContext(customer.customerId, user.dojoId as string, customer.marketId);
      const options = {
        bootstrap: 'localStorage',
        hash: hash.toString(),
        logger: customLogger,
      } as LDOptions;

      (async () => {
        const Provider = await asyncWithLDProvider({
          clientSideID: config.launchDarklyClientSideID,
          context,
          options,
        });
        setLDProvider(() => Provider);
      })();
    }
  }, [customer?.customerId, user?.dojoId, customer?.marketId]);

  return (
    <LDProvider>
      <LaunchDarklyContext.Provider value={{}}>{children}</LaunchDarklyContext.Provider>
    </LDProvider>
  );
};
