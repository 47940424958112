import { config } from '@/config';
import { useLogger } from 'framework';
import { DjpFeatureFlagsProvider } from 'legacy-stencil-react';
import { ReactNode } from 'react';

export type LegacyFeatureFlagsProviderProps = {
  children: ReactNode;
};

export const LegacyFeatureFlagsProvider = ({ children }: LegacyFeatureFlagsProviderProps) => {
  let featureFlags = {};
  const logger = useLogger();

  try {
    featureFlags = config.featureFlags;
  } catch {
    logger.error('Error parsing feature flags');
  }

  return <DjpFeatureFlagsProvider featureFlags={featureFlags ?? {}}>{children}</DjpFeatureFlagsProvider>;
};
