import { AnalyticsCategory } from '@/constants/analytics';
import { AnalyticsHomeVariantConstants } from '@/constants/analytics/home-variant';
import useElementEnteredViewport from '@/hooks/useElementEnteredViewport';
import { bodyRecipe, cardStyle, clsx, headingRecipe } from '@dojo-engineering/styled-system';
import { useAnalytics } from 'framework';
import { i18n } from 'legacy-i18n';
import Link from 'next/link';
import React, { useRef } from 'react';
import { iconContainer, paymentSectionTile, textContainer, tileContainerStyle } from './homeTile.css';

type HomeTileProps = {
  Icon: React.ReactNode;
  heading: string;
  body: string;
  path:
    | string
    | {
        url: string;
        external?: boolean;
        target?: HTMLAnchorElement['target'];
      };
  query?: { [key: string]: string };
  analyticsPageSection?: string;
  analyticsActionSuffix?: string;
};

export const HomeTile = ({ Icon, heading, body, path, analyticsPageSection, analyticsActionSuffix, query }: HomeTileProps) => {
  const { analytics } = useAnalytics();

  const elementRef = useRef();

  const handleElementEnteredViewport = () => {
    analytics.impression(AnalyticsCategory.homepage, AnalyticsHomeVariantConstants.actions.seen + analyticsActionSuffix, null, {
      pageSection: analyticsPageSection,
    });
  };

  useElementEnteredViewport(elementRef, handleElementEnteredViewport);

  const handleClick = () => {
    analytics.clickedElementV2(AnalyticsCategory.homepage, AnalyticsHomeVariantConstants.actions.selected + analyticsActionSuffix, null, {
      pageSection: analyticsPageSection,
    });
  };

  const tileContainer = clsx(cardStyle, tileContainerStyle, {
    [paymentSectionTile]:
      analyticsPageSection === AnalyticsHomeVariantConstants.pageSection.paymentLink ||
      analyticsPageSection === AnalyticsHomeVariantConstants.pageSection.virtualTerminal ||
      analyticsPageSection === AnalyticsHomeVariantConstants.pageSection.payByBank,
  });

  let pathUrl: string;
  let linkProps: object | undefined;

  if (typeof path === 'string') {
    pathUrl = path;
  } else if (typeof path === 'object' && path.url) {
    pathUrl = path.url;
    if (path.target) {
      linkProps = { target: path.target, ...(path.external ? { target: path.target ?? '_blank', rel: 'noopener noreferrer' } : {}) };
    }
  }

  return (
    <Link
      href={{ pathname: pathUrl, query }}
      prefetch={false}
      ref={elementRef}
      className={tileContainer}
      onClick={handleClick}
      {...(linkProps != null && { ...linkProps })}
    >
      <div className={iconContainer}>{Icon}</div>
      <div className={textContainer}>
        <span className={headingRecipe({ h: 6, variant: 'regular' })}>{i18n.t(heading)}</span>
        <span className={bodyRecipe({ size: 'small', color: 'secondary' })}>{i18n.t(body)}</span>
      </div>
    </Link>
  );
};

export default HomeTile;
